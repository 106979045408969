import { Col, Tag } from 'antd';
import { ReactComponent as LogoGame } from 'assets/vectors/logo-games.svg';
import BackgroundGame from 'assets/vectors/background-games.svg'; // Import as a module
import styled from 'styled-components';
import { media } from 'constants/enum/breakpoints';

export const GamesWrapper = styled.div`
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
  margin: 36px auto;
  padding: 0 50px 46px 50px;
  max-width: 1590px;
  z-index: 1; /* Ensure content is above the background */

  &::before {
    content: '';
    position: fixed; /* Fix the background image to the viewport */
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    width: 1000px; /* Adjust the width as needed */
    height: 1000px; /* Adjust the height as needed */
    background: url(${BackgroundGame}) no-repeat center center;
    background-size: cover; /* Adjust the size as needed */
    z-index: -1; /* Ensure the background is behind the content */
  }

  ${media.sm} {
    padding: 38px 25px 90px 25px;
    margin: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;

  ${media.sm} {
    flex-direction: column;
    gap: 31px;
  }
`;

export const TitleLogo = styled(LogoGame)`
  width: 34px;
  height: 34px;
`;

export const TitleStyled = styled.div`
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-family: Neue Haas Grotesk Display Pro;
  display: flex;
  gap: 15px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 12px;

  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #5656561a;
    opacity: 0.1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 3px;
  }

  ${media.sm} {
    gap: 10px;
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const FilterText = styled.div`
  color: #fff;
  opacity: 0.4;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const TagStyled = styled(Tag)`
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;

  &.ant-tag {
    color: #ffffff;
    border-radius: 40px;
    padding: 12px;
    margin: 0;
    font-size: 14px;
    background-color: #1f1f2a;
    border: none;
  }

  &.ant-tag-blue {
    background-color: #6b24b2;
    color: white;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const DropdownTitleWrapper = styled.div`
  gap: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.24);
`;

export const DropdownTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  width: 220px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
`;

export const MenuItemWrapper = styled.div`
  gap: 10px;
  padding: 14px 16px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;

  &:hover {
    color: #fff;
    background: #878273;
  }
`;

export const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
`;

export const GameStyledCol = styled(Col)`
  @media (min-width: 1440px) {
    width: 25%;
    flex-basis: 25%;
  }

  @media (min-width: 1750px) {
    flex-basis: 20%;
    width: 20%;
  }
`;
