import { PitRouter } from 'constants/routers';
import { useNavigate } from 'react-router-dom';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import gradientLine from 'assets/images/modals/gradient-line.svg';
import backgroundImage from 'assets/images/modals/loading-image.png';
import animationGif from 'assets/images/modals/congratulation-animation.gif';

import {
  AnimationGif,
  BackgroundImage,
  BodyAccountWrapper,
  BodyButtonWrapper,
  BodyText,
  BodyTextWrapper,
  ButtonText,
  ButtonWrapper,
  GradientLine,
  HeaderFirstText,
  HeaderSecondText,
  HeaderTextWrapper,
  ModalLevelMasterBody,
  ModalLevelMasterContainer,
  ModalLevelMasterHeader,
  ModalLevelMasterWrapper,
  ModalWrapper,
} from './styled';
import { useEffect, useState } from 'react';

export const Leaderboard = ({
  shareTwitterURL,
}: {
  shareTwitterURL?: string;
}) => {
  const open = useModalIsOpen(ApplicationModal.PLAYGAME_LEADERBOARD);
  const toggleModal = useToggleModal(ApplicationModal.PLAYGAME_LEADERBOARD);
  const handleCancel = () => {
    toggleModal();
  };
  const [gifSrc, setGifSrc] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      setGifSrc('');
      setTimeout(() => {
        setGifSrc(animationGif);
      }, 0);
    }
  }, [open]);

  return (
    <ModalWrapper isOpen={open} onCancel={handleCancel} width="520px">
      <ModalLevelMasterWrapper>
        <ModalLevelMasterContainer>
          <ModalLevelMasterHeader>
            <HeaderTextWrapper>
              <HeaderFirstText>You are on the Leaderboard</HeaderFirstText>
              <HeaderSecondText>Congratulations!</HeaderSecondText>
            </HeaderTextWrapper>
          </ModalLevelMasterHeader>

          <ModalLevelMasterBody>
            <BodyTextWrapper>
              <BodyText>
                With the arcade activated, earnings will flow until you&apos;re
                dethroned. Play more, share your status, and invite challenges.
              </BodyText>
            </BodyTextWrapper>

            <BodyButtonWrapper>
              <ButtonWrapper>
                <ButtonText onClick={handleCancel}>Continue</ButtonText>
                <ButtonText onClick={() => navigate(PitRouter.GAME_LIST)}>
                  Back to Arcade
                </ButtonText>
                <a href={shareTwitterURL} target="_blank" rel="noreferrer">
                  <ButtonText>Share</ButtonText>
                </a>
              </ButtonWrapper>
            </BodyButtonWrapper>

            <BodyAccountWrapper onClick={() => navigate(PitRouter.PROFILE)}>
              My Account
            </BodyAccountWrapper>
          </ModalLevelMasterBody>
        </ModalLevelMasterContainer>
      </ModalLevelMasterWrapper>
      <GradientLine src={gradientLine} />
      <BackgroundImage src={backgroundImage} />
      <AnimationGif src={gifSrc} />
    </ModalWrapper>
  );
};
