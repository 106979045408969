import { useEffect, useState } from 'react';
import { getAdmin } from 'services/api';

export interface IAdmin {
  username: string;
  avatarURL?: string;
}

export const useGetAdmin = (params, refresh: boolean) => {
  const [admin, setAdmin] = useState<IAdmin[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getAdmin(params);
      if (res?.success) {
        setAdmin(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { admin, total, loading };
};
