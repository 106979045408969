import { ScoringModelLeaderboardTypes } from 'constants/enum/ScoringModel';
import { useEffect, useState } from 'react';
import { getGameLevel, getGameScore, IGameScoreQuery } from 'services/api';

export interface IGameLevel {
  gameId: string;
  level: string;
  levelName: string;
  levelFee: string;
  player: {
    username: string;
    walletAddress: string;
    avatarURL: string;
  };
  playsAmount: number;
  totalOfSpentAmount: string;
  totalOfRevenue: string;
  revenueOfCurrentOwner: string;
  score: number;
  hasOwner: boolean;
}

export interface IGameScore {
  gameId: string;
  level: string;
  levelName: string;
  levelFee: string;
  rank: number;
  player: {
    username: string;
    walletAddress?: string;
    avatarURL: string;
  };
  playsAmount: number;
  score: number;
  totalScore: number;
  totalOfRevenue: string;
}

export const useGetGameLeaderboard = (
  params: IGameScoreQuery,
  scoringModels: ScoringModelLeaderboardTypes,
) => {
  const [loading, setLoading] = useState(false);

  const [gameLevel, setGameLevel] = useState<IGameLevel[]>([]);
  const [gameScore, setGameScore] = useState<IGameScore[]>([]);

  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      let res;

      if (params?.gameId) {
        if (
          scoringModels == ScoringModelLeaderboardTypes.LEVEL_BASED &&
          params.level == null
        ) {
          const res = await getGameLevel(params);

          if (res?.success) {
            if (params?.pageIndex > pageIndex) {
              setGameLevel([...gameLevel, ...res?.payload?.data?.data]);
            } else {
              setGameLevel(res?.payload?.data?.data);
            }
            setTotalCount(res?.payload?.data?.totalCount);
            setPageIndex(res?.payload?.data?.pageIndex);
          }
        } else if (
          scoringModels == ScoringModelLeaderboardTypes.SCORE_BASED &&
          params?.level
        ) {
          const res = await getGameScore(params);
          if (res?.success) {
            if (params?.pageIndex > pageIndex) {
              setGameScore([...gameScore, ...res?.payload?.data?.data]);
            } else {
              setGameScore(res?.payload?.data?.data);
            }
            setTotalCount(res?.payload?.data?.totalCount);
            setPageIndex(res?.payload?.data?.pageIndex);
          }
        }
      }
      setLoading(false);
    })();
  }, [params]);

  return { gameLevel, gameScore, loading, totalCount };
};
