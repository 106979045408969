import { useEffect, useState } from 'react';
import { getLowestFee } from 'services/api';

export const useGetLowestFee = (gameId: string, refresh?: boolean) => {
  const [lowestFee, setLowestFee] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      if (gameId) {
        setLoading(true);
        const res = await getLowestFee(gameId);
        if (res?.success) {
          setLowestFee(res?.payload?.data);
        }
        setLoading(false);
      }
    })();
  }, [gameId, refresh]);
  return { lowestFee, loading };
};
