import { useEffect, useState } from 'react';
import { getTopMetricsTopUsers, ITimeRange } from 'services/api';

interface ITopMetricsTopUsers {
  sortedByTotalEntryFee: IEntryFeeData[];
  sortedByTotalPlays: ITotalPlaysData[];
}

interface IEntryFeeData {
  totalEntryFee: string;
  totalPlays: number;
  username: string;
  avatarURL?: string;
}

interface ITotalPlaysData {
  totalEntryFee: string;
  totalPlays: number;
  username: string;
  avatarURL?: string;
}

export const useGetTopMetricsTopUsers = (params: ITimeRange) => {
  const [topMetricsTopUsers, setTopMetricsTopUsers] =
    useState<ITopMetricsTopUsers>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTopMetricsTopUsers(params);
      if (res?.success) {
        setTopMetricsTopUsers(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return {
    topMetricsTopUsers,
    loading,
  };
};
