import {
  ArrowWrapper,
  BigaEarnAPY,
  CarouselWrapper,
  CustomCarousel,
  HowToEarn,
  StepContent,
  StepContentContainer,
  StepWrapper,
  BigaEarnAPYMobile,
} from 'pages/Staking/styled';
import arrow1 from 'assets/images/staking/arrow-1.svg';
import arrow2 from 'assets/images/staking/arrow-2.svg';
import arrow3 from 'assets/images/staking/arrow-3.svg';

export const StakingCarousel = () => {
  return (
    <CarouselWrapper>
      <CustomCarousel>
        <BigaEarnAPY>Stake BIGA earn 50% APY paid in USDT.</BigaEarnAPY>
        <StepContentContainer>
          <HowToEarn>How to earn USDT</HowToEarn>
          <StepWrapper>
            <StepContent className="step-content-first">
              <h3>• &nbsp; Choose Term</h3>
              Choose 1 of any 3 fixed term deposits. This is the amount of time
              your BIGA tokens will be “locked” the longer the term the higher
              the interest rate meaning the higher the monthly USDT payments
              that hit your wallet.
              <ArrowWrapper src={arrow1} className="first" />
            </StepContent>
            <StepContent className="step-content-second">
              <h3>• &nbsp; Amount of BIGA</h3>
              Choose the total amount of BIGA tokens you want to stake. The more
              you stake the more USDT you earn.
              <ArrowWrapper src={arrow2} className="second" />
            </StepContent>
            <StepContent className="step-content-final">
              <h3>• &nbsp; Click Stake</h3>
              Once you click stake, you will be prompted with a tx confirmation
              from your wallet that holds BiGA tokens. Approve transaction and
              you’re off. You will start earning USDT right away with your first
              payment paid in one month!
              <ArrowWrapper src={arrow2} className="final" />
            </StepContent>
          </StepWrapper>
        </StepContentContainer>
      </CustomCarousel>
      <BigaEarnAPYMobile>
        Stake BIGA earn 50% APY paid in USDT.
      </BigaEarnAPYMobile>
    </CarouselWrapper>
  );
};
