import { ReactNode, useEffect } from 'react';
import { InputWrapper } from './styled';

interface IProps {
  onChange?: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onKeyDown?: (e: any) => void;
  type?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  value?: string;
  autoComplete?: string;
  className?: string;
}

export const Input = ({
  onChange,
  required,
  placeholder,
  onKeyDown,
  type,
  prefix,
  suffix,
  disabled,
  value,
  autoComplete,
  className,
}: IProps) => {
  return (
    <InputWrapper className="input-wrapper">
      {prefix}
      <input
        className={className}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        type={type}
        disabled={disabled}
        value={value}
        autoComplete={autoComplete}
      />
      {suffix}
    </InputWrapper>
  );
};
