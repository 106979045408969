import {
  Checkbox,
  Divider,
  Form,
  Input,
  Menu,
  Radio,
  Select,
  Upload,
} from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 56px;
`;

export const InputField = styled(Input)<{
  color?: string;
  borderColor?: string;
}>`
  /* border: 1px solid ${({ borderColor }) => borderColor || '#bcdfce'}; */
  /* border-radius: 0.6rem; */
  border: none;
  background-color: transparent;
  box-shadow: none !important;
  color: ${({ color }) => color || 'black'};
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  .ant-input {
    text-align: right !important;
  }

  input {
    color: ${({ color }) => color || 'black'} !important;
  }

  :focus,
  :hover {
    border-color: ${({ borderColor }) => borderColor || '#23c879'} !important;
  }

  :disabled,
  :disabled:hover {
    background-color: transparent !important;
    color: ${({ color }) => color || '#23c879'} !important;
  }

  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    color: ${({ color }) => color || '#23c879'};
    display: flex;
    justify-content: center;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const FormItemWrapper = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const FormItemInput = styled(Form.Item)`
  /* width: 98%; */
  margin-bottom: 0px;
  padding: 0px;
  .ant-form-item-label {
    label {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #525458;
    }
  }
`;

export const RatioGameType = styled.div`
  margin-top: 18px;
  .ant-radio-wrapper {
    font-size: 16px;
    color: black;
  }
`;

export const TextField = styled.p<{
  param?: string;
  bgColor?: string;
  wrap?: string;
  flex?: string;
}>`
  text-align: ${props => (props.wrap === 'true' ? 'left' : 'right')};
  color: black;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0;
  padding-right: 15px;
  height: ${props => (props.wrap === 'true' ? 'auto' : '38px')};
  width: 100%;
  max-width: ${props => props.param};
  white-space: ${props => (props.wrap === 'true' ? 'normal' : 'nowrap')};
  overflow: ${props => (props.wrap === 'true' ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  flex-shrink: 0;
  flex: ${props => props.flex};
  background-color: ${props => props.bgColor};
  border: ${props => (props.bgColor ? '1px solid #a7a7a7' : 'none')};
  @media (max-width: 768px) {
    /* max-width: 145px; */
    max-width: ${props => props.param};
    flex: ${props => props.flex};
    font-size: 16px;
  }
`;

export const WarningStyled = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const SuccessStyled = styled.p`
  font-size: 14px;
  color: #2fc169;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const InputWrapper = styled.div`
  align-items: center;
  border: 1px solid #a7a7a7;
  border-radius: 3.5px;
  display: flex;
  max-height: 60px;
  min-height: 60px;
  padding: 10px;
  width: 100%;
`;

export const AutoHeightInputWrapper = styled(InputWrapper)`
  max-height: unset;
`;

export const LabelStyled = styled.div<{ param?: string }>`
  width: 200px;
  max-width: ${props => props.param};
  color: #525458;
  font-size: 20px;
  font-weight: 600;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  @media (max-width: 768px) {
    font-size: 16px;
    width: 120px;
  }
`;

export const CheckBoxWrapper = styled.div<{ param?: boolean | any }>`
  align-items: center;
  border: 1px solid #a7a7a7;
  border-radius: 3.5px;
  background-color: ${props => (props.param == 'true' ? '#3d56da' : '')};
  color: ${props => (props.param == 'true' ? '#fff' : '#AFAFAF')};
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: space-between;
  height: 60px;
  /* margin-top: 10px; */
  padding: 10px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const CheckBoxStyled = styled(Checkbox)<{ param?: boolean | any }>`
  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    background-color: ${props =>
      props.param == 'true' ? '#8497FA' : '#eaeaea'};
  }
  .ant-checkbox-inner::after {
    width: 11px;
    height: 14px;
  }
`;

export const CheckBoxText = styled.div``;

export const StyledMenu = styled(Menu)`
  border: none;
  & .ant-menu.ant-menu-sub.ant-menu-inline,
  .ant-menu.ant-menu-sub.ant-menu-inline:hover,
  .ant-menu.ant-menu-sub.ant-menu-inline:focus,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: transparent;
  }
  & .ant-menu-submenu-selected,
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #fff !important;
  }
  & .ant-menu-inline .ant-menu-item:after,
  .ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-vertical .ant-menu-item:after {
    border: none;
  }
  & .ant-menu-submenu-arrow {
    display: none;
  }
  & .ant-menu-submenu-title,
  & .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto !important;
  }
  & .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 !important;
  }
`;

export const StyledTextArea = styled(InputField.TextArea)`
  resize: none;
`;

export const SelectStyled = styled(Select)`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
  height: 50px;
  text-align: center;
  flex: 1;
  width: 0 !important;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: none;
    height: 45px;
    overflow-y: auto;
  }
  .ant-select-selection-item {
    font-weight: 600;
  }
  .ant-select-selection-placeholder {
    color: #0b3885;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
  }
`;

export const RadioGroupStyled = styled(Radio.Group)`
  margin: 10px;
  .ant-radio-wrapper {
    margin: 0 40px 0 0;
    font-size: 20px;
  }
  .ant-radio-wrapper-checked {
    font-weight: 600;
  }
`;

export const DividerStyled = styled(Divider)`
  margin-left: -4px;
  margin-right: -4px;
  border-top-color: #d9d9d9;
`;

export const GroupTitleStyled = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
`;

export const UploadDragger = styled(Upload.Dragger)`
  user-select: none;

  &.ant-upload.ant-upload-drag {
    background: #fff;
    border: none;
  }

  &.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
`;

export const UploadFileZipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: #0b3885;
  font-size: 17px;
  font-weight: 600;

  .anticon-close-circle {
    color: #a7a7a7;
  }
`;

export const UploadText = styled.div`
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
`;

export const BackgroundGameImage = styled.div<{ src?: string }>`
  width: 80px;
  height: 100px;
  border: 1px solid #a7a7a7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  position: relative;

  .anticon-close-circle {
    scale: 1;
    top: -0.5rem;
    right: -0.5rem;
    color: #a7a7a7;
    position: absolute;
    :hover {
      scale: 1.2;
    }
  }
`;

export const ImageCropperWrapper = styled.div`
  max-width: 400px;
`;
