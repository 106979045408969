import { Button, Image } from 'antd';
import styled from 'styled-components';

export const TwitterButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 46px;
  background: #e4e4e4;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 75px;
`;

export const TextTwitterBtn = styled.p`
  margin: 0;
  font-style: normal;
  font-size: 16px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000;
  img {
    margin-right: 10px;
  }
`;

export const ButtonStyle = styled(Button)<any>`
  /* background-color: #fff; */
  border-radius: 6.5px;
  box-shadow: 4px 4px 3px #4a6ca4;
  color: #0b3885;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  text-transform: uppercase;
  width: 200px;
  :hover,
  :focus {
    border: none;
    color: #0b3885;
  }
`;

export const TwitterIcon = styled(Image)`
  width: 30px;
`;

export const IntegrateGameStyled = styled(Button)<{ $fontSize?: string }>`
  background: #fff;
  border: none;
  border-radius: 6.5px;
  color: #0b3885 !important;
  font-size: ${({ $fontSize }) => $fontSize || '17px'};
  font-weight: 600;
  height: max-content;
  line-height: 30px;
  padding: 5px 20px;
  width: 200px;

  &:hover {
    transform: scale(1.05);
  }
`;

export const StatusButtonStyled = styled(Button)<any>`
  background-color: ${p =>
    p.action === 'accept'
      ? '#23c879'
      : p.action === 'reject'
      ? '#4d5160'
      : '#ff525b'};
  color: white;
  border: none;
  border-radius: 0.8rem;
  font-size: 14px;
  font-weight: 700;
  width: 110px;

  :hover,
  :focus,
  :active {
    color: white;
    background-color: ${p =>
      p.action === 'accept'
        ? '#23c879'
        : p.action === 'reject'
        ? '#4d5160'
        : '#ff525b'};
    transform: scale(1.05);
  }

  :active {
    transform: scale(0.9);
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    background-color: transparent;
    opacity: 0.5;
    background-image: none !important;
    color: white;
  }
`;

export const ActionButton = styled(Button)<{
  $fontSize?: string;
  dark?: string;
}>`
  background: ${props =>
    props.dark === 'true' ? '#0b3885' : '#fff'} !important;
  border: none;
  border-radius: 6.5px;
  color: ${props => (props.dark === 'true' ? '#fff' : '#0b3885')} !important;
  font-size: ${({ $fontSize }) => $fontSize || '17px'};
  font-weight: 600;
  height: max-content;
  line-height: 30px;
  padding: 5px 20px;

  align-self: center;

  &:hover {
    transform: scale(1.05);
  }

  &.ant-btn-link {
    background: transparent !important;
    border: 1px solid ${props => (props.dark === 'true' ? '#0b3885' : '#fff')};
    color: ${props => (props.dark === 'true' ? '#0b3885' : '#fff')} !important;

    &:hover {
      transform: scale(1.05);
      border: 1px solid ${props => (props.dark === 'true' ? '#0b3885' : '#fff')};
    }
  }
`;

export const HeaderButton = styled.div`
  cursor: pointer;
  align-items: center;
  background: #ffffff;
  border-radius: 2.61575px;
  display: flex;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 700;
  width: 70px;
  height: 25px;

  color: #0b3885;
  padding: 0.4rem 0.5rem;
`;
