import { PitRouter } from 'constants/routers';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useConnectors from 'hooks/useConnectors';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser } from 'redux/slices/player';
import { useAppSelector } from 'redux/store';
import { logout } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';

export const useLogOut = ({
  onRefresh,
  refresh,
}: {
  onRefresh?: () => void;
  refresh?: boolean;
} = {}) => {
  const { account } = useActiveWeb3React();
  const { disconnect } = useConnectors();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { playerInfo } = useGetPlayer(null, refresh);
  const { playerInfo: playerI } = useAppSelector(state => state.player);

  const logOut = async () => {
    await logout(LocalhostStorage.get('refreshToken'));
    LocalhostStorage.remove('accessToken');
    LocalhostStorage.remove('refreshToken');
    LocalhostStorage.remove('tokenType');
    dispatch(logoutUser());
    disconnect();
    if (onRefresh) {
      onRefresh();
    }
    navigate(PitRouter.LOGIN);
  };

  useEffect(() => {
    if (
      playerI &&
      account &&
      playerI.walletAddress &&
      playerI.walletAddress !== account
    ) {
      logOut();
      // toast.error(
      //   'Please login to the account linked to the connected wallet address',
      // );
    }
  }, [account, playerI]);
  return useCallback(logOut, [disconnect, navigate, onRefresh]);
};
