import { useWeb3React } from '@web3-react/core';
import { List, Select } from 'antd';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { TokenItem } from 'components/Cashier/TokenItem';
import { useGetListPaymentToken } from 'hooks/cashier/useGetListPaymentToken';
import useConnectors from 'hooks/useConnectors';
import { useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { LocalhostStorage } from 'utils/sessionStorage';
import {
  injected,
  networkTypeSelected,
  SUPPORTED_NETWORKS,
} from 'utils/wallets/connector';

import {
  CloseIcon,
  CloseWrapper,
  ModalSelectTokenContainer,
  ModalSelectTokenFirstBlock,
  ModalSelectTokenSecondBlock,
  ModalSelectTokenWrapper,
  ModalWrapper,
  NetworkImage,
  SearchOutlinedStyled,
  SelectNetwork,
} from './styled';

const { Option } = Select;

export const SelectToken = ({ onItemSelected }) => {
  const { activate } = useWeb3React();
  const open = useModalIsOpen(ApplicationModal.CASHIER_SELECT_TOKEN);
  const toggleModal = useToggleModal(ApplicationModal.CASHIER_SELECT_TOKEN);
  const handleCancel = () => {
    toggleModal();
  };

  const handleItemSelected = item => {
    onItemSelected(item);
    handleCancel();
  };
  const deposits = Object.values(SUPPORTED_NETWORKS).filter(
    network => network.netType === networkTypeSelected,
  );
  const defaultChainId = process.env.REACT_APP_CHAIN_ID;
  const defaultDepositName =
    deposits.find(dep => dep.chainId === LocalhostStorage.get('chainId'))
      ?.name ?? '';
  const defaultDepositChainId =
    deposits.find(dep => dep.chainId === LocalhostStorage.get('chainId'))
      ?.chainId ?? +defaultChainId;
  const [searchQuery, setSearchQuery] = useState('');
  const [params, setParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    chainId: defaultDepositChainId,
  });
  const { onConnectMetamaskWallet } = useConnectors();

  const [selectedDeposit, setSelectedDeposit] = useState(defaultDepositName);

  const {
    listPaymentToken,
    loading: listPaymentTokenLoading,
    total: listPaymentTokenTotal,
  } = useGetListPaymentToken(params);

  const handleSelect = deposit => {
    try {
      if (!deposit) return;
      const getSelectedChainId = deposits.find(
        dep => dep.name === deposit,
      ).chainId;
      LocalhostStorage.set('chainId', getSelectedChainId);
      activate(injected(getSelectedChainId));
      onConnectMetamaskWallet();
      setSelectedDeposit(deposit);
      setParams(prev => ({
        ...prev,
        chainId: getSelectedChainId,
      }));
    } catch (err) {
      console.log('Change network error ', err);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      width="528px"
    >
      <ModalSelectTokenWrapper>
        <ModalSelectTokenContainer>
          <ModalSelectTokenFirstBlock>
            Select a cryptocurrency
          </ModalSelectTokenFirstBlock>

          <ModalSelectTokenSecondBlock
            placeholder="Search for a cryptocurrency"
            allowClear
            addonBefore={<SearchOutlinedStyled />}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <SelectNetwork
            value={selectedDeposit}
            onChange={e => {
              handleSelect(e);
            }}
            style={{ width: '12rem' }}
          >
            {deposits?.map(deposit => (
              <Option key={deposit.chainId} value={deposit.name}>
                <NetworkImage
                  style={{ width: '1rem', height: '1rem' }}
                  src={deposit?.iconUrl}
                />
                {deposit.name}
              </Option>
            ))}
          </SelectNetwork>
          <List
            dataSource={
              listPaymentToken
                ? listPaymentToken.filter(
                    item =>
                      item.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      item.symbol
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                  )
                : []
            }
            renderItem={item => (
              <TokenItem item={item} handleItemSelected={handleItemSelected} />
            )}
          />
        </ModalSelectTokenContainer>
      </ModalSelectTokenWrapper>
    </ModalWrapper>
  );
};
