import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gameId: undefined,
};

const detailSlice = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    setGameId: (state, { payload }) => {
      state.gameId = payload;
    },
  },
});

export const { setGameId } = detailSlice.actions;

export default detailSlice.reducer;
