import { useCallback } from 'react';
import { RootState } from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { ApplicationModal, setOpenModal } from '.';

export function useModalIsOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector(
    (state: RootState) => state.applicationModal.openModal,
  );
  return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalIsOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(setOpenModal(open ? null : modal));
  }, [dispatch, modal, open]);
}
