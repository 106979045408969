import { useEffect, useState } from 'react';
import { getAffiliateTopSignUp, ITimeRange } from 'services/api';

interface ITopAffiliate {
  username?: string;
  walletAddress: string;
  avatarURL?: string;
  totalSignUp: number;
}

export const useGetAffiliateTopSignUp = (params: ITimeRange) => {
  const [topAffiliateSignUp, setTopAffiliateSignUp] =
    useState<ITopAffiliate[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getAffiliateTopSignUp(params);
      if (res?.success) {
        setTopAffiliateSignUp(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return {
    topAffiliateSignUp,
    loading,
  };
};
