import { ReactNode } from 'react';
import { BackgroundGradient, StyledModal } from './styled';
import gradientImg from 'assets/images/profile/popup-gradient.png';

interface IProps {
  title?: string;
  className?: string;
  isOpen: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  children: ReactNode;
  okText?: string;
  bodyStyle?: any;
  width?: string;
}
export const UpdateInformationModal = ({
  title,
  isOpen,
  onCancel,
  children,
  okText,
  bodyStyle,
  width,
}: IProps) => {
  return (
    <StyledModal
      title={title}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      okText={okText}
      bodyStyle={bodyStyle}
      width={width}
    >
      {children}
      <BackgroundGradient src={gradientImg} />
    </StyledModal>
  );
};
