import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const CarouselContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
  margin: 0;
  color: #fff;
  flex-direction: column;
  row-gap: 30px;
`;

export const CarouselContainerContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  overflow-x: scroll;
  width: 100vw;

  &::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
  }
`;

export const CarouselItemList = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 40px;
  align-items: stretch;

  ${media.sm} {
    margin: 0 calc((100vw - 325px) / 2);
  }
`;

export const CarouselCardWrapper = styled.div`
  background-color: #222;
  border-radius: 29px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: transform 0.5s ease;
  width: 325px;
  flex: 1;
  margin-bottom: 15px;
  margin-top: 15px;

  transition: all ease-in-out 0.3s;

  &:hover {
    scale: 1.04;
    box-shadow: 5px 5px 3px rgba(255, 255, 255, 0.1);
  }
`;

export const CarouselNavButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 90vw;
  /* margin: auto; */

  * {
    font-size: 50px;
    font-weight: 100;
    line-height: 10px;
  }
`;
