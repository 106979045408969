import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timestamp: '',
};

const timestampSlice = createSlice({
  name: 'timestamp',
  initialState,
  reducers: {
    setTimestamp: (state, { payload }) => {
      state.timestamp = payload;
    },
  },
});

export const { setTimestamp } = timestampSlice.actions;

export default timestampSlice.reducer;
