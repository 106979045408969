import { UpdateInformationModal } from 'components/Modals/UserProfile/UpdateInformationModal';
import { ReactComponent as CongratulationText } from 'assets/images/modals/congratulation-text.svg';
import styled from 'styled-components';
import { Button } from 'antd';
import { media } from 'constants/enum/breakpoints';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(UpdateInformationModal)`
  position: relative;
  & > .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-close-icon {
    svg {
      fill: #fff;
    }
  }
`;

export const ModalLevelMasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

export const ModalLevelMasterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalLevelMasterHeader = styled.div`
  display: flex;
  padding: 25px 83.5px 48px;
  justify-content: center;
  align-items: center;

  ${media.sm} {
    padding: 25px 83.5px 36px;
  }
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  ${media.sm} {
    row-gap: 5px;
  }
`;

export const HeaderFirstText = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-weight: 700;

  ${media.sm} {
    font-size: 20px;
  }
`;

export const HeaderSecondText = styled(CongratulationText)`
  width: 450px;

  ${media.sm} {
    width: 300px;
  }
`;

export const ModalLevelMasterBody = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;

  ${media.sm} {
    font-size: 18px;
  }
`;

export const BodyTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;

  ${media.sm} {
    padding: 0px 5px;
  }
`;

export const BodyText = styled.div`
  color: #fff;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
`;

export const BodyButtonWrapper = styled.div`
  display: flex;
  padding: 0 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.sm} {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  ${media.sm} {
    gap: 10px;
  }
`;

export const ButtonText = styled(Button)`
  color: #fff;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 12px;
  height: 46px;
  padding: 0 22px;
  border: none;
  justify-content: center;
  display: flex;
  align-items: center;

  ${media.sm} {
    padding: 0 12px;
    font-size: 14px;
  }
`;

export const BodyAccountWrapper = styled(ButtonText)`
  border-radius: 50px;
  height: 58px;

  ${media.sm} {
    font-size: 16px;
    padding: 0 22px;
  }
`;

export const GradientLine = styled.img`
  position: absolute;
  left: 0;
  top: 145px;
  z-index: 52;

  ${media.sm} {
    top: 130px;
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  left: -120px;
  top: -100px;
  pointer-events: none;
  width: 150%;
  height: 150%;
  opacity: 0.4;
`;

export const AnimationGif = styled.img`
  position: absolute;
  left: -110px;
  top: -100px;
  pointer-events: none;
  width: 140%;
  height: 140%;
  z-index: 53;

  ${media.sm} {
    left: -30px;
    top: -40px;
    width: 120%;
    height: 120%;
  }
`;
