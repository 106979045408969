import { useWeb3React } from '@web3-react/core';
import defaultTokenImg from 'assets/images/default-token.png';
import { useGetTokenInfo } from 'hooks/erc20/useGetTokenInfo';
import { useState } from 'react';

import {
  Image,
  TextDecription,
  TextName,
  TextValue,
  ThirdBlockItem,
  ThirdBlockItemLeft,
  ThirdBlockItemLeftContent,
  ThirdBlockItemRight,
} from './styled';

interface IProps {
  item: any;
  handleItemSelected: (item) => void;
}

export const TokenItem = ({ item, handleItemSelected }: IProps) => {
  const [refresh, setRefresh] = useState<boolean>(false);

  const { account } = useWeb3React();

  const { tokenInfo, loading: tokenLoading } = useGetTokenInfo(
    item?.address,
    refresh,
  );
  return (
    <ThirdBlockItem
      key={item?.address}
      onClick={() => handleItemSelected(item)}
    >
      <ThirdBlockItemLeft>
        <Image src={item?.iconUrl || defaultTokenImg} alt={item?.name} />
        <ThirdBlockItemLeftContent>
          <TextName>{item?.name || 'hihi'}</TextName>
          <TextDecription>{item?.symbol}</TextDecription>
        </ThirdBlockItemLeftContent>
      </ThirdBlockItemLeft>
      <ThirdBlockItemRight>
        {tokenInfo?.balanceOf && !tokenLoading && account ? (
          <TextValue>
            {Number(tokenInfo?.balanceOf)
              .toFixed(2)
              .replace(/\.?0+$/, '') || 0}{' '}
            {item.symbol}
          </TextValue>
        ) : (
          <TextValue>... {item.symbol}</TextValue>
        )}
        <TextDecription>Available balance</TextDecription>
      </ThirdBlockItemRight>
    </ThirdBlockItem>
  );
};
