import { PlayerRoles } from 'constants/enum/PlayerRole';
import { RegisterStep } from 'constants/enum/RegisterStep';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { AdminPanel } from 'pages/AdminPanel';
import { AdminStats } from 'pages/AdminStats';
import { Affiliate } from 'pages/Affiliate';
import { ResetPassword } from 'pages/ForgotPassword';
import { GameList } from 'pages/GameList';
import { GamesPage } from 'pages/Games';
import { LandingPage } from 'pages/LandingPage';
import { LeaderBoard } from 'pages/Leaderboard';
import { LoginPage } from 'pages/Login';
import { PlayGame } from 'pages/PlayGame';
import { PosterPage } from 'pages/Poster';
import { Profile } from 'pages/Profile';
import { SignUpAccount } from 'pages/Register';
import { SupportPage } from 'pages/Support';
import { Suspense } from 'react';
import ReactGA from 'react-ga4';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Layout from 'template/Layout';

import { Cashier } from 'pages/Cashier';
import { usePublicPermission } from '../hooks/usePublicPermission';
import MaintenanceRoute from './MaintenanceRoute';
import { PassCodeRoute } from './PassCodeRoute';
import { PrivateRoute } from './PrivateRoute';
import { RoleProhibitRoute } from './RoleProhibitRoute';
import { RoleRoute } from './RoleRoute';
import { StakingPage } from 'pages/Staking';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID);

export const AppRouter = () => {
  const isPublicPermission = usePublicPermission();
  const isEnableCashier = useEnableCashier();
  return (
    <Router basename="/">
      <Suspense fallback={<LandingPage />}>
        <Routes>
          <Route element={<MaintenanceRoute />}>
            <Route path={PitRouter.HOME} element={<LandingPage />} />
            <Route
              path={PitRouter.REGISTER}
              element={
                <PassCodeRoute>
                  <SignUpAccount defaultStep={RegisterStep.enterDetail} />
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.AFFILIATE_CODE}
              element={
                <PassCodeRoute>
                  <SignUpAccount defaultStep={RegisterStep.enterDetail} />
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.LOGIN}
              element={
                <PassCodeRoute>
                  <LoginPage />
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.FORGOT_PASSWORD}
              element={
                <PassCodeRoute>
                  <ResetPassword />
                </PassCodeRoute>
              }
            />
            {isPublicPermission ? (
              <Route path={PitRouter.GAME_LIST} element={<GamesPage />} />
            ) : (
              <></>
            )}
            <Route element={<Layout />}>
              <Route
                path={PitRouter.LEADER_BOARD}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <RoleProhibitRoute roles={[PlayerRoles.Tester]}>
                        <LeaderBoard />
                      </RoleProhibitRoute>
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.GAME_LIST}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <GamesPage />
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.MANAGEMENT}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <RoleRoute
                        roles={[
                          PlayerRoles.SuperAdmin,
                          PlayerRoles.Admin,
                          PlayerRoles.Provider,
                        ]}
                      >
                        <GameList />
                      </RoleRoute>
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.ADMIN_PANEL}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <RoleRoute
                        roles={[PlayerRoles.SuperAdmin, PlayerRoles.Admin]}
                      >
                        <AdminPanel />
                      </RoleRoute>
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.ADMIN_STATS}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <RoleRoute
                        roles={[PlayerRoles.SuperAdmin, PlayerRoles.Admin]}
                      >
                        <AdminStats />
                      </RoleRoute>
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.POSTER}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <PosterPage />
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.PLAY_GAME}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <PlayGame />
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.PROFILE}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              {isEnableCashier && (
                <Route
                  path={PitRouter.CASHIER}
                  element={
                    <PassCodeRoute>
                      <PrivateRoute>
                        <RoleProhibitRoute roles={[PlayerRoles.Tester]}>
                          <Cashier />
                        </RoleProhibitRoute>
                      </PrivateRoute>
                    </PassCodeRoute>
                  }
                />
              )}
              <Route
                path={PitRouter.SUPPORT}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <SupportPage />
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              <Route
                path={PitRouter.AFFILIATE}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <RoleRoute roles={[PlayerRoles.Influencer]}>
                        <Affiliate />
                      </RoleRoute>
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
              {process.env.REACT_APP_IS_HIDE_STAKING !== 'true' && (
                <Route
                  path={PitRouter.STAKING}
                  element={
                    <PassCodeRoute>
                      <PrivateRoute>
                        <StakingPage />
                      </PrivateRoute>
                    </PassCodeRoute>
                  }
                />
              )}
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};
