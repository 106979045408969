import bigaArcadeLogo from 'assets/vectors/BIGA.svg';
import { Link } from 'react-router-dom';
import {
  LandingFooterContentContactUs,
  LandingFooterContentPolicyLinksColumn,
  LandingFooterContentPolicyLinksWrapper,
  LandingFooterContentSocialLinksColumn,
  LandingFooterContentSocialLinksWrapper,
  LandingFooterContentWrapper,
  LandingFooterMailWrapper,
  LandingFooterSectionWrapper,
} from './styled';

import discord from 'assets/icons/discord.svg';
import instagram from 'assets/icons/instagram.svg';
import mail from 'assets/icons/mail.svg';
import twitter from 'assets/icons/twitter.svg';

export const LandingFooterSection = () => {
  const termsLink = `${process.env.REACT_APP_HOST}/termsandconditions.pdf`;
  const policyLink = `${process.env.REACT_APP_HOST}/privacy-policy.pdf`;

  return (
    <LandingFooterSectionWrapper>
      <LandingFooterContentWrapper>
        <LandingFooterContentContactUs>
          <img src={bigaArcadeLogo} alt="biga-arcade" />
          <div>
            <p>Our mission is to give one million gamers,</p>
            <p>a reliable $1000 a month income</p>
          </div>
        </LandingFooterContentContactUs>

        <LandingFooterContentPolicyLinksWrapper className="desktop">
          <LandingFooterContentPolicyLinksColumn>
            <Link className="blur-link" to={termsLink} target="_blank">
              Terms and Conditions
            </Link>
            <Link className="blur-link" to={policyLink} target="_blank">
              Privacy Policy
            </Link>
          </LandingFooterContentPolicyLinksColumn>
        </LandingFooterContentPolicyLinksWrapper>

        <LandingFooterContentSocialLinksWrapper>
          <LandingFooterMailWrapper>
            <Link to="mailto:hello@bigarcade.org" className="mail-to-contact">
              hello@bigarcade.org
            </Link>
          </LandingFooterMailWrapper>
          <LandingFooterContentSocialLinksColumn>
            <Link to="https://twitter.com/bigaarcade" target="_blank">
              <img src={twitter} alt="twitter" />
            </Link>
            <Link to="https://www.instagram.com/bigaarcade/" target="_blank">
              <img src={instagram} alt="instagram" />
            </Link>
            <Link to="https://discord.gg/nGcGdS7tT3" target="_blank">
              <img src={discord} alt="discord" />
            </Link>
            <Link to="mailto:hello@bigarcade.org" target="_blank">
              <img src={mail} alt="mail" />
            </Link>
          </LandingFooterContentSocialLinksColumn>
        </LandingFooterContentSocialLinksWrapper>

        <LandingFooterContentPolicyLinksWrapper className="mobile">
          <LandingFooterContentPolicyLinksColumn>
            <Link className="blur-link" to={termsLink} target="_blank">
              Terms and Conditions
            </Link>
            <Link className="blur-link" to={policyLink} target="_blank">
              Privacy Policy
            </Link>
          </LandingFooterContentPolicyLinksColumn>
        </LandingFooterContentPolicyLinksWrapper>
      </LandingFooterContentWrapper>
    </LandingFooterSectionWrapper>

    // <LandingFooterBottomDiv></LandingFooterBottomDiv>
  );
};
