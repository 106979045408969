import { useEffect, useState } from 'react';
import { useStakeCallback } from './useStakeCallback';

interface IStakeInfo {
  amount: number;
  term: number;
  startTime: string;
}

export const useGetStakeUser = (address: string, refresh?: boolean) => {
  const [stakeInfo, setStakeInfo] = useState<IStakeInfo>();
  const { stakes } = useStakeCallback();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await stakes(address);
      setStakeInfo(res);
      setLoading(false);
    })();
  }, [stakes, address, refresh]);
  return { stakeInfo, loading };
};
