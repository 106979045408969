import { Image, Row, Segmented } from 'antd';
import background from 'assets/images/homepage.png';
import { ColumnCenter } from 'components/Column';
import styled from 'styled-components';

export const WrapperHome = styled(ColumnCenter)`
  position: absolute;
  bottom: 2px;
  z-index: 2;
`;
export const WrapperSegment = styled.div`
  margin: 0px 100px;
  position: relative;
`;

export const MainContent = styled.div<{ backgroundURL?: string }>`
  /* margin-left: 100px;
  margin-right: 100px; */
  padding-top: 80px;
  flex-grow: 1;
  /* @media (max-width: 1600px) {
    margin: 0px 100px;
  } */

  /* @media (max-width: 1024px) and (min-width: 821px) {
    margin: 0px;
    padding: 0 80px;
  }
  @media (max-width: 820px) {
    padding: 0;
    margin: 0px 20px;
  } */
  /* @media (min-width: 1601px) {
    max-width: 1380px;
    margin: auto;
    width: 90%;
  } */
  background-image: ${props =>
    props.backgroundURL
      ? `linear-gradient(
      180deg,
      rgba(6, 32, 76, 0.4) 0%,
      #06204c 57.81%
    ),
    url(${props.backgroundURL})`
      : ''};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const HomeSegment = styled(Segmented)`
  background-color: #5180f4;
  border-radius: 10px;
  padding: 5px;

  :hover {
    background-color: #5180f4 !important;
  }
  .ant-segmented-item {
    border-radius: 10px !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: white;
    padding: 8px 30px;
  }

  .ant-segmented-item-selected {
    color: #5180f4;
    background-color: white;
  }
`;

export const GridItems = styled(Row)`
  margin: 0 !important;
  width: 100%;
`;

export const BackgroundHomepage = styled.div`
  /* background: url(${background}); */
  height: 100vh;
  background-size: 100%;
`;

export const Background = styled(Image)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
`;

export const MainBackground = styled(Image)`
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -10.1rem;
  z-index: -1;
  @media (max-width: 1024px) {
    top: -15.1rem;
  }
  @media (max-width: 1024px) and (max-height: 768px) {
    top: -10.5rem;
  }
  @media (max-width: 820px) {
    width: fit-content;
    top: -13.1rem;
  }
  @media (max-width: 576px) {
    width: auto;
    top: -10.1rem;
  }
`;

export const MainLayout = styled.div<{ isFull: boolean }>`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  margin-left: 145px;
  transition: all 0.3s;
  @media (max-width: 1440px) {
    margin-left: ${props => (!props.isFull ? '118px' : '0px')};
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  cursor: pointer;

  svg {
    background-color: #0b3885;
    width: 30px;
    height: 30px;

    path {
      stroke: #fff;
    }
  }

  @media (max-width: 550px) {
    display: block;
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 99;

    &.with-desktop {
      left: 118px;
    }
  }
`;
