import { Divider, Select } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const SelectGameStyled = styled(Select)`
  border: rgb(111 199 87 / 46%) 1px solid;
  border-radius: 6.5px;
  font-size: 17px;
  font-weight: 600;
  width: 180px;
  text-align: center;

  @media (max-width: 640px) {
    margin-top: 10px;
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 6.5px;
    border: none;
  }

  .ant-select-selection-item {
    font-weight: 600;
  }

  .ant-select-selection-placeholder {
    color: #0b3885;
  }
`;

export const LeaderBoardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px 40px 100px;
  align-items: center;
  row-gap: 10px;
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  ${media.xs} {
    padding: 40px 12px 100px;
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100vh - 94px);
`;

export const RightWrapper = styled.div`
  gap: 30px;
  width: 100%;
  padding: 10px 41px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.md2} {
    padding: 10px 0px 0;
  }
`;

export const GeneralButtonWrapper = styled.div`
  display: flex;
  height: 40px;
  max-width: 100%;
  padding: 0 40px;

  ${media.md2} {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div<{ active?: boolean }>`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: left;
  flex: 1;
  /* border-bottom: ${props =>
    props?.active ? '1px solid rgba(0, 170, 253, 1)' : 'none'}; */
`;

export const ButtonText = styled(Text)<{ active?: boolean }>`
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
  /* color: ${props =>
    props?.active ? 'rgba(0, 170, 253, 1)' : 'rgba(255, 255, 255, 0.3)'}; */
`;

export const ListGameContainer = styled.div`
  width: 46%;
  box-sizing: border-box;

  ${media.md2} {
    width: 340px;
    margin-bottom: 5px;
  }
`;

export const FullGameContainer = styled.div`
  padding: 16px 40px 0;
  display: flex;

  ${media.md2} {
    padding: 16px 0 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 8px;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: rgba(86, 86, 86, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;

export const StyledDivider = styled(Divider)`
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  height: 180px;
`;

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 8%;

  ${media.md2} {
    margin-bottom: 5px;
    padding: 0 20px;
  }
`;

export const TitleAndListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListGameWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  gap: 16px;
  padding: 0 5px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 0;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track-piece {
    background: rgba(86, 86, 86, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 1);
  }

  .ant-list-split .ant-list-item {
    border: none;
  }

  .ant-list-item {
    padding: 0;
    margin-bottom: 12px;
  }

  .ant-list-items {
    display: flex;
    gap: 20px;
    height: 180px;
  }
`;

export const GameWrapper = styled.img<{ selected?: boolean }>`
  width: 121px;
  height: 151.25px;
  object-fit: cover;
  position: relative;
  cursor: pointer;
  border-radius: 22px;

  &:hover {
    outline: 2px solid rgba(0, 170, 253, 1);
  }

  outline: ${props =>
    props?.selected ? '2px solid rgba(0, 170, 253, 1)' : 'none'};
`;

export const InformationWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 32px 0px;
  display: flex;
  justify-content: space-between;
  background: #15151b;
  border-radius: 10px;
  margin-top: 40px;

  ${media.md2} {
    flex-direction: column;
    gap: 20px;
  }

  ${media.sm} {
    padding: 10px 0;
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  ${media.md2} {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    padding: 0 30px;
  }

  ${media.sm} {
    padding: 0 10px;
  }
`;

export const DetailLabelWrapper = styled(Text)`
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
`;

export const DetailValueWrapper = styled(Text)`
  color: #fff;
  font-size: 24px;
  font-weight: 600;

  ${media.sm} {
    font-size: 18px;
  }
`;

export const SelectMultiLevel = styled(Select)`
  border-radius: 12px;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  height: 48px;
  width: 180px;
  text-align: center;
  width: 200px;

  svg {
    fill: #fff;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-weight: 600;
      font-size: 17px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    text-align: left;
    width: 100%;
    border-radius: 10px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 6.5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.03);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
    border-radius: 12px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
`;

export const OptionItem = styled.span`
  color: #fff;
`;

export const SelectMultiLevelMinisize = styled(SelectMultiLevel)`
  width: 140px;
  font-size: 14px;

  .ant-select-selector {
    .ant-select-selection-item {
      font-size: 14px;
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(255, 255, 255, 0.07);
  }
`;

export const TitlePageWrapper = styled.div`
  gap: 12px;
  display: flex;
  font-size: 32px;
  font-weight: 700;
  align-items: center;
  color: #fff;
`;

export const LeaderboardIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 187, 255, 0.24);
  border-radius: 50px;
  svg path {
    fill: #00aafd;
  }
`;

export const GradientBottom = styled.img`
  position: absolute;
  bottom: -400px;
  right: -550px;
  pointer-events: none;
  width: 1200px;

  ${media.sm} {
    display: none;
  }
`;
