import { useEffect, useState } from 'react';
import { getNotificationSetting } from 'services/api';

export const useGetNotificationSetting = (refresh?: boolean) => {
  const [notificationSettingList, setNotificationSettingList] = useState<any[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getNotificationSetting();
      if (res?.success) {
        setNotificationSettingList(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [refresh]);
  return { notificationSettingList, loading };
};
