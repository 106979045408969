import {
  GameBigBox,
  GameContentDescription,
  GameContentHeading,
  GameContentHeadingWrapper,
  GameContentRevenu,
  GameContentWrapper,
  GameLogo,
  GameLogoContainer,
  GameName,
  GameSmallBox,
  GameTags,
  GameThumbnailImg,
  GameThumbnailWrapper,
  GameWrapper,
  OurGamesDescription,
  OurGamesTitle,
  OurGamesTitleWrapper,
  Wrapper,
} from './styled';
import WatermelonLogo from 'assets/main/games/watermelon-logo.png';
import WatermelonThumbnail from 'assets/main/games/watermelon-thumbnail.png';
import BabyDashLogo from 'assets/main/games/baby-dash-logo.png';
import BabyDashThumbnail from 'assets/main/games/baby-dash-thumbnail.png';
import KektLogo from 'assets/main/games/kekt-logo.png';
import KektThumbnail from 'assets/main/games/kekt-thumbnail.png';
import StoshiWorldLogo from 'assets/main/games/stoshi-world-logo.png';
import StoshiWorldThumbnail from 'assets/main/games/stoshi-world-thumbnail.png';
import JetpackLogo from 'assets/main/games/jetpack-logo.png';
import JetpackThumbnail from 'assets/main/games/jetpack-thumbnail.png';
import StoshiRunLogo from 'assets/main/games/stoshi-run-logo.png';
import StoshiRunThumbnail from 'assets/main/games/stoshi-run-thumbnail.png';
import WenLamboLogo from 'assets/main/games/wen-lambo-logo.png';
import WenLamboThumbnail from 'assets/main/games/wen-lambo-thumbnail.png';
import SlimixLogo from 'assets/main/games/slimix-logo.png';
import SlimixThumbnail from 'assets/main/games/slimix-thumbnail.png';
import BinaryLogo from 'assets/main/games/binary-logo.png';
import BinaryThumbnail from 'assets/main/games/binary-thumbnail.png';
import BlockspaceLogo from 'assets/main/games/blockspace-logo.png';
import BlockspaceThumbnail from 'assets/main/games/blockspace-thumbnail.png';
import PlayerIcon from 'assets/main/games/players.png';
import { MainButton } from 'components/Button/MainButton';
import { CarouselCard, CarouselContainer } from 'components/Carousel';
import { useEffect, useState } from 'react';
import { useGetGameActive } from 'hooks/games/useGetGameActive';
import { formatNumber } from 'utils/formatNumber';

interface Game {
  gameId: string;
  logoImg: string;
  thumbnailImg: string;
  nofPlayers: number;
  name: string;
  totalRevenu: number;
  description: string;
  tags: string[];
}

const GAME_LIST: Game[] = [
  {
    gameId: 'watermelongame',
    logoImg: WatermelonLogo,
    thumbnailImg: WatermelonThumbnail,
    nofPlayers: 100000,
    name: 'Watermelon game',
    totalRevenu: 800690,
    description:
      'Physics based puzzle game where you have to match fruits and try to get the highest score possible.',
    tags: ['Puzzle', 'Highscore', 'Physics', 'Suika Game'],
  },
  {
    gameId: 'baby-dash',
    logoImg: BabyDashLogo,
    thumbnailImg: BabyDashThumbnail,
    nofPlayers: 100000,
    name: 'Baby Dash',
    totalRevenu: 252690,
    description:
      "Bike around the perilous canyon track. It's not just a race, it's a battle against gravity, obstacles, and your own reflexes. ",
    tags: ['Endless Run', 'Highest Total Score'],
  },
  {
    gameId: 'kekt',
    logoImg: KektLogo,
    thumbnailImg: KektThumbnail,
    nofPlayers: 100000,
    name: 'Kekt',
    totalRevenu: 177762.1,
    description:
      'Pepe navigates the worlds of critters to get to his nemesis (other famous memes).',
    tags: ['Level Ownership', 'Platformer', 'Boss'],
  },
  {
    gameId: 'stoshi-world',
    logoImg: StoshiWorldLogo,
    thumbnailImg: StoshiWorldThumbnail,
    nofPlayers: 100000,
    name: 'Stoshi World',
    totalRevenu: 166380.25,
    description:
      'Stoshi World is our flagship Platforming maze where Stoshi must collect all coins, avoid enemies and head to the wallet before time runs out.',
    tags: ['Level Ownership', 'Platformer'],
  },
  {
    gameId: 'jetpack-galaxy-rush',
    logoImg: JetpackLogo,
    thumbnailImg: JetpackThumbnail,
    nofPlayers: 100000,
    name: 'Jetpack Galaxy Rush',
    totalRevenu: 166380.25,
    description:
      'Defy gravity in Jetpack Galaxy Rush! Soar through the unknown world in this 2D side-scrolling adventure.',
    tags: [
      'Boss Fight',
      'Shooter',
      'Endless Run',
      'Highest Total Score',
      'Sidescroller',
    ],
  },
  {
    gameId: 'stoshi-run',
    logoImg: StoshiRunLogo,
    thumbnailImg: StoshiRunThumbnail,
    nofPlayers: 100000,
    name: 'Stoshi Run',
    totalRevenu: 166380,
    description:
      'Stoshi is back to take on the challenge of FREE running across the metropolitan skyline against his opponents. ',
    tags: ['Endless Run', 'Highscore', 'Platformer'],
  },
  {
    gameId: 'wen-lambo',
    logoImg: WenLamboLogo,
    thumbnailImg: WenLamboThumbnail,
    nofPlayers: 100000,
    name: 'Wen Lambo',
    totalRevenu: 177762.1,
    description:
      'Wen Lambo is a combination of speed racing and drifting through a track which is endless and over time becomes more challenging to navigate. ',
    tags: ['Highscore', 'Endless Run'],
  },
  {
    gameId: 'slimix-showdown',
    logoImg: SlimixLogo,
    thumbnailImg: SlimixThumbnail,
    nofPlayers: 100000,
    name: 'Slimix Showdown',
    totalRevenu: 20925,
    description:
      'Puzzle battle game where players merge coloured slimes to create powerful combos and trigger explosive chain reaction.',
    tags: [
      'Puzzle',
      'Highscore',
      'Fighting',
      'Versus',
      'Casual',
      'Match',
      'Upgrade',
    ],
  },
  {
    gameId: 'binary-shift',
    logoImg: BinaryLogo,
    thumbnailImg: BinaryThumbnail,
    nofPlayers: 100000,
    name: 'Binary Shift',
    totalRevenu: 177762.1,
    description:
      'Binary Shift places you in the shoes of our anonymous hacker where you must combine your swift actions with his binary world manipulation.',
    tags: ['Highscore', 'Game Jam', 'King of the hill', 'Hacker'],
  },
  {
    gameId: 'blockspace',
    logoImg: BlockspaceLogo,
    thumbnailImg: BlockspaceThumbnail,
    nofPlayers: 100000,
    name: 'Blockspace',
    totalRevenu: 177762.1,
    description:
      'Mind-bending puzzle game that challenges players to guide blocks through a series of intricate mazes, obstacles.',
    tags: ['Level Ownership', 'Fastest Time', 'Geometry', 'Physics'],
  },
];

export const OurGames = () => {
  const [gamesList, setGamesList] = useState(GAME_LIST);
  const [params] = useState({
    showStatistic: true,
    ids: gamesList.map(game => game.gameId),
  });

  const { gameActive } = useGetGameActive(params);

  useEffect(() => {
    if (!gameActive) return;
    setGamesList(prevGames =>
      prevGames.map(prevGame => {
        const filtered = gameActive.find(
          game => game.gameId == prevGame.gameId,
        );
        return !filtered
          ? prevGame
          : {
              ...prevGame,
              nofPlayers: filtered.totalPlayers,
              totalRevenu: filtered.totalRevenue,
              tags: filtered.tags,
            };
      }),
    );
  }, [gameActive]);

  const handlePlayNow = (gameId: string) => {
    window.open(
      `${window.location.origin}/${gameId}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <Wrapper>
      <OurGamesTitleWrapper>
        <OurGamesTitle>
          OUR
          <span style={{ color: '#E961FF' }}> GAMES </span>
        </OurGamesTitle>
        <OurGamesDescription>
          Multiple Games, levels, scoring models. Find the right game for you
          and became a level master and earn.
        </OurGamesDescription>
      </OurGamesTitleWrapper>
      {gamesList.map((game, index) => (
        <GameWrapper className="desktop" key={index}>
          <GameSmallBox className="small-box">
            <GameLogo className="logo" src={game.logoImg} alt="" />
            <GameLogo className="play-now" src={game.logoImg} alt="" />
            <MainButton
              className="play-now"
              onClick={() => handlePlayNow(game.gameId)}
            >
              Play now
            </MainButton>
          </GameSmallBox>
          <GameBigBox className="big-box">
            <GameContentWrapper>
              <GameContentHeadingWrapper>
                <GameContentHeading>
                  <div className="status">
                    {' '}
                    <span>o</span>Playing now
                  </div>
                  <div className="players">
                    <img src={PlayerIcon} alt="" />{' '}
                    {formatNumber(game.nofPlayers)}
                  </div>
                </GameContentHeading>
                <GameName>{game.name}</GameName>
              </GameContentHeadingWrapper>
              <GameContentRevenu>
                <div className="title">TOTAL REVENU</div>
                <div className="total-revenu">
                  ${Number(game.totalRevenu).toLocaleString()}
                </div>
              </GameContentRevenu>
              <GameContentDescription>
                {game.description}
              </GameContentDescription>
            </GameContentWrapper>
            <GameThumbnailWrapper>
              <GameThumbnailImg
                className="thumbnail"
                src={game.thumbnailImg}
                alt=""
              />
              <GameTags>
                {game.tags.map((tag, tagIndex) => (
                  <div key={tagIndex}>{tag}</div>
                ))}
              </GameTags>
            </GameThumbnailWrapper>
          </GameBigBox>
        </GameWrapper>
      ))}

      <CarouselContainer className="game-list">
        {gamesList.map((game, index) => (
          <CarouselCard key={index}>
            <GameWrapper className="mobile">
              <GameLogoContainer>
                <GameLogo className="mobile" src={game.logoImg} alt="" />
              </GameLogoContainer>
              <GameContentWrapper className="mobile">
                <GameContentHeadingWrapper>
                  <GameName className="mobile">{game.name}</GameName>
                </GameContentHeadingWrapper>
                <GameContentRevenu>
                  <div className="title mobile">TOTAL REVENU</div>
                  <div className="total-revenu mobile">
                    ${game.totalRevenu.toLocaleString()}
                  </div>
                </GameContentRevenu>
                <GameContentDescription className="mobile">
                  {game.description}
                </GameContentDescription>
              </GameContentWrapper>
              <MainButton
                className="mobile"
                height="54px"
                onClick={() => handlePlayNow(game.gameId)}
              >
                Play now
              </MainButton>
            </GameWrapper>
          </CarouselCard>
        ))}
      </CarouselContainer>
    </Wrapper>
  );
};
