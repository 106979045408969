import { createSlice } from '@reduxjs/toolkit';
import { PlayerStatus } from 'constants/enum/PlayerStatus';

const initialState: {
  playerInfo: {
    emailAddress: string;
    username?: string;
    walletAddress?: string;
    avatarURL?: string;
    status: PlayerStatus;
    roles: string[];
  };
  isLoggedIn: boolean;
  refreshAvatar: boolean;
} = {
  playerInfo: null,
  isLoggedIn: false,
  refreshAvatar: false,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      state.isLoggedIn = true;
      state.playerInfo = payload;
    },
    logoutUser: state => {
      state.isLoggedIn = false;
      state.playerInfo = null;
    },
    refreshAvatar: state => {
      state.refreshAvatar = !state.refreshAvatar;
    },
  },
});

export const { loginUser, logoutUser, refreshAvatar } = playerSlice.actions;

export default playerSlice.reducer;
