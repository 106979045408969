import { MaintenanceContainer } from './styled';

export const MaintenancePage = () => {
  return (
    <MaintenanceContainer>
      <div className="heading-section-title">BIGA is under maintenance</div>
      <div className="heading-section-description">
        We are working hard to bring it up online soon
      </div>
    </MaintenanceContainer>
  );
};
