import { AnimationContainer, AnimationItem } from './styled';
import imgage1 from 'assets/main/play-earn/animation/10.png';
import imgage2 from 'assets/main/play-earn/animation/hodl-1.png';
import imgage3 from 'assets/main/play-earn/animation/hodl.png';
import imgage4 from 'assets/main/play-earn/animation/fruit-combinator-high-symbols-05.png';
import imgage5 from 'assets/main/play-earn/animation/group-1171274605.png';
import imgage6 from 'assets/main/play-earn/animation/group-1171274606.png';
import imgage7 from 'assets/main/play-earn/animation/laugh-satire.png';
import imgage8 from 'assets/main/play-earn/animation/mask-group-1.png';
import imgage9 from 'assets/main/play-earn/animation/mask-group.png';
import imgage10 from 'assets/main/play-earn/animation/loading_image.png';
import imgage11 from 'assets/main/vison/animation/elipse.png';

export const PlayEarnAnimation = () => {
  return (
    <AnimationContainer>
      <AnimationItem
        className="pink-bg"
        src={imgage10}
        style={{
          right: '-130px',
          top: '-230px',
          opacity: 1,
          width: '140%',
        }}
      />
      <AnimationItem
        className="animation-bg"
        src={imgage11}
        style={{ top: -390, left: -230, width: '140%' }}
      />
      <AnimationItem src={imgage1} style={{ left: '130px', zIndex: 1 }} />
      <AnimationItem
        className="coin-left"
        src={imgage2}
        style={{ left: '-50px', bottom: '180px' }}
      />
      <AnimationItem
        className="coin-top-right"
        src={imgage3}
        style={{ right: 340, top: -30, zIndex: 1 }}
      />
      <AnimationItem
        className="watermelon"
        src={imgage4}
        style={{ right: 495, bottom: 10, zIndex: 1 }}
      />
      <AnimationItem
        className="coin-top-left"
        src={imgage5}
        style={{ left: 170, top: 110 }}
      />
      <AnimationItem
        className="coin-bot-right"
        src={imgage6}
        style={{ right: 170, bottom: 170, zIndex: 1 }}
      />
      <AnimationItem
        className="face-smile"
        src={imgage7}
        style={{ right: '325px', bottom: '5px', zIndex: 1 }}
      />
      <AnimationItem
        className="baby"
        src={imgage8}
        style={{ left: '20px', bottom: '50px' }}
      />
      <AnimationItem
        className="flying-main"
        src={imgage9}
        style={{ right: '0', top: '-50px' }}
      />
    </AnimationContainer>
  );
};
