const breakpoints = {
  xs: '428px',
  sm: '639px',
  md: '767px',
  md2: '834px',
  lg: '1024px',
  xl: '1280px',
  xl1: '1440px',
  xl2: '1512px',
  xl3: '1750px',
  xl4: '1920px',
} as const;

export const media = {
  xs: `@media (max-width: ${breakpoints.xs})`,
  sm: `@media (max-width: ${breakpoints.sm})`,
  md: `@media (max-width: ${breakpoints.md})`,
  md2: `@media (max-width: ${breakpoints.md2})`,
  lg: `@media (max-width: ${breakpoints.lg})`,
  xl: `@media (max-width: ${breakpoints.xl})`,
  xl1: `@media (max-width: ${breakpoints.xl1})`,
  xl2: `@media (max-width: ${breakpoints.xl2})`,
  xl3: `@media (max-width: ${breakpoints.xl3})`,
  xl4: `@media (max-width: ${breakpoints.xl4})`,
};
