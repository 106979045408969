import { PoweroffOutlined, SolutionOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import bigalogo from 'assets/vectors/logo-navbar-biga.svg';
import { ThemedModal } from 'components/Modals/styled';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { useLogOut } from 'hooks/useLogOut';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';

import {
  DropdownItemWrapper,
  DropdownWrapper,
  Line,
  Logo,
  LogoWrapper,
  MainWrapper,
  MenuItemWrapper,
  MenuLogoAdminPanel,
  MenuLogoAdminStats,
  MenuLogoAffiliate,
  MenuLogoCashier,
  MenuLogoGameManagement,
  MenuLogoGames,
  MenuLogoLeaderboard,
  // SettingWrapper,
  MenuLogoSetting,
  MenuLogoStaking,
  MenuText,
  NavbarWrapper,
  ProfileAvatar,
  ProfileAvatarWrapper,
  ProfileMain,
  ProfileWrapper,
  SupportLogo,
} from './styled';

export const AppMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isEnableCashier = useEnableCashier();

  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);

  return (
    <NavbarWrapper>
      <MainWrapper>
        <MenuItemWrapper
          to={PitRouter.GAME_LIST}
          className={pathname === PitRouter.GAME_LIST ? 'navbar-active' : ''}
        >
          <MenuLogoGames />
        </MenuItemWrapper>

        {playerInfo &&
          playerInfo.roles &&
          !playerInfo.roles.includes(PlayerRoles.Tester) && (
            <>
              <MenuItemWrapper
                to={PitRouter.LEADER_BOARD}
                className={
                  pathname === PitRouter.LEADER_BOARD ? 'navbar-active' : ''
                }
              >
                <MenuLogoLeaderboard />
              </MenuItemWrapper>
            </>
          )}

        {playerInfo?.roles &&
          playerInfo.roles.includes(PlayerRoles.Influencer) && (
            <>
              <MenuItemWrapper
                to={PitRouter.AFFILIATE}
                className={
                  pathname === PitRouter.AFFILIATE ? 'navbar-active' : ''
                }
              >
                <MenuLogoAffiliate />
              </MenuItemWrapper>
            </>
          )}

        {isEnableCashier &&
          playerInfo?.status === PlayerStatus.UploadedProfile &&
          !playerInfo?.roles.includes(PlayerRoles.Tester) && (
            <>
              <MenuItemWrapper
                to={PitRouter.CASHIER}
                className={
                  pathname === PitRouter.CASHIER ? 'navbar-active' : ''
                }
              >
                <MenuLogoCashier />
              </MenuItemWrapper>
            </>
          )}

        {playerInfo?.roles &&
          (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
            playerInfo.roles.includes(PlayerRoles.Admin) ||
            playerInfo.roles.includes(PlayerRoles.Provider)) && (
            <>
              <MenuItemWrapper
                to={PitRouter.MANAGEMENT}
                className={
                  pathname === PitRouter.MANAGEMENT ? 'navbar-active' : ''
                }
              >
                <MenuLogoGameManagement />
              </MenuItemWrapper>
            </>
          )}

        {playerInfo?.roles &&
          (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
            playerInfo.roles.includes(PlayerRoles.Admin)) && (
            <MenuItemWrapper
              to={PitRouter.ADMIN_STATS}
              className={
                pathname === PitRouter.ADMIN_STATS ? 'navbar-active' : ''
              }
            >
              <MenuLogoAdminStats />
            </MenuItemWrapper>
          )}
        {playerInfo?.roles &&
          (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
            playerInfo.roles.includes(PlayerRoles.Admin)) && (
            <MenuItemWrapper
              to={PitRouter.ADMIN_PANEL}
              className={
                pathname === PitRouter.ADMIN_PANEL ? 'navbar-active' : ''
              }
            >
              <MenuLogoAdminPanel />
            </MenuItemWrapper>
          )}
        {process.env.REACT_APP_IS_HIDE_STAKING !== 'true' && (
          <MenuItemWrapper
            to={PitRouter.STAKING}
            className={pathname === PitRouter.STAKING ? 'navbar-active' : ''}
          >
            <MenuLogoStaking />
          </MenuItemWrapper>
        )}

        {!playerInfo?.roles.includes(PlayerRoles.Tester) && (
          <>
            <MenuItemWrapper
              to={PitRouter.SUPPORT}
              className={pathname === PitRouter.SUPPORT ? 'navbar-active' : ''}
            >
              <SupportLogo />
            </MenuItemWrapper>
            <Line />
          </>
        )}

        {playerInfo && playerInfo.status === PlayerStatus.UploadedProfile && (
          <MenuItemWrapper
            to={PitRouter.PROFILE}
            className={pathname === PitRouter.PROFILE ? 'navbar-active' : ''}
          >
            <MenuLogoSetting />
          </MenuItemWrapper>
        )}
      </MainWrapper>
    </NavbarWrapper>
  );
};
