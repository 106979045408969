export enum GaCategories {
  GENERAL = 'General',
  SIGNUP = 'Signup category',
}

export enum GaActions {
  ACTION_PUBLIC_ALPHA = 'Public alpha',
  ACTION_SIGNUP = 'Signup',
  ACTION_SIGNUP_FORM = 'Signup form',
  ACTION_SIGNUP_VERIFY_CODE = 'Signup verify code',
  ACTION_SIGNUP_SETUP_PROFILE = 'Signup setup profile',
  ACTION_SIGNUP_COMPLETED = 'Signup completed',
}

export enum GaLabels {
  LABEL_PUBLIC_ALPHA = 'Public alpha label',
  LABEL_SIGNUP = 'Signup label',
  LABEL_SIGNUP_FORM = 'Signup form label',
  LABEL_SIGNUP_VERIFY_CODE = 'Signup verify code label',
  LABEL_SIGNUP_SETUP_PROFILE = 'Signup setup profile label',
  LABEL_SIGNUP_COMPLETED = 'Signup completed label',
}
