import { PitRouter } from 'constants/routers';
import { useNavigate } from 'react-router-dom';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import { SubmitButton, ThemedModal, Wrapper } from '../styled';

export const UnregisteredModal = () => {
  const isOpen = useModalIsOpen(ApplicationModal.UNREGISTERED);
  const registeredToggle = useToggleModal(ApplicationModal.UNREGISTERED);

  const navigate = useNavigate();

  const redirectRegister = (fromPage?) => {
    navigate(PitRouter.REGISTER, { state: { fromPage } });
    registeredToggle();
  };

  return (
    <>
      <ThemedModal
        title={false}
        open={isOpen}
        onCancel={registeredToggle}
        footer={false}
        width={550}
      >
        <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
          You need to register account before register your game!
        </div>
        <Wrapper>
          <SubmitButton onClick={() => redirectRegister(PitRouter.GAME_LIST)}>
            Create new account
          </SubmitButton>
        </Wrapper>
      </ThemedModal>
    </>
  );
};
