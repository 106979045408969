import { useEffect, useState } from 'react';
import { getRevenueByGame } from 'services/api';

export interface IRevenueByGame {
  username: string;
  avatarURL?: string;
  totalOfRevenue: string;
  totalOfSpentAmount: string;
  playsAmount: string;
}

export const useGetRevenueByGame = (params, refresh: boolean) => {
  const [revenue, setRevenue] = useState<IRevenueByGame[]>(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getRevenueByGame(params);
      if (res?.success) {
        setRevenue(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { revenue, loading };
};
