import { useEffect, useState } from 'react';
import { getPlayerGameRevenueList } from 'services/api';

import { IPlayerGameRevenueList } from './useGetPlayerGameRevenueList';

export const useGetPlayerGameRevenueListForScroll = (
  params,
  refresh?: boolean,
) => {
  const [playerGameRevenueList, setPlayerGameRevenueList] = useState<
    IPlayerGameRevenueList[]
  >([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      if (
        !total ||
        params.pageIndex <= page ||
        playerGameRevenueList?.length < total
      ) {
        const res = await getPlayerGameRevenueList(params);
        if (res?.success) {
          if (params.pageIndex > page) {
            setPlayerGameRevenueList([
              ...playerGameRevenueList,
              ...res?.payload?.data?.data,
            ]);
          } else {
            setPlayerGameRevenueList(res?.payload?.data?.data);
          }
          setTotal(res?.payload?.data?.totalCount);
          setPage(params.pageIndex);
        }
      }

      setLoading(false);
    })();
  }, [params, refresh]);
  return {
    playerGameRevenueList,
    loading,
    total,
  };
};
