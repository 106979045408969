import styled from 'styled-components';

export const LandingPageWrapper = styled.div`
  width: 100vw;
  background-color: var(--background-main);
`;

export const LandingPageFixedWrapper = styled.div`
  /* background-color: #093071; */
  position: fixed;
  width: 100%;
`;

export const LandingPageTopScreenWrapper = styled.div`
  /* background-color: #093071; */
  height: 100vh;
`;

export const Logo = styled.img`
  @media (max-width: 1350px) {
    width: 50%;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    width: 25%;
    max-width: 100%;
  }
`;

export const Description = styled.div`
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 1350px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
`;

export const FooterDescription = styled.div`
  color: #fff;
  font-family: 'Changeling Neo';
  font-size: 1.5rem;
  font-weight: 700;

  @media (max-width: 576px) {
    font-size: 0.9rem !important;
  }

  @media (max-width: 576px) {
    font-size: 0.9rem !important;
  }

  @media (max-width: 1280px) {
    font-size: 1rem;
    text-align: center;
  }
`;

export const LandingContentWrapper = styled.div`
  background: #0b3885;
  overflow: hidden;
  position: absolute;
  top: 100vh;
  width: 100%;
  z-index: 1;
`;

export const LandingScrollDownWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LandingScrollDownText = styled.div`
  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const MainBlockTopLandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px;
  position: relative;
  height: 100%;

  @media (max-width: 1280px) {
    align-items: center;
    flex-direction: column;
    gap: 24px;
  }
`;

export const FirstBlockTopLandingPageWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column !important;
    align-items: center;
    gap: 15px !important;
  }

  @media (max-width: 1280px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const FirstBlockLeftTopLandingPageWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 41px;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 1280px) {
    gap: 24px;
  }
`;

export const FirstBlockRightTopLandingPageWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 1280px) {
    gap: 0;
  }
`;

export const SecondBlockTopLandingPageWrapper = styled.div`
  align-items: flex-end;
  bottom: 55px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  position: absolute;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  @media (max-width: 480px) {
    bottom: 15%;
  }
`;

export const ScrollImage = styled.img`
  @media (max-width: 1280px) {
    height: 50px;
  }
`;
