import { Top5Table, Top5TableRow, Top5Title, Top5Wrapper } from './styled';
import GoldIcon from 'assets/main/vison/gold-icon.png';
import SliverIcon from 'assets/main/vison/sliver-icon.png';
import AvataDefault from 'assets/main/vison/avata-default.png';
import { useGetTopEarners } from 'hooks/landingpage/useGetTopEarners';

export const TopEarners = () => {
  const { topEarners, loading } = useGetTopEarners();

  return (
    <Top5Wrapper>
      <Top5Title>TOP 5 EARNERS</Top5Title>
      <Top5Table>
        {topEarners.map((earner, index) => (
          <Top5TableRow key={index}>
            <div className="reward">
              <img src={index <= 2 ? GoldIcon : SliverIcon} alt="" />
              <span>{index + 1}</span>
            </div>
            <img
              className="avatar"
              src={earner.avatarURL || AvataDefault}
              alt=""
            />
            <div className="username">{earner.username}</div>
            <div>
              ${' '}
              {earner.accumulatedRevenue.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }) || 0}
            </div>
          </Top5TableRow>
        ))}
      </Top5Table>
    </Top5Wrapper>
  );
};
