import { useMemo } from 'react';
import { LocalhostStorage } from 'utils/sessionStorage';
import { ContractAddressByChainId } from 'utils/wallets/connector';

const useContractInfo = () => {
  const CHAINID = LocalhostStorage.get('chainId');
  return useMemo(() => {
    if (!CHAINID) return;
    return ContractAddressByChainId[+CHAINID];
  }, [CHAINID]);
};

export default useContractInfo;
