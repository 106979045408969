import { Col, Form, Input, Row, Spin } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { ActionButton } from 'components/Button/styled';
import { ThemedModal } from 'components/Modals/styled';
import { useGetPlatformConfig } from 'hooks/platform-config/useGetPlatformConfig';
import usePlayerCallback from 'hooks/player/usePlayerCallback';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ICheckUsernameExists, setPlatformConfig } from 'services/api';

import { AvatarImg, InputWrapper, WarningStyled } from './styled';

export const PlatformConfig = () => {
  const [admin, setAdmin] = useState<string>();
  const [platformAccount, setPlatformAccount] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);

  const { platformConfig, loading: configLoading } =
    useGetPlatformConfig(refresh);

  const { checkUsernameExists } = usePlayerCallback();

  const [adminExists, setAdminExists] = useState<ICheckUsernameExists>(null);
  const [checkingAdmin, setCheckingAdmin] = useState<boolean>(false);

  const [platformAccountExists, setPlatformAccountExists] =
    useState<ICheckUsernameExists>(null);
  const [checkingPlatformAccount, setCheckingPlatformAccount] =
    useState<boolean>(false);

  useDebounce(
    () => {
      void (async () => {
        setCheckingAdmin(true);
        if (admin && admin !== platformConfig?.superAdminAccount?.username) {
          const result = await checkUsernameExists(admin);
          setAdminExists(result);
        }
        setCheckingAdmin(false);
      })();
    },
    500,
    [admin],
  );
  useDebounce(
    () => {
      void (async () => {
        setCheckingPlatformAccount(true);
        if (
          platformAccount &&
          platformAccount !== platformConfig?.platformAccount?.username
        ) {
          const result = await checkUsernameExists(platformAccount);
          setPlatformAccountExists(result);
        }
        setCheckingPlatformAccount(false);
      })();
    },
    500,
    [platformAccount],
  );

  const onReset = useCallback(() => {
    if (platformConfig) {
      setAdmin(platformConfig.superAdminAccount?.username);
      setPlatformAccount(platformConfig.platformAccount?.username);
    }
  }, [platformConfig]);

  useEffect(() => {
    onReset();
  }, [onReset]);

  const enableButton = useMemo(() => {
    return (
      admin &&
      platformAccount &&
      ((admin !== platformConfig?.superAdminAccount?.username &&
        adminExists?.player?.username === admin) ||
        (platformAccount !== platformConfig?.platformAccount?.username &&
          platformAccountExists?.player?.username === platformAccount))
    );
  }, [
    admin,
    platformAccount,
    platformConfig,
    adminExists,
    platformAccountExists,
  ]);

  const handleSetConfig = async () => {
    setConfirmVisible(false);
    setLoading(true);

    const response = await setPlatformConfig({
      superAdminUsername: admin,
      platformAccountUsername: platformAccount,
    });
    if (response?.success) {
      toast.success('You have successfully set platform config');
      setRefresh(!refresh);
    } else toast.error('Set platform config failed!!');

    setLoading(false);
  };

  return (
    <Spin spinning={configLoading}>
      <Form autoComplete="off" layout="vertical">
        <Row justify={'center'}>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mt-2" name={['admin-address']}>
              <InputWrapper>
                <label>Super Admin</label>
                <Spin spinning={checkingAdmin}>
                  <Input
                    type={'string'}
                    placeholder="Please enter admin username"
                    onChange={e => setAdmin(e.target.value)}
                    disabled={loading}
                    value={admin}
                    prefix={
                      <AvatarImg
                        src={
                          adminExists?.player?.avatarURL ||
                          platformConfig?.superAdminAccount?.avatarURL ||
                          defaultAvatarImg
                        }
                      />
                    }
                  />
                </Spin>
                {admin &&
                  admin !== platformConfig?.superAdminAccount?.username &&
                  !checkingAdmin &&
                  adminExists &&
                  !adminExists?.isExisting && (
                    <WarningStyled>**Username does not exists</WarningStyled>
                  )}
              </InputWrapper>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item className="mt-2" name={['platform-wallet-address']}>
              <InputWrapper>
                <label>Platform account</label>
                <Spin spinning={checkingPlatformAccount}>
                  <Input
                    type={'string'}
                    placeholder="Please enter platform account username"
                    onChange={e => setPlatformAccount(e.target.value)}
                    disabled={loading}
                    value={platformAccount}
                    prefix={
                      <AvatarImg
                        src={
                          platformAccountExists?.player?.avatarURL ||
                          platformConfig?.platformAccount?.avatarURL ||
                          defaultAvatarImg
                        }
                      />
                    }
                  />
                </Spin>
                {platformAccount &&
                  platformAccount !==
                    platformConfig?.platformAccount?.username &&
                  !checkingPlatformAccount &&
                  platformAccountExists &&
                  !platformAccountExists?.isExisting && (
                    <WarningStyled>**Username does not exists</WarningStyled>
                  )}
              </InputWrapper>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'center'} gutter={8}>
          <Col>
            <ActionButton
              onClick={() => enableButton && setConfirmVisible(true)}
              disabled={!enableButton}
              loading={loading}
            >
              Update
            </ActionButton>
          </Col>
          <Col>
            <ActionButton onClick={onReset} type="link">
              Reset
            </ActionButton>
          </Col>
        </Row>
      </Form>
      <ThemedModal
        visible={confirmVisible}
        onOk={handleSetConfig}
        onCancel={() => setConfirmVisible(false)}
        okText={'Update'}
        cancelText="No"
        width={400}
        style={{ marginTop: '50px' }}
      >
        <p>Do you want to update platform configuration?</p>
      </ThemedModal>
    </Spin>
  );
};
