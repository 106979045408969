import defaultTokenImg from 'assets/images/default-token.png';
import { useGetTokenInfo } from 'hooks/erc20/useGetTokenInfo';
import { useState } from 'react';

import {
  FourthBlockBalanceContent,
  FourthBlockBalanceContentTitle,
  FourthBlockBalanceContentValue,
  FourthBlockBalanceContentWrapper,
  Image,
} from './styled';

interface IProps {
  item: any;
}

export const TokenSelected = ({ item }: IProps) => {
  const [refresh, setRefresh] = useState<boolean>(false);

  const { tokenInfo, loading: tokenLoading } = useGetTokenInfo(
    item?.address,
    refresh,
  );
  return (
    <>
      {item ? (
        <FourthBlockBalanceContentWrapper>
          <Image src={item.iconUrl || defaultTokenImg} alt="iconUrl" />
          <FourthBlockBalanceContent>
            <FourthBlockBalanceContentValue>
              {tokenInfo?.balanceOf &&
              tokenInfo?.tokenAddress === item?.address ? (
                <FourthBlockBalanceContentValue>
                  {Number(tokenInfo?.balanceOf)
                    .toFixed(2)
                    .replace(/\.?0+$/, '') || 0}{' '}
                  {item.symbol}
                </FourthBlockBalanceContentValue>
              ) : (
                <FourthBlockBalanceContentValue>
                  ... {item.symbol}
                </FourthBlockBalanceContentValue>
              )}
            </FourthBlockBalanceContentValue>
            <FourthBlockBalanceContentTitle>
              Available balance
            </FourthBlockBalanceContentTitle>
          </FourthBlockBalanceContent>
        </FourthBlockBalanceContentWrapper>
      ) : (
        <FourthBlockBalanceContentValue>
          No token selected
        </FourthBlockBalanceContentValue>
      )}
    </>
  );
};
