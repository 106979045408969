import { StakeDTO } from 'hooks/staking/useGetStakeInfo';
import {
  CustomedDivider,
  StakingFooterItem,
  StakingFooterLabel,
  StakingFooterNumber,
  StakingFooterWrapper,
} from 'pages/Staking/styled';

type IProps = {
  stakeData: StakeDTO;
};

export const StakingFooter = ({ stakeData }: IProps) => {
  return (
    <StakingFooterWrapper>
      <StakingFooterItem>
        <StakingFooterLabel>Stakers</StakingFooterLabel>
        <StakingFooterNumber>
          {stakeData && stakeData.stakers ? stakeData.stakers : 0}
        </StakingFooterNumber>
      </StakingFooterItem>
      <CustomedDivider type="vertical" />
      <StakingFooterItem>
        <StakingFooterLabel>Stake</StakingFooterLabel>
        <StakingFooterNumber>
          {stakeData && stakeData.stake
            ? Math.round(
                stakeData.stake / 10 ** stakeData.supportedToken.decimals,
              )
            : 0}
        </StakingFooterNumber>
      </StakingFooterItem>
      <CustomedDivider type="vertical" />
      <StakingFooterItem>
        <StakingFooterLabel>Yield</StakingFooterLabel>
        <StakingFooterNumber>
          {stakeData && stakeData.yield
            ? Math.round(
                stakeData.yield / 10 ** stakeData.supportedToken.decimals,
              )
            : 0}
        </StakingFooterNumber>
      </StakingFooterItem>
    </StakingFooterWrapper>
  );
};
