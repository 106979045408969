import styled from 'styled-components';
import { Button } from 'antd';
import { media } from 'constants/enum/breakpoints';

export const DisconnectMetamaskModalContainer = styled.div`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  margin-bottom: 20px;
  word-wrap: break-word;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  padding: 30px 0 60px;

  ${media.sm} {
    font-size: 20px;
    padding: 30px 0 50px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

export const YesButton = styled(Button)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  height: auto;
  outline: 0;
  border: none;
  width: 155px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }

  ${media.sm} {
    width: 135px;
  }
`;

export const NoButton = styled(Button)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: #070709;
  box-shadow: 0px 8px 8px -4px rgba(255, 255, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  height: auto;
  outline: 0;
  border: none;
  width: 122px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }

  ${media.sm} {
    width: 102px;
  }
`;
