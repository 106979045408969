import { useEffect, useState } from 'react';
import { useStakeCallback } from './useStakeCallback';

export const useGetTermDeadline = (
  startTime: string,
  term: number,
  refresh?: boolean,
) => {
  const [deadline, setDeadline] = useState();
  const { getTermDeadline } = useStakeCallback();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTermDeadline(startTime, term);
      setDeadline(res);
      setLoading(false);
    })();
  }, [getTermDeadline, startTime, term, refresh]);
  return { deadline, loading };
};
