// eslint-disable-next-line simple-import-sort/imports
import OneSignal from 'react-onesignal';
import { toast } from 'react-toastify';
import {
  createDesktopSubscription,
  updateNotificationSetting,
} from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';
import useEnableNotification from './notification/useEnableNotification';

function useOneSignal() {
  const isEnable = useEnableNotification();

  const initOneSignal = async (appOneSignalId = '') => {
    if (!isEnable) return;
    if ('serviceWorker' in navigator) {
      // Register a service worker hosted at the root of the
      // site using the default scope.
      navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(
        registration => {
          console.log('Service worker registration succeeded:', registration);
        },
        error => {
          console.error(`Service worker registration failed: ${error}`);
        },
      );
    } else {
      console.error('Service workers are not supported.');
    }

    await OneSignal.init({
      appId: appOneSignalId || process.env.REACT_APP_ONE_SIGNAL_ID,
      allowLocalhostAsSecureOrigin: true,
    });
    await OneSignal.Slidedown.promptPush();
    return;
  };

  const getSubscriptionID = () => {
    if (!isEnable) return '';
    const Subscription_ID = OneSignal.User.PushSubscription.id;
    return Subscription_ID;
  };

  const checkInitOneSignal = async () => {
    if (!isEnable) return;
    const isLoggedIn = Boolean(LocalhostStorage.get('accessToken'));
    if (isLoggedIn) {
      const Subscription_ID = OneSignal.User.PushSubscription.id;
      if (Subscription_ID && Subscription_ID !== '') return '';
      await initOneSignal();
    }
    return;
  };

  const handleListenChangeSubscriber = () => {
    if (!isEnable) return;
    OneSignal.User.PushSubscription.addEventListener('change', async e => {
      if (e.current.id !== '') {
        const resDesktopSubscription = await createDesktopSubscription({
          subscriptionId: e.current.id,
        });
        if (resDesktopSubscription?.success) {
          const resUpdateNotificationSetting = await updateNotificationSetting([
            {
              type: 'learderboard_win_loss_via_web_push',
              enabled: true,
            },
          ]);
          if (!resUpdateNotificationSetting?.success) {
            toast.error(
              resDesktopSubscription?.message ||
                'Not update notification setting. Please try again.',
            );
          }
        } else {
          toast.error(
            resDesktopSubscription?.message ||
              'Not register notification. Please try again.',
          );
        }
      }
    });
  };

  return {
    initOneSignal,
    getSubscriptionID,
    checkInitOneSignal,
    handleListenChangeSubscriber,
  };
}

export default useOneSignal;
