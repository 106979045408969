import {
  LogoutModalContainer,
  Title,
  ButtonWrapper,
  NoButton,
  LogoutButton,
  GradientLine,
  Wrapper,
  StyledBr,
} from './styled';
import { UpdateInformationModal } from '../UpdateInformationModal';

interface IProps {
  isOpen: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}
export const LogoutModal = ({ isOpen, onOk, onCancel }: IProps) => {
  return (
    <Wrapper>
      <LogoutModalContainer>
        <UpdateInformationModal
          className="logout-modal"
          isOpen={isOpen}
          onCancel={onCancel}
        >
          <Title>
            Are you sure you want <StyledBr /> to sign out?
          </Title>
          <ButtonWrapper>
            <NoButton onClick={onCancel}>No</NoButton>
            <LogoutButton onClick={onOk}>Logout</LogoutButton>
          </ButtonWrapper>
          <GradientLine />
        </UpdateInformationModal>
      </LogoutModalContainer>
    </Wrapper>
  );
};
