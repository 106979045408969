import { SearchOutlined } from '@ant-design/icons';
import { IntegrateGameButton } from 'components/Button/IntegrateGameButton';
import { ConfigLevelFeeModal } from 'components/Modals/ConfigLevelFee';
import { GameDetails } from 'components/Modals/GameDetails';
import { IntegrateGameModal } from 'components/Modals/IntegrateGame';
import { UnregisteredModal } from 'components/Modals/Unregistered';
import { UpdateGameModal } from 'components/Modals/UpdateGame';
import { AllGamesTable } from 'components/Table/AllGamesTable';
import { TitleStyled } from 'pages/Register/styled';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import HomepageTemplate from 'template/Homepage';

import {
  ButtonTabsWrapper,
  ButtonText,
  ButtonWrapper,
  GameListContainer,
  GeneralButtonWrapper,
  InputSearchWrapper,
} from './styled';

export const GameList = () => {
  const integrateToggle = useToggleModal(ApplicationModal.REGISTER_GAME);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');

  const openIntegrateModal = () => {
    integrateToggle();
  };

  const { gameId } = useSelector((state: RootState) => state.detail);
  const [activeButton, setActiveButton] = useState('update');
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName);
  };

  return (
    <HomepageTemplate>
      <GameListContainer>
        <TitleStyled>Game List</TitleStyled>
        <GeneralButtonWrapper>
          <ButtonTabsWrapper>
            <ButtonWrapper
              active={activeButton === 'update'}
              onClick={() => handleButtonClick('update')}
            >
              <ButtonText>Manage</ButtonText>
            </ButtonWrapper>
            <ButtonWrapper
              active={activeButton === 'customize'}
              onClick={() => handleButtonClick('customize')}
            >
              <ButtonText>Customize</ButtonText>
            </ButtonWrapper>
          </ButtonTabsWrapper>

          <IntegrateGameButton onClick={openIntegrateModal}>
            Register Your Game
          </IntegrateGameButton>

          <InputSearchWrapper
            placeholder="Search game"
            allowClear
            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </GeneralButtonWrapper>
        <AllGamesTable
          refresh={refresh}
          onRefresh={() => setRefresh(!refresh)}
          activeButton={activeButton}
          searchQuery={searchQuery}
        />
      </GameListContainer>
      <IntegrateGameModal onRefresh={() => setRefresh(!refresh)} />
      <UnregisteredModal />
      <GameDetails gameId={gameId} refresh={refresh} />
      <ConfigLevelFeeModal gameId={gameId} refresh={refresh} />
      <UpdateGameModal gameId={gameId} onRefresh={() => setRefresh(!refresh)} />
    </HomepageTemplate>
  );
};
