import { useEffect, useState } from 'react';
import { getAffiliate } from 'services/api';

export interface IAffiliateInfo {
  affiliateCode: string;
  signUps: number;
  uniquePlayers: number;
  totalAttempts: number;
  totalEarnedAmount: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    amount: string;
  };
}

export const useGetAffiliate = (params?: any, refresh?: boolean) => {
  const [affiliateInfo, setAffiliateInfo] = useState<IAffiliateInfo>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getAffiliate();
      if (res?.success) {
        setAffiliateInfo(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { affiliateInfo, loading };
};
