import { Button } from 'antd';
import styled from 'styled-components';
import { UpdateInformationModal } from '../UserProfile/UpdateInformationModal';
import { media } from 'constants/enum/breakpoints';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

export const ModalWrapper = styled(UpdateInformationModal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const ModalWithdrawWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalWithdrawContainer = styled.div`
  /* background-color: #fff; */
`;

export const ModalWithdrawContent = styled.div`
  padding: 30px 0 20px 0;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  ${media.sm} {
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    align-items: center;
  }
`;

export const SubmitButton = styled(Button)<{ height?: string; width?: string }>`
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  height: 46px;
  background-color: #0a3885;
  margin-top: 20px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 12px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }

  ${media.sm} {
    margin-top: 0;
    width: 250px;
  }
`;

export const LinkButton = styled(Button)<{ height?: string; width?: string }>`
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  height: 46px;
  /* width: ${props => (props.width ? props.width : '250px')}; */
  background: #070709;
  margin-top: 20px;
  box-shadow: 0px 8px 8px -4px rgba(255, 255, 255, 0.12);
  border-radius: 12px;

  :hover,
  :focus,
  :active {
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d1d1;
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    opacity: 0.6;
  }

  ${media.sm} {
    width: 250px;
    margin-top: 0;
  }
`;
