import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/store';
import { LocalhostStorage } from 'utils/sessionStorage';

import {
  AccessControlButton,
  AccessControlContainer,
  AccessControlForm,
  AccessControlInput,
  AccessControlLabel,
  AccessControlStyled,
  AccessControlWrapper,
  SplashVideoFullScreen,
} from './styled';

interface AccessControlProps {
  onAccessGranted: (accessKey: string) => void;
}

const AccessControl: React.FC<AccessControlProps> = ({ onAccessGranted }) => {
  const [accessKey, setAccessKey] = useState('');
  const [showVideo, setShowVideo] = useState(false);

  const handleAccessKeyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAccessKey(event.target.value);
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  let bot: boolean | undefined;
  let telegramId: number | undefined;
  let chatId: number | undefined;
  const botParam: string = searchParams.get('bot');
  const telegramIdParam: string = searchParams.get('telegramId');
  const chatIdParam: string = searchParams.get('chatId');
  if (botParam) {
    bot = botParam === 'true';
  }
  if (telegramIdParam) {
    telegramId = parseInt(telegramIdParam);
  }
  if (chatIdParam) {
    chatId = parseInt(chatIdParam);
  }

  const handleNavigaRegister = () => {
    if (bot) {
      navigate(
        pathname + `?bot=true&telegramId=${telegramId}&chatId=${chatId}`,
      );
    } else {
      navigate(pathname);
    }
  };

  const isOnMobileDevice = () => {
    return /Android|Mobi|webOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
    );
  };

  const isLoggedIn = Boolean(LocalhostStorage.get('accessToken'));
  const player = useAppSelector(state => state.player);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (accessKey === process.env.REACT_APP_PASSWORD) {
      if (!isLoggedIn && !player?.isLoggedIn) {
        if (isOnMobileDevice()) {
          localStorage.setItem('accessKey', accessKey);
          onAccessGranted(accessKey);
        } else {
          setShowVideo(true);
        }
        handleNavigaRegister();
      } else {
        setShowVideo(false);
        localStorage.setItem('accessKey', accessKey);
        onAccessGranted(accessKey);
      }
    } else {
      toast.error('Invalid password!');
    }
  };

  return (
    <AccessControlWrapper>
      <AccessControlStyled>
        <AccessControlContainer>
          <AccessControlForm>
            <AccessControlLabel>Please enter the password:</AccessControlLabel>
            <AccessControlInput
              type="password"
              value={accessKey}
              onChange={handleAccessKeyChange}
            />
            <AccessControlButton onClick={handleFormSubmit}>
              Submit
            </AccessControlButton>
          </AccessControlForm>
        </AccessControlContainer>

        {showVideo && (
          <SplashVideoFullScreen
            onEnded={() => {
              setShowVideo(false);
              localStorage.setItem('accessKey', accessKey);
              onAccessGranted(accessKey);
            }}
            className="splash-video-full-screen"
          />
        )}
      </AccessControlStyled>
    </AccessControlWrapper>
  );
};

export default AccessControl;
