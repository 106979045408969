import { ReactNode } from 'react';

import { IntegrateGameStyled } from './styled';

export const IntegrateGameButton = ({
  children,
  fontSize,
  onClick,
}: {
  children: ReactNode;
  fontSize?: string;
  onClick: () => void;
}) => {
  return (
    <IntegrateGameStyled onClick={onClick} $fontSize={fontSize}>
      {children}
    </IntegrateGameStyled>
  );
};
