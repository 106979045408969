import { Col, Row, Spin } from 'antd';
import usePlayerCallback from 'hooks/player/usePlayerCallback';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { addAdmin, ICheckUsernameExists } from 'services/api';

import {
  AddThemedModal,
  SubmitButton,
  TextInputField,
  ThemedModal,
  Wrapper,
} from '../styled';
import { AddText, WarningStyled } from './styled';

interface IAddAdminProps {
  onRefresh: () => void;
}

export const AddAdminModal = (props: IAddAdminProps) => {
  const isOpen = useModalIsOpen(ApplicationModal.ADD_ADMIN);
  const modalToggle = useToggleModal(ApplicationModal.ADD_ADMIN);

  const [username, setUsername] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);

  const handleUsernameChange = e => {
    setUsername(e);
  };
  const { checkUsernameExists } = usePlayerCallback();

  const [checkingUsername, setCheckingUsername] = useState<boolean>(false);

  const [usernameExists, setUsernameExists] =
    useState<ICheckUsernameExists>(null);

  useDebounce(
    () => {
      void (async () => {
        setCheckingUsername(true);
        if (username) {
          const result = await checkUsernameExists(username);
          setUsernameExists(result);
        }
        setCheckingUsername(false);
      })();
    },
    500,
    [username],
  );

  const handleAddAdmin = async () => {
    setConfirmVisible(false);
    setLoading(true);

    const response = await addAdmin({
      username,
    });
    if (response?.success) {
      toast.success('You have successfully added admin');
      setUsername(undefined);
      props.onRefresh();
    } else toast.error('Add admin failed!!');

    modalToggle();

    setLoading(false);
  };

  const isInputValid = useMemo(() => {
    return (
      username &&
      !checkingUsername &&
      usernameExists &&
      usernameExists?.isExisting
    );
  }, [username, usernameExists, checkingUsername]);

  return (
    <>
      <ThemedModal
        title={'Add admin'}
        open={isOpen}
        onCancel={modalToggle}
        footer={false}
        width={550}
      >
        <Row>
          <Col sm={24}>
            <Spin spinning={checkingUsername}>
              <TextInputField
                type="string"
                value={username}
                onChange={e => handleUsernameChange(e.target.value)}
                placeholder="Enter username"
                allowClear
              />
            </Spin>
            {username &&
              !checkingUsername &&
              usernameExists &&
              !usernameExists?.isExisting && (
                <WarningStyled>**Username does not exists</WarningStyled>
              )}
          </Col>
        </Row>
        <Wrapper>
          <SubmitButton
            height="40px"
            width="150px"
            onClick={() => isInputValid && setConfirmVisible(true)}
            loading={loading}
            disabled={!isInputValid}
          >
            Add
          </SubmitButton>
        </Wrapper>
      </ThemedModal>
      <AddThemedModal
        visible={confirmVisible}
        onOk={handleAddAdmin}
        onCancel={() => setConfirmVisible(false)}
        okText={'Add'}
        cancelText="No"
        width={400}
        style={{ marginTop: '50px' }}
      >
        <AddText>
          Do you want to add <b>{username}</b> into Admin list?
        </AddText>
      </AddThemedModal>
    </>
  );
};
