import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import getNodeUrl from 'utils/wallets/getRpcUrl';

export default function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any,
): T | null {
  const { library, account } = useWeb3React<Web3Provider>();

  return useMemo(() => {
    if (!address || !ABI) {
      return null;
    }

    try {
      return new Contract(
        address,
        ABI,
        account
          ? library?.getSigner(account)
          : library || new StaticJsonRpcProvider(getNodeUrl()),
      );
    } catch (error) {
      console.error('useContract', 'Failed To Get Contract', error);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, ABI, library, account]) as T;
}
