import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { useAppSelector } from 'redux/store';

export const usePublicPermission = () => {
  const { isLoggedIn, playerInfo } = useAppSelector(state => state.player);

  return !isLoggedIn ||
    playerInfo === null ||
    (playerInfo && playerInfo?.status !== PlayerStatus.UploadedProfile)
    ? true
    : false;
};
