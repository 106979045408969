import { Input, Modal, Select } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/vectors/logo-cashier-search.svg';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const TextName = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

export const TextValue = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
`;

export const TextDecription = styled(Text)`
  color: #000;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 500;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: #15151b;
    border-radius: 24px;
    .ant-modal-body {
      padding: 0;
    }

    .ant-list {
      width: 100%;
    }
  }

  .ant-modal-close-icon {
    svg {
      fill: #fff;
    }
  }
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalSelectTokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ModalSelectTokenContainer = styled.div`
  display: flex;
  width: 528px;
  padding: 32px 20px 29px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24.5px;

  ${media.sm} {
    width: 100%;
  }
`;

export const ModalSelectTokenFirstBlock = styled.div`
  width: 448px;

  color: #fff;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  line-height: normal;
`;

export const ModalSelectTokenSecondBlock = styled(Input)`
  color: #000;
  background-color: rgba(10, 56, 133, 0.04);
  /* width: 75%; */
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 40px;
  width: 100%;
  border-radius: 50px;

  border: 1px solid rgba(0, 0, 0, 0.08);
  background: var(--light-gray-6, #f2f2f7);

  .ant-input-affix-wrapper,
  .ant-input {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;

    color: #000;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 4px 8px;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #000;
      opacity: 0.24;
    }
    &:-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: #000;
      opacity: 0.24;
    }
  }

  .ant-input-group-addon {
    background-color: transparent;
    border: none;
    opacity: 0.4;
    padding: 0;
  }

  ${media.sm} {
    padding: 16px 20px;
  }
`;

export const ModalSelectTokenThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThirdBlockItem = styled.div`
  width: 448px;
  display: flex;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  &:hover {
    background: #f2f2f7;
  }
`;

export const ThirdBlockItemLeft = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

export const ThirdBlockItemLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const ThirdBlockItemRight = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const SearchOutlinedStyled = styled(SearchIcon)`
  cursor: pointer;
  user-select: none;
`;

export const NetworkImage = styled.img`
  width: 1rem;
  height: 1rem;
  margin-inline: 5px;
`;

export const SelectNetwork = styled(Select)`
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 6.5px;
  color: #000;
  font-size: 17px;
  font-weight: 600;
  height: 38px;
  width: 180px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-weight: 600;
      font-size: 17px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 6.5px;
    border: none;
    background-color: rgba(243, 244, 246, 1);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
    border-radius: 6.5px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
`;
