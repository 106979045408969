import { useEffect, useState } from 'react';
import { getTotalMetricsPlatformRevenue, ITimeRange } from 'services/api';

export interface ITotalMetricsPlatformRevenue {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  totalPlatformFee: string;
  totalAffiliateFee: string;
  totalLevelOwnerFee: string;
  totalProviderFee: string;
  totalTreasuryFee: string;
  tokenPrice: number;
}

export const useGetMetricsPlatformRevenue = (params: ITimeRange) => {
  const [totalMetricsPlatformRevenue, setTotalMetricsPlatformRevenue] =
    useState<ITotalMetricsPlatformRevenue>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTotalMetricsPlatformRevenue(params);
      if (res?.success) {
        setTotalMetricsPlatformRevenue(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return { totalMetricsPlatformRevenue, loading };
};
