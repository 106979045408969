import { useEffect, useState } from 'react';
import { getPlatformConfig } from 'services/api';

export interface IPlatformConfig {
  superAdminAccount: {
    username: string;
    avatarURL?: string;
  };
  platformAccount: {
    username: string;
    avatarURL?: string;
  };
}

export const useGetPlatformConfig = (refresh?: boolean) => {
  const [platformConfig, setPlatformConfig] = useState<IPlatformConfig>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getPlatformConfig();
      if (res?.success) {
        setPlatformConfig(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [refresh]);
  return { platformConfig, loading };
};
