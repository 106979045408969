import { Tooltip } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import useDashboardCallback from 'hooks/dashboard/useDashboardCallback';
import { IGameActive } from 'hooks/games/useGetGameActive';
import { useGetLowestFee } from 'hooks/level-fee/useGetLowestFee';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { useAppSelector } from 'redux/store';
import { filterValue } from 'utils/filterValue';

import {
  DrawerButton,
  DrawerButtonPlay,
  DrawerButtonPlayWrapper,
  DrawerContentBodyWrapper,
  DrawerContentHeaderWrapper,
  DrawerContentWrapper,
  DrawerDecriptionGameWrapper,
  DrawerGeneralWrapper,
  DrawerLabelGame,
  DrawerLabelGameGeneralWrapper,
  DrawerLabelGameWrapper,
  DrawerNameGameWrapper,
  DrawerSearchBarWrapper,
  DrawerSearchWrapper,
  DrawerTitleGameWrapper,
  DrawerTotalDetailWrapper,
  DrawerTotalIncomeTitle,
  DrawerTotalIncomeValue,
  DrawerTotalIncomeWrapper,
  DrawerTotalTitleWrapper,
  DrawerTotalValueWrapper,
  DrawerTotalWrapper,
  DrawerWrapper,
  GamePlayAndFeeWrapper,
  CreditsLogo,
  SearchOutlinedStyled,
  SelectStyled,
  DrawerSelectedLabelGameWrapper, // Import RedTag styled component
  DrawerBlur,
} from './styled';

export const GameDetailDrawerMobile = ({
  gameDetail,
  selectedTags, // Add selectedTags prop
}: {
  gameDetail?: IGameActive;
  selectedTags?: string[]; // Add selectedTags type
}) => {
  const open = useModalIsOpen(ApplicationModal.GAME_DETAIL_DRAWER);
  const toggleDrawer = useToggleModal(ApplicationModal.GAME_DETAIL_DRAWER);

  const navigate = useNavigate();

  const { lowestFee } = useGetLowestFee(gameDetail?.gameId);

  const { getTotalPlayersAndAttempts, getTotalValue, getTotalIncome } =
    useDashboardCallback(gameDetail?.gameId);

  const [term, setTerm] = useState<number>(Number(filterValue.alTime));
  const [username, setUsername] = useState<string>();
  const [totalPlayers, setTotalPlayers] = useState<number>();
  const [totalAttempts, setTotalAttempts] = useState<number>();
  const [totalValue, setTotalValue] = useState<string>();
  const [totalIncome, setTotalIncome] = useState<string>();
  const [totalSpentAmount, setTotalSpentAmount] = useState<string>();

  const [debouncedUsername, setDebouncedUsername] = useState<string>('');
  useDebounce(
    () => {
      void (async () => {
        setDebouncedUsername(username);
      })();
    },
    500,
    [username],
  );

  useEffect(() => {
    void (async () => {
      const result = await getTotalPlayersAndAttempts({
        term,
        username: debouncedUsername,
      });
      setTotalPlayers((await result)?.totalPlayers);
      setTotalAttempts((await result)?.totalAttempts);
    })();
  }, [getTotalPlayersAndAttempts, term, debouncedUsername]);

  useEffect(() => {
    void (async () => {
      const result = await getTotalValue({
        term,
        username: debouncedUsername,
      });
      setTotalValue((await result)?.totalRevenue);
      setTotalSpentAmount((await result)?.totalOfSpentAmount);
    })();
  }, [getTotalValue, term, debouncedUsername]);

  useEffect(() => {
    void (async () => {
      const result = await getTotalIncome();
      setTotalIncome((await result)?.totalIncome);
    })();
  }, [getTotalIncome]);

  const handleUsernameChange = (value: string) => {
    setUsername(value);
  };

  const handleTermChange = (value: number) => {
    setTerm(value);
  };

  const { isLoggedIn } = useAppSelector(state => state.player);

  return (
    <DrawerWrapper
      placement="bottom"
      visible={open}
      closable={false}
      onClose={() => {
        toggleDrawer();
      }}
      height={'100vh'}
    >
      <DrawerBlur onClick={toggleDrawer} />
      <DrawerGeneralWrapper>
        <DrawerContentWrapper>
          <DrawerContentHeaderWrapper>
            <DrawerTitleGameWrapper>
              <DrawerNameGameWrapper>{gameDetail?.name}</DrawerNameGameWrapper>

              <DrawerLabelGameGeneralWrapper>
                {selectedTags && selectedTags.length === 0 && (
                  <DrawerSelectedLabelGameWrapper key="all">
                    <DrawerLabelGame>All</DrawerLabelGame>
                  </DrawerSelectedLabelGameWrapper>
                )}
                {gameDetail?.tags.map((tag, index) =>
                  selectedTags.includes(tag) ? (
                    <DrawerSelectedLabelGameWrapper key={index}>
                      <DrawerLabelGame>{tag}</DrawerLabelGame>
                    </DrawerSelectedLabelGameWrapper>
                  ) : (
                    <DrawerLabelGameWrapper key={index}>
                      <DrawerLabelGame>{tag}</DrawerLabelGame>
                    </DrawerLabelGameWrapper>
                  ),
                )}
              </DrawerLabelGameGeneralWrapper>
            </DrawerTitleGameWrapper>
            <DrawerDecriptionGameWrapper>
              {gameDetail?.description &&
                JSON.parse(gameDetail?.description)
                  ?.split('\n')
                  .map((value, key) => <div key={key}>{value}</div>)}
            </DrawerDecriptionGameWrapper>
          </DrawerContentHeaderWrapper>

          <DrawerContentBodyWrapper>
            <DrawerTotalIncomeWrapper>
              <DrawerTotalIncomeTitle>Total revenue</DrawerTotalIncomeTitle>
              <DrawerTotalIncomeValue>
                {parseFloat(totalValue)
                  .toLocaleString('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                  .replace(/\./g, ' ') || 0}
              </DrawerTotalIncomeValue>
            </DrawerTotalIncomeWrapper>

            <DrawerTotalDetailWrapper>
              <DrawerTotalWrapper>
                <DrawerTotalTitleWrapper>
                  Total unique players
                </DrawerTotalTitleWrapper>
                <DrawerTotalValueWrapper>
                  {totalPlayers
                    ?.toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\./g, ' ') ?? 0}
                </DrawerTotalValueWrapper>
              </DrawerTotalWrapper>
              <DrawerTotalWrapper>
                <DrawerTotalTitleWrapper>
                  Total attempts
                </DrawerTotalTitleWrapper>
                <DrawerTotalValueWrapper>
                  {totalAttempts
                    ?.toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\./g, ' ') ?? 0}
                </DrawerTotalValueWrapper>
              </DrawerTotalWrapper>
              <DrawerTotalWrapper>
                <DrawerTotalTitleWrapper>
                  Total BIGA credits
                </DrawerTotalTitleWrapper>
                <DrawerTotalValueWrapper>
                  <CreditsLogo />
                  {parseFloat(
                    formatUnits(
                      totalSpentAmount || 0,
                      gameDetail?.tokenInfo?.decimals || 18,
                    ),
                  )
                    .toLocaleString('de-DE', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                    .replace(/\./g, ' ')}{' '}
                </DrawerTotalValueWrapper>
              </DrawerTotalWrapper>
            </DrawerTotalDetailWrapper>
          </DrawerContentBodyWrapper>
        </DrawerContentWrapper>

        <GamePlayAndFeeWrapper>
          <DrawerButtonPlayWrapper>
            <DrawerButtonPlay
              onClick={() => {
                if (isLoggedIn) {
                  toggleDrawer();
                  navigate(`/${gameDetail?.gameId}`);
                } else {
                  toast.error('Please log in to play the game!');
                }
              }}
            >
              <DrawerButton>Play</DrawerButton>
            </DrawerButtonPlay>
          </DrawerButtonPlayWrapper>
          <DrawerTotalTitleWrapper>
            Starting From{' '}
            {formatUnits(lowestFee || 0, gameDetail?.tokenInfo?.decimals || 18)}{' '}
            BIGA Credits
          </DrawerTotalTitleWrapper>
        </GamePlayAndFeeWrapper>
      </DrawerGeneralWrapper>
    </DrawerWrapper>
  );
};
