import { Button, Modal, Upload } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    box-shadow: none;
    border-radius: 24px;
    overflow: hidden;
    background: linear-gradient(#0f0f13, #161923);
    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-close-x {
    padding-top: 10px;
    svg {
      fill: #fff;
    }
  }
`;

export const BackgroundGradient = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  inset: 0;
  width: 100%;
  height: 100%;
`;

export const ModalEditAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 448px; */
  gap: 20px;
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalEditWrapper = styled.div`
  display: flex;
  /* width: 448px; */
  padding-top: 55px;
  flex-direction: column;
  gap: 55px;
`;

export const ModalCropAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 55;
`;

export const CropWrapper = styled.div`
  width: 211px;
  height: 211px;
  border: 1px dashed #fff;
  background: #fff;
`;

export const UploadProfileDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    background: #fff;
    border: none;
  }

  &.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
`;

export const CropText = styled(Text)`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
`;

export const ModalAvatarButtonWrapper = styled.div`
  display: flex;
  padding: 24px 32px;
  justify-content: space-between;
  gap: 10px;
`;

export const ClearButtonWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.16);
  flex: 1;
  text-align: center;
`;

export const ClearButton = styled(Button)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: #070709;
  box-shadow: 0px 8px 8px -4px rgba(255, 255, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  height: auto;
  outline: 0;
  border: none;
  width: 155px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }

  ${media.sm} {
    font-weight: 400;
    padding: 12px 10px;
    width: 130px;
  }
`;

export const SaveImageButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SaveImageButton = styled(Button)`
  cursor: pointer;
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  height: auto;
  outline: 0;
  border: none;
  width: 155px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: #15151b;
    opacity: 0.4;
    outline: 0;
  }

  ${media.sm} {
    font-weight: 400;
    padding: 12px 10px;
    width: 130px;
  }
`;

export const ModalEditContainer = styled.div``;
