import { useEffect, useState } from 'react';
import { getStakeInfo } from 'services/api';

export interface StakeDTO {
  stakers: number;
  stake: number;
  yield: number;
  supportedToken: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    tokenPrice: string;
  };
}

export const useGetStakeInfo = (refresh?: boolean) => {
  const [stakeData, setStakeData] = useState<StakeDTO>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getStakeInfo();
      if (res?.success) {
        setStakeData(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [refresh]);
  return { stakeData, loading };
};
