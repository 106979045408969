import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

interface ButtonStyledProps {
  height?: string;
  width?: string;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  width: ${({ width }) => width || '236px'};
  height: ${({ height }) => height || '69px'};
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.13);
  color: rgba(255, 255, 255, 0.66);
  font-size: 22px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.02);
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }

  ${media.md2} {
    font-size: 14px;
  }
`;
