import styled from 'styled-components';

export const WalletNotConnectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 100px 0 202px;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const WalletNotConnectedText = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;

  @media (max-width: 950px) {
    span {
      font-size: 16px;
    }
  }
`;
