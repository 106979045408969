import { Button } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierRightContentThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
`;

export const ThirdBlockTitle = styled(Text)`
  color: rgba(169, 169, 169, 1);
  font-size: 16px;
  font-weight: 500;

  ${media.sm} {
    font-weight: 400;
  }
`;

export const ThirdBlockValue = styled(Text)`
  color: #fff;
  font-size: 32px;
  font-weight: 700;
`;

export const ThirdBlockUnit = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`;

export const SecondBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const SecondBlockItemWrapper = styled.div`
  display: flex;
  padding: 8px 94px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  ${media.xl} {
    padding: 8px 40px;
  }

  ${media.sm} {
    padding: 0 12px;
  }
`;

export const SecondBlockItem = styled.div`
  display: flex;
  padding: 12px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const SecondBlockItemTitle = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
`;

export const SecondBlockItemValue = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
`;

export const SecondBlockTotalWrapper = styled.div`
  display: flex;
  padding: 12px 94px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid rgba(65, 65, 65, 1);

  ${media.xl} {
    padding: 12px 40px;
  }

  ${media.sm} {
    padding: 12px;
  }
`;

export const SecondBlockTotalTitle = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
`;

export const SecondBlockTotalValue = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 700;

  text-align: right;
`;

export const ThirdBlock = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ThirdBlockButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  padding: 0 94px;

  ${media.xl} {
    padding: 0 40px;
  }

  ${media.sm} {
    gap: 20px;
    padding: 0 12px;
    flex-direction: column;
  }
`;

export const ThirdBlockButtonBackWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(65, 65, 65, 1);
  height: 63px;
  user-select: none;
  cursor: pointer;
  width: 50%;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  background-color: #fff;
  border-radius: 12px;
  color: #000;
  font-size: 18px;
  font-weight: 700;

  &:hover {
    opacity: 0.8;
  }

  ${media.sm} {
    width: 100%;
  }
`;

export const ThirdBlockButtonBack = styled(Text)`
  color: #000;
  font-size: 18px;
  font-weight: 500;
`;

export const ThirdBlockButtonConfirmWrapper = styled(Button)`
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  width: 50%;
  border: none;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 12px;

  color: #fff;
  font-weight: 700;
  font-size: 18px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #00aafd;
    background: #fff;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    border: none;
    outline: 0;
  }

  ${media.sm} {
    width: 100%;
  }
`;

export const ThirdBlockButtonGeminiWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(11, 56, 133, 0.24);
  user-select: none;
  cursor: pointer;
  padding: 16px 0;
`;

export const ThirdBlockButtonGemini = styled(Text)`
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 500;
`;

export const SecondBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
