import {
  StepContent,
  StepContentContainer,
  StepWrapper,
  StakingActionContainer,
  InputRow,
  SelectStyled,
  CustomSlider,
  GradientMobile,
  StepNumber,
  StepName,
  CustomedDivider,
  StakingButton,
} from 'pages/Staking/styled';
import gradientMobile from 'assets/images/staking/bg-gradient-mobile.svg';
import { ReactNode } from 'react';

interface IProps {
  stepNumber: string;
  stepName: string;
  stepContent: string;
  children: ReactNode;
}

const HowToEarnUSDTStep = ({
  stepNumber,
  stepName,
  stepContent,
  children,
}: IProps) => {
  return (
    <StepWrapper>
      <StepName>
        <StepNumber>{stepNumber}</StepNumber>
        {stepName}
      </StepName>
      <InputRow>{children}</InputRow>
      <StepContent>{stepContent}</StepContent>
    </StepWrapper>
  );
};

export const HowToEarnUSDTMobile = () => {
  return (
    <StakingActionContainer className="how-to-earn-mobile-wrapper">
      <StepContentContainer>
        <HowToEarnUSDTStep
          stepNumber="1"
          stepName="Choose Term"
          stepContent="Choose 1 of any 3 fixed term deposits. This is the amount of time your
           BIGA tokens will be “locked” the longer the term the higher the interest rate meaning the
            higher the monthly USDT payments that hit your wallet."
        >
          <label className="opacity">Choose your term</label>
          <SelectStyled placeholder="Term" disabled className="opacity" />
        </HowToEarnUSDTStep>
        <CustomedDivider type="vertical" />
        <HowToEarnUSDTStep
          stepNumber="2"
          stepName="Amount of BIGA"
          stepContent="Choose the total amount of BIGA tokens you want to stake. The more you stake the more USDT you earn."
        >
          <label htmlFor="" className="opacity">
            Select amount
          </label>
          <CustomSlider
            defaultValue={0}
            value={50}
            min={0}
            max={100}
            className="opacity"
          />
        </HowToEarnUSDTStep>
        <CustomedDivider type="vertical" />
        <HowToEarnUSDTStep
          stepNumber="3"
          stepName="Click Stake"
          stepContent="Once you click stake, you will be prompted with a tx confirmation from your wallet 
          that holds BIGA tokens. Approve transaction and you’re off. You will start earning USDT right 
          away with your first payment paid in one month!"
        >
          <StakingButton className="opacity">Stake</StakingButton>
        </HowToEarnUSDTStep>
      </StepContentContainer>
      <GradientMobile src={gradientMobile} />
    </StakingActionContainer>
  );
};
