import { useEffect, useState } from 'react';
import { getGameActive, IGameActiveQuery } from 'services/api';

export interface IGameActive {
  gameId?: string;
  scoringModel?: string;
  name?: string;
  description?: string;
  backgroundImgUrl?: string;
  backgroundColor?: string;
  logoColor?: string;
  tags?: string[];
  tokenAddress?: string;
  tokenInfo?: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
  };

  // only showStatistic = true
  totalAttempts?: number;
  totalPlayers?: number;
  totalWalletValue?: string;
  totalRevenue?: number;
  totalOfSpentAmount?: string;
}

export const useGetGameActive = (params: IGameActiveQuery) => {
  const [gameActive, setGameActive] = useState<IGameActive[]>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getGameActive(params);
      if (res?.success) {
        setGameActive(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params]);
  return { gameActive, loading, total };
};
