import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';

export const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { isLoggedIn, playerInfo } = useAppSelector(state => state.player);

  useEffect(() => {
    if (
      !isLoggedIn ||
      playerInfo === null ||
      (playerInfo && playerInfo?.status !== PlayerStatus.UploadedProfile)
    ) {
      navigate(PitRouter.REGISTER);
    }
  }, [isLoggedIn, navigate, playerInfo]);

  return children;
};
