import { StatusButtonStyled } from './styled';

const StatusButton = ({ children, action, onClick, loading, disabled }) => {
  return (
    <>
      <StatusButtonStyled
        action={action}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        {children}
      </StatusButtonStyled>
    </>
  );
};
export default StatusButton;
