import { RegisterConnectText, Wrapper } from 'components/Register/styled';
import styled from 'styled-components';

export const LoginWrapper = styled(Wrapper)`
  position: relative;
`;

export const LoginLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 1.5rem;
`;

export const ForgotPasswordLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

export const ForgotPasswordStepLink = styled.div<{ disabled?: boolean }>`
  color: #00aafd;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-decoration-line: underline;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  :hover,
  :focus {
    color: #fff;
  }
`;

export const LoginConnectText = styled(RegisterConnectText)`
  color: #a9a9a9;
  font-size: 12px;
`;
