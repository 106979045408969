import bigaLogo from 'assets/vectors/logo-biga-arcade.svg';
import { EnterEmailForm } from 'components/ForgotPassword/EnterEmail';
import { ResetPasswordForm } from 'components/ForgotPassword/ResetPassword';
import { PitRouter } from 'constants/routers';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordWrapper, Logo, StepWrapper } from './styled';

export const ResetPassword = () => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  return (
    <ForgotPasswordWrapper>
      <Logo
        src={bigaLogo}
        alt="logo"
        onClick={() => {
          navigate(PitRouter.HOME);
        }}
      />
      <StepWrapper>
        {token ? (
          <ResetPasswordForm token={token}></ResetPasswordForm>
        ) : (
          <EnterEmailForm></EnterEmailForm>
        )}
      </StepWrapper>
    </ForgotPasswordWrapper>
  );
};
