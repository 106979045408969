import { Image } from 'antd';
import styled from 'styled-components';

export const PosterWrapper = styled.div`
  /* text-align: center;
  margin: auto;
  margin-top: 7rem;
  width: 70%; */
  text-align: center;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  min-height: calc(100vh - 73px - 87px);
  width: 70%;

  font-family: 'Neue Haas Grotesk Display Pro', serif;
  background-color: #ffffff;
  font-style: normal;

  color: #0b3885;
  /* min-height: 100%; */
`;

export const PosterTitle = styled.h1`
  /* font-weight: 800; */
  display: flex;
  position: relative;
  .poster-title-stars-top-left {
    position: absolute;
    left: -65px;
    top: -15px;
  }

  .poster-title-stars-bottom-right {
    position: absolute;
    right: -65px;
    bottom: -15px;
  }
  .poster-title {
    font-weight: 700;
    font-size: 28.949px;
    line-height: 35px;

    border: 1px solid rgba(11, 56, 133, 0.21);
    border-radius: 7px;

    padding: 15px 22px;
    color: #0b3885;
  }
`;

export const PosterIcon = styled(Image)`
  width: 40%;
`;

export const TextStyled = styled.p`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 820px) {
    font-size: 18px;
  }
  @media (max-width: 576px) {
    font-size: 17px;
  }
`;

export const SubTitleBox = styled.div`
  margin-top: 3rem;
  width: 50%;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const ShareTwitterButton = styled.a`
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;

  border: none;

  color: #ffffff;
  background-color: #03a9f4;
  border-radius: 6px;

  padding: 9px 14px;
  text-decoration: none;
  :hover,
  :focus {
    color: #ffffff;
  }
`;

export const FailedText = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;
