import { Modal } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalPaymentErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalPaymentErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  gap: 24px;
  padding: 48px 24px;

  & > .payment-checkout-icon {
    scale: 3.5;
  }
`;

export const ModalPaymentErrorFirstBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ErrorText = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  color: #ff1900;
  text-align: center;
`;

export const ErrorDetailText = styled(Text)`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;
