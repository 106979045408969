export enum EventType {
  LevelStarted = 'LevelStarted',
  LevelFinished = 'LevelFinished',
  GameAdded = 'GameAdded',
  GameUpdated = 'GameUpdated',
  Deposited = 'Deposited',
  Withdrawn = 'Withdrawn',
  Staked = 'Staked',
  WithdrawnStake = 'WithdrawnStake',
}
