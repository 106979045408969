import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, Row, Select, Spin, Switch } from 'antd';
import { ActionButton } from 'components/Button/styled';
import { InputWrapper } from 'components/PlatformConfig/styled';
import { GamerDataDayFilterType, TimeType } from 'constants/enum/FilterType';
import {
  getScoringModels,
  ScoringModelLeaderboardTypes,
} from 'constants/enum/ScoringModel';
import {
  ONE_HOUR_IN_MILLISECONDS,
  ONE_MONTH_IN_MILLISECONDS,
  ONE_WEEK_IN_MILLISECONDS,
} from 'constants/value';
import { useGetGameList } from 'hooks/games/useGetGameList';
import { useGetPlatformConfig } from 'hooks/platform-config/useGetPlatformConfig';
import moment, { Moment } from 'moment';
import {
  AdminStatsContentWrapper,
  SelectDaysFilter,
  TimePickerWrapper,
} from 'pages/AdminStats/styled';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { downloadGamerData, IDownloadGamerData } from 'services/api';

import {
  ActionButtonWrapper,
  GamerDataCheckboxWrapper,
  GamerDataColumnWrapper,
  GamerDataGameOptionLable,
  GamerDataInfoTextWrapper,
  GamerDataInfoTitleText,
  GamerDataInfoWrapper,
  GamerDataInputWrapper,
  GamerDataUserInput,
  HeaderContentGamerDataGameDropdownWrapper,
  HeaderContentGamerDataWrapper,
  HeaderContentItemWrapper,
  HeaderToggleItemDetail,
  ScoreTextWrapper,
  SelectGameOption,
} from './styled';

const { Option } = Select;

export const GamerDataTable = () => {
  const [userNameValue, setUserNameValue] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedGame, setSelectedGame] = useState<string>('');
  const [levelPassToggle, setLevelPassToggle] = useState(false);
  const [isScoreSelected, setIsScoreSelected] = useState(false);
  const [gameType, setGameType] = useState<
    ScoringModelLeaderboardTypes | string
  >('');
  const [selectedRange, setSelectedRange] = useState<
    [Moment | null, Moment | null]
  >([moment(), moment().subtract(1, 'day')]);
  const [selectedDayFilter, setSelectedDayFilter] = useState(
    GamerDataDayFilterType.TWENTY_FOUR_HOURS,
  );

  const [dateParams, setDateParams] = useState<{
    startDate: number | null;
    endDate: number | null;
  }>({
    startDate: moment().subtract(1, 'day').valueOf(),
    endDate: moment().valueOf(),
  });

  const [gameListParams, setGameListParams] = useState({
    pageSize: 10,
    pageIndex: 1,
  });

  const { listGame, loading: gameListLoading } = useGetGameList(
    gameListParams,
    false,
  );
  const { loading: configLoading } = useGetPlatformConfig();

  useEffect(() => {
    if (gameListLoading && listGame) {
      if (selectedGame === '') setSelectedGame(listGame[0]?.gameId ?? '');
      const getTypeGame = getScoringModels(listGame[0]?.scoringModel);
      setGameType(getTypeGame ?? '');
    }
  }, [gameListLoading, listGame, selectedGame]);

  const handleGameSelect = selected => {
    if (selected) {
      setSelectedGame(selected ?? '');
      const gameType = getScoringModels(
        listGame.find(game => game.gameId === selected)?.scoringModel,
      );
      setGameType(gameType);
    }
  };

  useEffect(() => {
    if (gameType) {
      setIsScoreSelected(false);
      setLevelPassToggle(false);
    }
  }, [gameType]);

  const handleDayFilterByHoursOrWeek = (
    type: TimeType,
    selected: GamerDataDayFilterType,
  ) => {
    const numberOfHours = Number(selected.split(' ')[0]);
    setSelectedRange([
      moment().subtract(
        numberOfHours,
        type === TimeType.HOURS
          ? TimeType.HOURS
          : type === TimeType.WEEK
          ? TimeType.WEEK
          : TimeType.MONTH,
      ),
      moment(),
    ]);
    switch (type) {
      case TimeType.HOURS:
        setDateParams({
          startDate: Date.now() - numberOfHours * ONE_HOUR_IN_MILLISECONDS,
          endDate: Date.now(),
        });
        break;
      case TimeType.WEEK:
        setDateParams({
          startDate: Date.now() - numberOfHours * ONE_WEEK_IN_MILLISECONDS,
          endDate: Date.now(),
        });
        break;
      case TimeType.MONTH:
        setDateParams({
          startDate: Date.now() - numberOfHours * ONE_MONTH_IN_MILLISECONDS,
          endDate: Date.now(),
        });
        break;
    }
  };

  const handleDayFilterSelect = (selected: GamerDataDayFilterType) => {
    setSelectedDayFilter(selected);
    if (selected === GamerDataDayFilterType.ALL_TIME) {
      setDateParams({
        startDate: 0,
        endDate: Date.now(),
      });
      return;
    }
    switch (selected) {
      case GamerDataDayFilterType.TWENTY_FOUR_HOURS:
        return handleDayFilterByHoursOrWeek(TimeType.HOURS, selected);
      case GamerDataDayFilterType.FORTY_EIGHT_HOURS:
        return handleDayFilterByHoursOrWeek(TimeType.HOURS, selected);
      case GamerDataDayFilterType.ONE_WEEK:
        return handleDayFilterByHoursOrWeek(TimeType.WEEK, selected);
      case GamerDataDayFilterType.ONE_MONTH:
        return handleDayFilterByHoursOrWeek(TimeType.MONTH, selected);
    }
  };

  const handleRangeChange = (dates: [Moment | null, Moment | null]) => {
    setSelectedRange(dates);

    if (dates !== null) {
      const [start, end] = dates;
      setDateParams({
        startDate: start ? start.valueOf() : null,
        endDate: end ? end.valueOf() : null,
      });
    } else {
      setDateParams({ startDate: null, endDate: null });
    }
  };

  const handleRetrievalData: () => Promise<void> = async () => {
    setDownloadLoading(true);
    const downloadParams: IDownloadGamerData = {
      gameId: selectedGame,
      startDate: dateParams.startDate,
      endDate: dateParams.endDate,
      username: userNameValue,
      includeScore:
        gameType === ScoringModelLeaderboardTypes.LEVEL_BASED
          ? levelPassToggle
          : isScoreSelected,
    };
    try {
      const res: any = await downloadGamerData(downloadParams);
      if (res?.status === 201) {
        toast.success('Retrieval data success!!');
      } else if (!res?.statusCode) {
        toast.warning('Not found username');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDownloadLoading(false);
    }
  };
  const handleListGameScroll = e => {
    const element: Element = e?.target;
    if (element.scrollHeight === element.clientHeight + element.scrollTop) {
      setGameListParams(prevParams => ({
        ...prevParams,
        pageSize: prevParams.pageSize + 10,
      }));
    }
  };

  return (
    <Spin spinning={configLoading}>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <HeaderContentGamerDataGameDropdownWrapper>
            <Row style={{ width: '100%', height: '100%' }} justify={'center'}>
              <Col
                span={12}
                xs={24}
                sm={24}
                md={12}
                style={{
                  marginBlock: 'auto',
                  height: '100%',
                  gap: '20px',
                }}
              >
                <GamerDataColumnWrapper>
                  <HeaderContentGamerDataWrapper>
                    <GamerDataGameOptionLable>
                      <span>Game</span>
                      <SelectGameOption
                        value={selectedGame}
                        onChange={handleGameSelect}
                        overwriteMarginTop="0px"
                        overwriteMarginLeft="35px"
                        isGamerDataTable={true}
                        onPopupScroll={handleListGameScroll}
                        loading={gameListLoading}
                        defaultActiveFirstOption={true}
                      >
                        {listGame?.map((game, index) => {
                          const isSpinner: boolean =
                            index === listGame.length - 1 ||
                            listGame[index].gameId === selectedGame;
                          return (
                            <Option key={game.gameId} value={game.gameId}>
                              {gameListLoading
                                ? isSpinner && <Spin spinning={true} />
                                : ` ${game.name}`}
                            </Option>
                          );
                        })}
                      </SelectGameOption>
                    </GamerDataGameOptionLable>
                  </HeaderContentGamerDataWrapper>
                  <HeaderContentGamerDataWrapper>
                    <GamerDataGameOptionLable>
                      <span style={{ minWidth: '70px' }}>User name</span>
                      <GamerDataUserInput>
                        <GamerDataInputWrapper>
                          <InputWrapper
                            overwriteDisplay="flex"
                            overwriteJustifyContent="center"
                            overwriteMinWidth="200px"
                          >
                            <Input
                              type={'string'}
                              placeholder="Please enter username"
                              disabled={gameListLoading}
                              width={100}
                              value={userNameValue}
                              onChange={e => setUserNameValue(e.target.value)}
                            />
                          </InputWrapper>
                        </GamerDataInputWrapper>
                      </GamerDataUserInput>
                    </GamerDataGameOptionLable>
                  </HeaderContentGamerDataWrapper>
                </GamerDataColumnWrapper>
              </Col>
              <Col span={12} xs={24} sm={24} md={12}>
                <GamerDataColumnWrapper>
                  <HeaderContentItemWrapper>
                    <AdminStatsContentWrapper
                      justifyContent="flex-end"
                      alignItems="center"
                      flexGap="0px"
                    >
                      <TimePickerWrapper
                        value={selectedRange}
                        onChange={handleRangeChange}
                        format="DD/MM/YYYY"
                      />
                      <SelectDaysFilter
                        value={selectedDayFilter}
                        onChange={handleDayFilterSelect}
                      >
                        {Object.values(GamerDataDayFilterType).map(
                          (value, index) => (
                            <Option key={index} value={value}>
                              {value}
                            </Option>
                          ),
                        )}
                      </SelectDaysFilter>
                    </AdminStatsContentWrapper>
                  </HeaderContentItemWrapper>
                  <HeaderContentItemWrapper>
                    <HeaderToggleItemDetail>
                      <GamerDataInfoWrapper>
                        {gameType ===
                        ScoringModelLeaderboardTypes.LEVEL_BASED ? (
                          <GamerDataInfoTextWrapper>
                            <GamerDataInfoTitleText>
                              Level Pass
                            </GamerDataInfoTitleText>
                            <Switch
                              checked={levelPassToggle}
                              onChange={() =>
                                setLevelPassToggle(!levelPassToggle)
                              }
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              style={{ marginBlock: 'auto' }}
                            />
                          </GamerDataInfoTextWrapper>
                        ) : (
                          <ScoreTextWrapper>
                            Score
                            <GamerDataCheckboxWrapper>
                              <Checkbox
                                onChange={() =>
                                  setIsScoreSelected(!isScoreSelected)
                                }
                              ></Checkbox>
                            </GamerDataCheckboxWrapper>
                          </ScoreTextWrapper>
                        )}
                      </GamerDataInfoWrapper>
                    </HeaderToggleItemDetail>
                  </HeaderContentItemWrapper>
                </GamerDataColumnWrapper>
              </Col>
            </Row>
          </HeaderContentGamerDataGameDropdownWrapper>
        </Col>
        <Col span={24}>
          <HeaderContentGamerDataGameDropdownWrapper>
            <Row style={{ width: '100%', height: '100%' }} justify={'center'}>
              <Col
                span={12}
                xs={24}
                sm={24}
                md={12}
                style={{
                  marginBlock: 'auto',
                  height: '100%',
                }}
              ></Col>
              <Col
                span={12}
                xs={24}
                sm={24}
                md={12}
                style={{ marginBlock: 'auto', height: '100%' }}
              ></Col>
            </Row>
          </HeaderContentGamerDataGameDropdownWrapper>
        </Col>
      </Row>
      <ActionButtonWrapper>
        <ActionButton
          onClick={handleRetrievalData}
          disabled={downloadLoading || !userNameValue}
        >
          {downloadLoading ? (
            <Spin spinning={true}>Retrieval data</Spin>
          ) : (
            'Retrieval data'
          )}
        </ActionButton>
      </ActionButtonWrapper>
    </Spin>
  );
};
