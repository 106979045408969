import { useEffect, useState } from 'react';
import { getGameActiveDetail } from 'services/api';

export interface IGameInfo {
  gameId: string;
  name: string;
  scoringModel: string;
  description: string;
  backgroundImgUrl: string;
  backgroundColor: string;
  logoColor: string;
  tags: string[];
  slug: string;
  tokenAddress: string;
  tokenInfo: {
    address: string;
    decimals: number;
    name: string;
    symbol: string;
  };
}

export const useGetGameActiveInfo = (gameId: string) => {
  const [gameInfo, setGameInfo] = useState<IGameInfo>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      if (gameId) {
        setLoading(true);
        const res = await getGameActiveDetail(gameId);
        if (res?.success) {
          setGameInfo(res?.payload?.data);
        }
        setLoading(false);
      }
    })();
  }, [gameId]);
  return { gameInfo, loading };
};
