import { isAddress } from 'ethers/lib/utils';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  totalPlayersAndAttempts,
  totalTokenIncome,
  totalValue,
} from 'services/api';

const useDashboardCallback = (gameId: string) => {
  const getTotalPlayersAndAttempts: (params: {
    term: number;
    username?: string;
  }) => Promise<{
    gameId: string;
    totalPlayers: number;
    totalAttempts: number;
  }> = useCallback(
    async (params: { term: number; username?: string }) => {
      try {
        if (gameId && typeof params.term !== 'undefined') {
          return (
            await totalPlayersAndAttempts({
              ...params,
              gameId,
            })
          )?.payload?.data;
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [gameId],
  );

  const getTotalValue: (params: {
    term: number;
    username?: string;
  }) => Promise<{
    gameId: string;
    totalRevenue: string;
    totalOfSpentAmount: string;
  }> = useCallback(
    async (params: { term: number; username?: string }) => {
      try {
        if (gameId && typeof params.term !== 'undefined') {
          return (
            await totalValue({
              ...params,
              gameId,
            })
          )?.payload?.data;
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [gameId],
  );

  const getTotalIncome: () => Promise<{
    totalIncome: string;
    username: string;
  }> = useCallback(async () => {
    try {
      if (gameId) {
        return (await totalTokenIncome(gameId))?.payload?.data;
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.reason || e.message);
    }
  }, [gameId]);

  return { getTotalPlayersAndAttempts, getTotalValue, getTotalIncome };
};

export default useDashboardCallback;
