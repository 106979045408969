import { useEffect, useState } from 'react';
import { getPlayerGameStatistics } from 'services/api';

export interface IPlayerGameStatistics {
  gamesPlayed: number;
  currentRevenueCount: number;
  levelMastered: number;
  gameAmountOfLevelMastered: number;
  spentAmount: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    amount: string;
  };
  revenueAmount: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    amount: string;
  };
}

export const useGetPlayerGameStatistics = (params: any, refresh?: boolean) => {
  const [playerGameStatistics, setPlayerGameStatistics] =
    useState<IPlayerGameStatistics>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getPlayerGameStatistics(params);
      if (res?.success) {
        setPlayerGameStatistics(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { playerGameStatistics, loading };
};
