import { useEffect, useState } from 'react';
import { getGameList } from 'services/api';

import { IGameInfo } from './useGetGameInfo';

export interface IGame extends IGameInfo {
  totalPlayers: number;
  totalAttempts: number;
}

export const useGetGameList = (params, refresh: boolean) => {
  const [listGame, setListGame] = useState<IGame[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getGameList(params);
      if (res?.success) {
        setListGame(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }

      setLoading(false);
    })();
  }, [params, refresh]);
  return { listGame, total, loading };
};
