import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  border: 1px solid #1e1e1ef0;
  background: #111115;
  border-radius: 8px;
  gap: 8px;
  color: #fff;
  padding: 0 10px;

  input {
    background: #111115;
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 14px;
    font-weight: 500;
    border: none;
    outline: none;
    width: 100%;

    &::placeholder {
      color: #727277;
      font-weight: 500;
      font-size: 14px;
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: #fff;
    margin-top: -3px;

    &.fill-white {
      path {
        fill: #fff;
      }
    }
  }

  ${media.md2} {
    background: #050511;

    input {
      background: #050511;
    }
  }
`;
