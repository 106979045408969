import { ButtonStyled } from './styled';
import { GrayButtonProps } from './type';

export const GrayButton = ({
  children,
  onClick,
  isLoading = false,
  disabled = false,
  height,
  width,
  className,
}: GrayButtonProps) => {
  return (
    <ButtonStyled
      onClick={onClick}
      disabled={isLoading || disabled}
      height={height}
      width={width}
      className={className}
    >
      {isLoading ? 'Loading...' : children}
    </ButtonStyled>
  );
};
