import { useEffect, useState } from 'react';
import { getTotalMetricsScoringModelRevenue, ITimeRange } from 'services/api';

export interface ITotalMetricsScoringModelRevenue {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  tokenPrice: number;
  scoringModelsRevenue: any[];
}

export const useGetMetricsScoringModelRevenue = (params: ITimeRange) => {
  const [totalMetricsScoringModelRevenue, setTotalMetricsScoringModelRevenue] =
    useState<ITotalMetricsScoringModelRevenue>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTotalMetricsScoringModelRevenue(params);
      if (res?.success) {
        setTotalMetricsScoringModelRevenue(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return {
    totalMetricsScoringModelRevenue,
    loading,
  };
};
