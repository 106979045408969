export const CHAIN_NETWORK = {
  [42]: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    base_url: 'https://kovan.etherscan.io',
    node: process.env.REACT_APP_KOVAN_NODE,
    chain_name: 'Kovan Test Network',
  },
  [97]: {
    name: 'BNB',
    symbol: 'TBNB',
    decimals: 18,
    base_url: 'https://testnet.bscscan.com',
    node: 'https://bsc-testnet-rpc.publicnode.com',
    chain_name: 'BNB Smart Chain Testnet',
  },
  [56]: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
    base_url: 'https://bscscan.com',
    node: 'https://bsc-dataseed.binance.org',
    chain_name: 'BNB Smart Chain Mainnet',
  },
  [43113]: {
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    base_url: 'https://testnet.explorer.avax.network/',
    node: process.env.REACT_APP_AVALANCHE_NODE,
    chain_name: 'Avalanche FUJI Testnet RPC',
  },
  [43114]: {
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    base_url: 'https://subnets.avax.network/',
    node: 'https://api.avax.network/ext/bc/C/rpc',
    chain_name: 'Avalanche C-Chain',
  },
  [8453]: {
    name: 'Base',
    symbol: 'ETH',
    decimals: 18,
    base_url: 'https://basescan.org',
    node: 'https://base.llamarpc.com/',
    chain_name: 'Base LlamaNodes',
  },
  [84532]: {
    name: 'Base Sepolia',
    symbol: 'ETH',
    decimals: 18,
    base_url: 'https://base-sepolia.blockscout.com',
    node: 'https://sepolia.base.org',
    chain_name: 'Base Sepolia Testnet',
  },
  [80001]: {
    name: 'POLYGON',
    symbol: 'matic',
    decimals: 18,
    base_url: 'https://mumbai.polygonscan.com/',
    node: process.env.REACT_APP_POLYGON_NODE,
    chain_name: 'Polygon Testnet',
  },
  [770]: {
    name: 'GSC',
    symbol: 'GNI',
    decimals: 18,
    base_url: 'http://14.161.39.254:20940/',
    node: process.env.REACT_APP_GSC_NODE,
    chain_name: 'GSC Testnet',
  },
  [11155111]: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    base_url: 'https://sepolia.etherscan.io',
    node: process.env.REACT_APP_SEPOLIA_NODE,
    chain_name: 'Sepolia Testnet',
  },
  [1]: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    base_url: 'https://etherscan.io',
    node: process.env.REACT_APP_ETHEREUM_NODE,
    chain_name: 'Ethereum Mainnet',
  },
};

export const destinationId = {
  [770]: '100',
  [42]: '99',
  [43113]: '97',
  [97]: '98',
  [56]: '98',
  [80001]: '96',
};
