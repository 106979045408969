import { Table } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const LeaderBoardTableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  background-color: transparent;

  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: unset;
  }
`;

export const LeaderboardTableStyled = styled(Table)<{
  hasLoadMore?: boolean;
  firstRankIndex?: number;
}>`
  width: 95%;
  gap: 16px;

  table {
    border-collapse: separate;
    border-spacing: 0 7px;

    tr:first-child {
      border-spacing: unset;
    }

    td {
      border-bottom: none;
    }

    tr {
      td:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }

      td:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }

  .ant-table {
    background: transparent;
  }
  .ant-table-thead tr,
  .ant-table-tbody tr {
    width: 100%;
    background-color: #15151b;
    border-radius: 7px;
    overflow: hidden;
  }

  tr:nth-child(${props => props.firstRankIndex + 1}) {
    background: linear-gradient(
      135deg,
      rgba(255, 0, 0, 0.04),
      rgba(255, 199, 0, 0.04),
      rgba(69, 246, 215, 0.04),
      rgba(70, 67, 255, 0.04),
      rgba(255, 0, 214, 0.04)
    );
    td {
      border-bottom: 1px solid rgba(233, 97, 255, 1);
      &:nth-child(1) {
        color: rgba(233, 97, 255, 1);
      }
    }
  }
  /*
    border-bottom: 2px solid #fff;
    border-image: linear-gradient(
        90deg,
        rgba(255, 0, 214, 0.04),
        rgba(70, 67, 255, 0.04),
        rgba(69, 246, 215, 0.04),
        rgba(255, 199, 0, 0.04),
        rgba(255, 0, 0, 0.04)
      )
      1;
  } */

  .ant-table-tbody tr td {
    height: 40px;
    padding: 0px 16px;
    text-align: left;
    font-size: 14px;

    &:first-of-type {
      padding-left: 40px;
    }
  }

  .ant-table-thead tr th {
    height: 48px;
    padding: 0px 16px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 170, 253, 1);
    text-align: left;
    background-color: rgba(21, 21, 27, 1);

    &:first-of-type {
      padding-left: 40px;
    }

    svg {
      fill: #fff;
    }
  }

  .ant-table-column-sorter-inner {
    color: #1c1b1f;
  }

  .ant-table-tbody tr td {
    height: 56px;
    text-align: left;
  }

  .ant-table-cell {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  .ant-table-column-title,
  .ant-table-thead th {
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  .ant-table-tbody tr:nth-child(even) {
    background-color: rgba(21, 21, 27, 0.29);
  }

  .ant-table-column-sorters {
    display: inline-flex;
  }

  .ant-table-tbody tr:hover td {
    background-color: transparent;
  }

  & > .ant-table-tbody > tr.ant-table-row > td {
    background-color: red;
  }

  .ant-table-tbody tr td.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-column-sorters .ant-table-column-sorter-down.active {
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    width: 0;
  }

  td.column-align-left,
  th.column-align-left {
    text-align: left !important;
  }

  .ant-table-body {
    overflow-y: auto;
    max-height: ${props =>
      props.hasLoadMore
        ? 'calc(100vh - 64px - 117px - 32px - 24px - 39px - 51px) !important'
        : 'calc(100vh - 64px - 117px - 32px - 24px - 39px) !important'};
    @media (max-width: 948px) {
      max-height: ${props =>
        props.hasLoadMore
          ? 'calc(100vh - 64px - 247px - 32px - 24px - 39px - 51px) !important'
          : 'calc(100vh - 64px - 247px - 32px - 24px - 39px) !important'};
    }

    &::-webkit-scrollbar {
      height: 6px;
      border-radius: 100px;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: rgba(86, 86, 86, 0.1);
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;

export const LeaderboardScoreTable = styled(LeaderboardTableStyled)<{
  firstRankIndex?: number;
  secondRankIndex?: number;
  thirdRankIndex?: number;
}>`
  table tr:nth-child(${props => props.firstRankIndex + 1}) td:nth-child(1) {
    color: #fac519;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  table tr:nth-child(${props => props.firstRankIndex + 1}) td {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(90deg, red, rgba(0, 0, 0, 0)) 1 100%; */

    border-color: 5px solid transparent;
  }

  table
    tr:not(.ant-table-placeholder):nth-child(
      ${props => props.firstRankIndex + 1}
    ) {
    background: linear-gradient(
      135deg,
      rgba(255, 0, 0, 0.04),
      rgba(255, 199, 0, 0.04),
      rgba(69, 246, 215, 0.04),
      rgba(70, 67, 255, 0.04),
      rgba(255, 0, 214, 0.04)
    );
  }
  td {
    height: 80px;
  }

  table
    tr:nth-child(${props => props.firstRankIndex + 1})
    td:nth-child(1)
    .circle {
    /* display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px; */
    color: #e961ff;
  }

  table tr:nth-child(${props => props.secondRankIndex + 1}) td {
    border-bottom: 1px solid #ffc700;
    &:nth-child(1) .circle {
      /* display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: #c7d2e0; */
      color: #ffc700;
    }
  }

  table tr:nth-child(${props => props.thirdRankIndex + 1}) td {
    border-bottom: 1px solid rgba(0, 170, 253, 1);
    &:nth-child(1) .circle {
      /* display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: #d9af7c; */
      color: rgba(0, 170, 253, 1);
    }
  }
`;

export const LoadMoreButtonWrapper = styled.div`
  width: 153px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  margin: 10px;
`;

export const LoadMoreContentWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  gap: 8px;
  margin: 16px 0;
  cursor: pointer;
  align-items: center;
`;

export const LoadMoreText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
`;

export const LoadMoreIcon = styled.img`
  width: 14px;
  height: 8px;
`;

export const OwnerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const OwnerAvatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

export const OwnerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

export const OwnerName = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`;

export const HighlightCurrentOwner = styled.span`
  /* background-color: lightgreen; */
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
  padding: 2px;
`;
