import { useEffect, useState } from 'react';
import { getAffiliateTopRevenue, ITimeRange } from 'services/api';

interface ITopRevenue {
  username?: string;
  walletAddress: string;
  avatarURL?: string;
  totalRevenue: number;
}

export const useGetAffiliateTopRevenue = (params: ITimeRange) => {
  const [topAffiliateRevenue, setTopAffiliateRevenue] =
    useState<ITopRevenue[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getAffiliateTopRevenue(params);
      if (res?.success) {
        setTopAffiliateRevenue(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return {
    topAffiliateRevenue,
    loading,
  };
};
