import { PostContractActionDTO as PostContractActionDTO } from 'interfaces/dto/PostContractActionDTO';

import Api from '../index';

export const postContractAction = async (data: PostContractActionDTO) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/contract-action/contract-action-data',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};
