import { useEffect, useState } from 'react';
import { getTotalAffiliate, ITimeRange } from 'services/api';

export interface ITotalAffiliate {
  totalSignUps: number;
  totalRevenue: number;
}

export const useGetTotalAffiliate = (params: ITimeRange) => {
  const [totalAffiliate, setTotalAffiliate] = useState<ITotalAffiliate>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTotalAffiliate(params);
      if (res?.success) {
        setTotalAffiliate(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return { totalAffiliate, loading };
};
