import { Form, Input } from 'antd';
import styled from 'styled-components';
import background from 'assets/images/register/bg-register-login.png';
import registerImage from 'assets/images/register/register-image.png';
import congratulationImage from 'assets/images/register/congratulation-bg.png';
import loginImage from 'assets/images/register/login-image.png';
import { media } from 'constants/enum/breakpoints';
import bgMobile from 'assets/images/register/bg-mobile.svg';

export const RegisterWrapper = styled.div<{ success?: string }>`
  background-image: url(${background});
  background-color: #000;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 50px 0;

  ${media.md2} {
    background: url(${bgMobile});
    background-color: #000;
    background-size: auto 100%;
    border-radius: 0 0 35px 35px;
  }

  .hidden {
    display: none;
  }
`;

export const RegisterContainer = styled.div`
  max-width: 1052px;
  display: flex;
  justify-content: center;
  width: 80%;
  border-radius: 25px;
  overflow: hidden;
  background: linear-gradient(to bottom, #161923, #0f0f13);

  ${media.xl1} {
    max-width: 800px;
  }

  ${media.md2} {
    background: none;
  }

  ${media.sm} {
    width: 100%;
    margin: 0 24px;
  }
`;

export const RegisterImage = styled.div`
  background-image: url(${registerImage});
  background-repeat: no-repeat;
  width: 53%;
  background-size: auto 100%;

  ${media.md2} {
    display: none;
  }
`;

export const CongratulationImage = styled.div`
  background-image: url(${congratulationImage});
  background-repeat: no-repeat;
  width: 53%;
  background-size: auto 100%;

  ${media.md2} {
    display: none;
  }
`;

export const LoginImage = styled.div`
  background-image: url(${loginImage});
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 53%;

  ${media.md2} {
    display: none;
  }
`;

export const RegisterFormContainer = styled.div`
  width: 47%;

  ${media.md2} {
    width: 100%;
    max-width: 494px;
  }
`;

export const TitleStyled = styled.p`
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
`;

export const FormTitleStyled = styled.p`
  color: #000;
  font-size: 25px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
`;

export const FooterTextStyled = styled.p`
  font-size: 18.5px;
  font-weight: 450;
  text-align: center;
  margin-top: 20px;
`;

export const LabelStyled = styled.div`
  color: #b6b6b6;
  display: flex;
  font-size: 20px;
  font-weight: 450;
  justify-content: space-between;
  margin: 80px 0 40px;
`;

export const FormWrapper = styled.div`
  margin-top: 42px;
  padding: 35px;
  border-radius: 15px;
  border: 1px solid #b9b9b9;
  min-width: 400px;
  background-color: #fff;

  .ant-input-affix-wrapper {
    border: #bcdfce 1px solid;
    height: 38px;
    border-radius: 0;
    :focus,
    :active,
    :focus-visible {
      border-color: #0b3885 !important;
      box-shadow: 0 0 0 2px rgb(35 200 121 / 43%) !important;
    }
    :hover {
      border-color: #0b3885 !important;
    }
  }
  .ant-form-item-explain-error {
    font-size: 13px;
  }
`;

export const CustomFormItem = styled(Form.Item)`
  border: none;
`;

export const InputStyle = styled(Input)`
  border: 1px solid #a7a7a7;
  border-radius: 3.5px;
  font-size: 20px;
  font-weight: 450;
  height: 60px;
  :focus,
  :active,
  :focus-visible {
    border-color: #a7a7a7 !important;
  }
  :hover {
    border-color: #a7a7a7 !important;
  }
  ::placeholder {
    color: #525458;
  }
`;

export const PasswordInputStyle = styled(Input.Password)`
  border: 1px solid #a7a7a7 !important;
  border-radius: 3.5px !important;
  font-size: 20px;
  height: 60px !important;
  :focus,
  :active,
  :focus-visible {
    border-color: #a7a7a7 !important;
    box-shadow: 0 0 0 2px rgb(35 200 121 / 43%) !important;
  }
  :hover {
    border-color: #a7a7a7 !important;
  }

  input::placeholder {
    color: #525458;
    font-weight: 450;
  }
`;

export const FormButtonWrapper = styled(Form.Item)`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 2rem 0 0;
  button {
    background: #0b3885;
    border-radius: 3.5px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    height: 40px;
    width: 200px;
    :hover,
    :focus {
      background: #0b3885;
      color: #fff;
    }
  }
`;

export const TextError = styled.p`
  font-size: 14px;
  color: red;
  margin-bottom: 0;
`;

export const StepWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: sticky;
`;
export const LoadingPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const FooterSection = styled.div`
  display: none;

  ${media.md2} {
    display: block;
  }
`;
