import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { LandingFooterSection } from 'components/Landing/FooterSection';
import { LoginForm } from 'components/Login/LoginForm';
import { PitRouter } from 'constants/routers';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import {
  FooterSection,
  LoadingPage,
  LoginImage,
  RegisterContainer,
  RegisterFormContainer,
  RegisterWrapper,
  StepWrapper,
} from 'pages/Register/styled';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { playerInfo, loading } = useGetPlayer(null);
  useEffect(() => {
    if (playerInfo) {
      navigate(PitRouter.GAME_LIST);
    }
  }, [playerInfo, navigate]);
  return (
    <>
      <RegisterWrapper>
        <RegisterContainer>
          <LoginImage></LoginImage>
          <RegisterFormContainer>
            {loading && (
              <LoadingPage>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                />
              </LoadingPage>
            )}
            <StepWrapper>
              <LoginForm />
            </StepWrapper>
          </RegisterFormContainer>
        </RegisterContainer>
      </RegisterWrapper>

      <FooterSection>
        <LandingFooterSection />
      </FooterSection>
    </>
  );
};
