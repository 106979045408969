import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  position: relative;

  ${media.md2} {
    min-height: unset;
    height: 980px;
  }
`;

export const VisionBackground = styled.img`
  position: absolute;
  bottom: -380px;
  z-index: 0;
  left: 0;

  ${media.md} {
    zoom: 0.4;
    top: -500px;
  }
`;

export const VisionContentWrapper = styled.div`
  display: flex;
  margin: auto auto;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  ${media.xl2} {
    zoom: 0.9;
    .animation {
      zoom: 0.8;
    }
  }

  ${media.xl} {
    margin-top: 140px;
  }

  ${media.lg} {
    zoom: 0.7;
  }

  ${media.md2} {
    row-gap: 30px;

    .animation {
      width: 100%;
    }
  }
`;

export const VisionContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 150px;
  align-items: flex-end;
  margin-bottom: 150px;

  ${media.xl} {
    margin-bottom: 0;
  }

  ${media.md2} {
    row-gap: 50px;
    align-items: center;
  }
`;

export const VisionTitleWrapper = styled.div``;

export const VisionTitle = styled.div`
  color: var(--main-white);
  text-align: center;
  font-size: 64px;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 558px;
  line-height: 100%;
  z-index: 0;
  text-align: right;

  ${media.md2} {
    font-size: 28px;
    text-align: center;
  }
`;

export const VisionDescription = styled.div`
  color: var(--main-white);
  text-align: center;
  line-height: 34px;
  z-index: 0;
  font-size: 22px;
  max-width: 320px;
  text-align: left;
  font-weight: 200;
  letter-spacing: 1px;

  ${media.md2} {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
  width: 60vw;
  max-width: 566px;
  height: 80vh;
  min-height: 725px;
  max-height: 725px;

  .rich-coin-rotate {
    transition: rotate ease-in-out 0.3s;
  }

  .animation-bg {
    scale: 0;
    transition: scale ease-in-out 0.3s;
  }

  &:hover {
    .rich-coin-rotate {
      rotate: 90deg;
    }

    .animation-bg {
      scale: 1;
    }
  }

  ${media.md2} {
    width: 100vw;
    margin: auto;
    /* display: flex;
      justify-content: center; */
  }
`;

export const AnimationItem = styled.img`
  position: absolute;
`;
