import { useRef } from 'react';
import {
  CarouselCardWrapper,
  CarouselContainerContent,
  CarouselContainerWrapper,
  CarouselItemList,
  CarouselNavButtons,
} from './styled';
import { GrayButton } from 'components/Button/GrayButton';

export const CarouselContainer = props => {
  const carouselRef = useRef(null);
  const currentSlide = useRef(0);

  function updateSlide() {
    const cardWidth =
      document.getElementsByClassName('carousel-card')[0].clientWidth;
    carouselRef.current.scrollTo({
      left: currentSlide.current * (cardWidth + 20),
      behavior: 'smooth',
    });
  }

  function prevSlide() {
    currentSlide.current =
      currentSlide.current === 0 ? 0 : currentSlide.current - 1;
    updateSlide();
  }

  function nextSlide() {
    currentSlide.current = (currentSlide.current + 1) % props.children.length; // Adjust number based on available cards
    updateSlide();
  }
  return (
    <CarouselContainerWrapper className={props.className || ''}>
      <CarouselContainerContent ref={carouselRef}>
        <CarouselItemList>{props.children}</CarouselItemList>
      </CarouselContainerContent>
      <CarouselNavButtons>
        <GrayButton height="41px" onClick={prevSlide}>
          &#8249;
        </GrayButton>
        <GrayButton height="41px" onClick={nextSlide}>
          &#8250;
        </GrayButton>
      </CarouselNavButtons>
    </CarouselContainerWrapper>
  );
};

export const CarouselCard = props => {
  return (
    <CarouselCardWrapper className="carousel-card">
      {props.children}
    </CarouselCardWrapper>
  );
};
