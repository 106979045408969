import { useEffect, useState } from 'react';
import { getProviderWhitelist } from 'services/api';

export interface IProviderWhitelist {
  username: string;
  avatarURL?: string;
}

export const useGetProviderWhitelist = (params, refresh: boolean) => {
  const [whitelist, setWhitelist] = useState<IProviderWhitelist[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getProviderWhitelist(params);
      if (res?.success) {
        setWhitelist(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { whitelist, total, loading };
};
