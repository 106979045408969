import { AnimationContainer, AnimationItem } from './styled';
import imgage1 from 'assets/main/vison/animation/elipse.png';
import imgage2 from 'assets/main/vison/animation/emoji-riches_coins.png';
import imgage3 from 'assets/main/vison/animation/genie-3-wishes_genie4.png';

export const VisionAnimation = () => {
  return (
    <AnimationContainer>
      <AnimationItem
        className="animation-bg"
        src={imgage1}
        style={{ top: -200, left: -260 }}
      />
      <AnimationItem
        className="rich-coin-rotate"
        src={imgage2}
        style={{ zIndex: 1, top: 200, left: 190 }}
      />
      <AnimationItem src={imgage3} style={{ left: -40 }} />
    </AnimationContainer>
  );
};
