import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

interface ButtonStyledProps {
  height?: string;
  width?: string;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  width: ${({ width }) => width || '236px'};
  height: ${({ height }) => height || '69px'};
  border-radius: 50px;
  border: none;
  color: white;
  font-size: 22px;
  font-weight: 500;
  background: linear-gradient(93deg, #0038ff -49.51%, #00aafd 99.71%);
  box-shadow: 0px 6px 12px 0px rgba(0, 187, 255, 0.24);

  &:hover {
    background: linear-gradient(93deg, #0028dd -49.51%, #0088dd 99.71%);
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }

  ${media.md2} {
    font-size: 14px;
  }
`;
