import { Modal } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const Wrapper = styled.div`
  text-align: center;
  background: #fff;
  padding: 2.5rem;

  max-width: 100%;

  margin-bottom: 1rem;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalVerifyOtpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RegisterVerifyCodeWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

export const RegisterVerifyCodeStepHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  align-items: center;
  gap: 1.5rem;
`;

export const RegisterStepTitle = styled.div`
  color: var(--biga-blue);
  text-align: center;
  font-family: 'Changeling Neo';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  max-width: 28rem;
`;

export const RegisterStepDescription = styled.div`
  color: #000;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-align: center;
`;

export const RegisterVerifyCodeBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

export const RegisterVerifyCodeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

export const RegisterVerifyCodeInput = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #fff;
  padding: 0.625rem 1rem;
  text-align: center;

  width: 3.5rem;
  aspect-ratio: 1 / 1;

  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--biga-blue);

  @media (max-width: 478px) {
    width: 2.5rem;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  @media (max-width: 348px) {
    width: 1.5rem;
    font-size: 0.5rem;
  }

  outline: none;
`;

export const TextErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > img {
    cursor: pointer;
  }
`;

export const TextError = styled.p`
  color: #ff3b3b;
  /* text-align: center; */
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`;

export const CenterTextError = styled(TextError)`
  text-align: center;
  white-space: pre-wrap;
`;

export const BackText = styled.div`
  white-space: pre-wrap;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RegisterStepBlueLink = styled.div`
  color: var(--biga-blue);
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

export const RegisterVerifyCodeStepTimer = styled.div`
  color: #ff3b3b;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
