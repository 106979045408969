import styled from 'styled-components';
import { media } from 'constants/enum/breakpoints';

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  position: relative;

  ${media.xl} {
    min-height: unset;
  }

  ${media.md2} {
    /* min-height: unset; */
    height: 920px;
  }
`;

export const HowToWorkBackground = styled.img`
  position: absolute;
  bottom: -680px;
  z-index: 0;
  right: 0;
  /* display: none; */

  ${media.md} {
    zoom: 0.3;
    right: -200px;
  }
`;

export const HowToWorkRichesCoins = styled.img`
  position: absolute;
  right: 0;
  top: 200px;
  height: 380px;

  ${media.md2} {
    top: 100px;
    left: 30vw;
    height: 280px;
  }
`;

export const HowToWorkRichesCoins1 = styled.img`
  position: absolute;
  left: 0;
  top: 150px;

  ${media.md2} {
    display: none;
  }
`;

export const HowToWorkVideo = styled.div`
  width: 100%;
  max-width: 1180px;
  height: 738px;
  margin: auto auto;
  border-radius: 18px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: black;

  * {
    color: white;
  }

  ${media.xl} {
    zoom: 0.8;
    margin: 100px auto;
  }

  ${media.lg} {
    zoom: 0.7;
  }

  ${media.md2} {
    width: 90%;
    margin: auto auto;
    height: max-content;
  }
`;

export const HowToWorkVideoIframe = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 18px;
  min-height: 738px;

  ${media.md2} {
    min-height: 90%;
  }
`;

export const HowToWorkVideoCover = styled.img`
  position: absolute;
  width: 100%;

  ${media.md2} {
    position: unset;
  }
`;

export const HowToWorkVideoContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #4b4b4b;
  padding: 30px 50px;
  z-index: 1;

  .title {
    font-size: 24px;
    font-weight: 500;
  }

  .description {
    margin-top: 10px;
    font-size: 16px;
  }

  ${media.md2} {
    zoom: unset;
    border: none;
    position: absolute;
    bottom: -380px;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      * {
        text-align: center;
      }
    }
  }
`;
