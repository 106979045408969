import styled from 'styled-components';

export const BannerWrapper = styled.div`
  width: 100%;

  background-color: lightgreen;

  font-size: 18px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 500;
  text-align: center;
  z-index: 100;
  padding: 10px;
  color: black;
`;
