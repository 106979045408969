import { Collapse } from 'antd';
import { ReactComponent as ArrowIcon } from 'assets/vectors/logo-support-arrow.svg';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { Link } from 'react-router-dom';
import HomepageTemplate from 'template/Homepage';
import { ReactComponent as SupportIcon } from 'assets/vectors/logo-navbar-help.svg';
import aboveGradient from 'assets/images/support/bg-gradient-1.png';
import belowGradient from 'assets/images/support/bg-gradient-2.png';
import mobileGradient from 'assets/images/profile/gradient-mobile.svg';

import {
  AboveGradient,
  BelowGradient,
  CollapseContent,
  CollapseHeader,
  CollapseWrapper,
  FirstBlock,
  FirstBlockItem,
  FirstBlockItemDecription,
  FirstBlockItemTitle,
  MobileGradient,
  MobileTitlePageWrapper,
  SecondBlock,
  SupportContentWrapper,
  SupportIconWrapper,
  SupportWrapper,
  TextBIGAButton,
  TextBIGAMail,
  TextMail,
  ThirdBlock,
  ThirdBlockBody,
  ThirdBlockHeader,
  ThirdBlockHeaderDecription,
  TitlePageWrapper,
} from './styled';

const { Panel } = Collapse;

export const SupportPage = () => {
  const isEnableCashier = useEnableCashier();
  const panels = [
    {
      key: '1',
      header: <CollapseHeader>What are BIGA Credits?</CollapseHeader>,
      content: (
        <CollapseContent>
          BIGA Credits are the official virtual currency used to access and
          enjoy our games on our Arcade platform. To play our games, you will
          need to acquire BIGA Credits. They can be purchased through our
          cashier using USDT, USDC, or ETH, or through our trusted fiat payment
          provider.
        </CollapseContent>
      ),
    },
    {
      key: '2',
      header: <CollapseHeader>How much is one BIGA credit?</CollapseHeader>,
      content: (
        <CollapseContent>
          Each BIGA Credit holds a value of 0.10 USD.
        </CollapseContent>
      ),
    },
    {
      key: '3',
      header: <CollapseHeader>How do I purchase BIGA Credits?</CollapseHeader>,
      content: (
        <CollapseContent>
          To purchase BIGA Credits, simply visit our{' '}
          {isEnableCashier ? (
            <Link to={PitRouter.CASHIER}>cashier page</Link>
          ) : (
            'cashier page'
          )}
          . On this page, you will find various payment options available.
          Choose your preferred payment method and follow the instructions to
          complete the purchase of BIGA Credits.
        </CollapseContent>
      ),
    },
    {
      key: '4',
      header: (
        <CollapseHeader>How do I make money playing BIGA?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          To earn income playing BIGA games, you need to achieve and maintain
          level ownership in the games while other gamers attempt to compete and
          dethrone you. When you are the Level Master, all revenue generated
          from the plays encountered for that specific level or game goes
          directly into your account. You have the flexibility to utilize these
          earnings to conquer more levels, enhance your gaming experience, or
          even withdraw the funds into USD or USDT.
        </CollapseContent>
      ),
    },
    {
      key: '5',
      header: (
        <CollapseHeader>
          I don&apos;t have a crypto wallet, can I still purchase BIGA?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          Absolutely! We have integrated Stripe, a trusted payment provider, to
          offer you an alternative way to purchase BIGA Credits. Through Stripe,
          you can conveniently purchase BIGA Credits using fiat currency (USD).
          This means you can buy BIGA Credits without needing a crypto wallet.
          <br />
          <br />
          Please note that currently, the only withdrawal option is through a
          digital wallet. If you don&apos;t have one or need assistance, feel
          free to reach out to{' '}
          <TextMail to="mailto:hello@bigarcade.org">
            hello@bigarcade.org
          </TextMail>
          .
        </CollapseContent>
      ),
    },
    {
      key: '6',
      header: <CollapseHeader>Can I play for Free?</CollapseHeader>,
      content: (
        <CollapseContent>
          Absolutely! Each game on our platform offers a free version that
          allows you to test the gameplay and experience the game before
          deciding to make the step into the REAL game. The free versions
          provide you with a taste of the game and its mechanics without
          requiring any BIGA Credits. You can enjoy these free versions
          regardless of the number of BIGA Credits you have in your account.
        </CollapseContent>
      ),
    },
    {
      key: '7',
      header: <CollapseHeader>What is a level master?</CollapseHeader>,
      content: (
        <CollapseContent>
          A Level Master is the prestigious title given to the owner of the
          highest score or the most recent gamer who passes that level in a
          specific game. Becoming a Level Master comes with exclusive benefits.
          The Level Master not only earns recognition and respect but also has
          the opportunity to generate INCOME from the revenue generated by that
          particular game. They continue to earn from the game until another
          skilled gamer surpasses their score and takes over the title as the
          new Level Master.
        </CollapseContent>
      ),
    },
    {
      key: '8',
      header: (
        <CollapseHeader>
          Is every game the same BIGA credit amount to play?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          No, each game has its own unique pricing structure. <br />
          <br />
          In certain games, the price may vary depending on the level or stage
          you wish to access. Some levels may require higher skill levels and
          confidence to attempt, making them more challenging. As the difficulty
          and demand increase, so does the credit requirement for those specific
          levels. This also means that the Level Master who achieves the highest
          score or mastery in those levels earns a higher revenue value.
        </CollapseContent>
      ),
    },
    {
      key: '9',
      header: <CollapseHeader>Is this Gambling?</CollapseHeader>,
      content: (
        <CollapseContent>
          No, our platform is not classified as gambling. <br />
          <br />
          Our games are skill-based rather than relying solely on luck. Becoming
          a Level Master requires dedication, effort, and honing your gaming
          skills. It&apos;s the mastery of gameplay and strategy that determines
          success, rather than chance or random outcomes. Therefore, our
          platform is not deemed as gambling.
        </CollapseContent>
      ),
    },
    {
      key: '10',
      header: (
        <CollapseHeader>I have a partnership opportunity?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          That&apos;s great to hear! We are always open to collaborating with
          projects that share our goal of providing gamers with the opportunity
          to earn income through playing games. To discuss partnership
          opportunities, please send us a message at{' '}
          <TextMail to="mailto:partnerships@bigarcade.org">
            partnerships@bigarcade.org
          </TextMail>
          . We will carefully review your proposal and respond if we find it to
          be a great fit for our platform.
        </CollapseContent>
      ),
    },
    {
      key: '11',
      header: <CollapseHeader>Can I play your games on Mobile?</CollapseHeader>,
      content: (
        <CollapseContent>
          Yes! We now support mobile gameplay through our Telegram App. While
          all our games are designed for desktop users, you can enjoy them on
          mobile as well. For the best experience, we recommend following the
          video linked here for more information:{' '}
          <Link to="https://www.youtube.com/watch?v=Ch3aZQxwo5o">
            https://www.youtube.com/watch?v=Ch3aZQxwo5o
          </Link>
          .
        </CollapseContent>
      ),
    },
    {
      key: '12',
      header: (
        <CollapseHeader>
          Can I feature my Game on the BIGA arcade?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          Absolutely! We are interested in partnering with third-party
          developers or studios who want to launch or upload their games on BIGA
          Arcade. By featuring your game on our platform, you will have the
          opportunity to earn royalties based on the plays your game receives.
          This provides a revenue stream without the need for intrusive
          advertising placements that can be annoying for gamers. We do have
          specific requirements that your game must meet, but we are also
          flexible and willing to work with the right game to ensure a
          successful collaboration.
          <br />
          <br />
          To explore this opportunity further, please email{' '}
          <TextMail to="mailto:dev@bigarcade.org">dev@bigarcade.org</TextMail>.
        </CollapseContent>
      ),
    },
    {
      key: '13',
      header: (
        <CollapseHeader>How do you prevent cheating in games?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          At BIGA, we take cheating very seriously, and we have strict measures
          in place to maintain fair gameplay. Any gamer found attempting to
          cheat will face immediate termination from our platform. We also
          employ the latest anti-hacking software tools to detect and prevent
          cheating attempts. Our team is continuously updating and improving our
          games to stay ahead of any potential loopholes that cheaters may
          exploit. <br />
          <br />
          By maintaining a strong stance against cheating and implementing
          robust security measures, we strive to create a fair and enjoyable
          gaming environment for all our players.
        </CollapseContent>
      ),
    },
    {
      key: '14',
      header: (
        <CollapseHeader>Do you do any Collabs with streamers?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          Yes, we do collaborate with streamers! If you are a streamer who has a
          strong relationship with your viewers, regardless of your size or
          following, we encourage you to reach out to us at
          partnerships@bigarcade.org. We are always looking for streamers who
          align with our goals and vision. We will carefully evaluate the
          synergy between our platform and your content and get back to you if
          we see a potential collaboration opportunity.
        </CollapseContent>
      ),
    },
    {
      key: '15',
      header: (
        <CollapseHeader>How often do you upload NEW games?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          At BIGA, our aim is to provide a diverse selection of games to our
          users. We have set a goal to offer 100 games on the BIGA platform
          within 24 months of going LIVE. To achieve this, we are actively
          working on releasing new games or multiple games every two weeks from
          the launch of the BIGA platform. <br />
          <br />
          We are dedicated to including various genres, including FPS, puzzle,
          racing, adventure, and more. We welcome game submissions from
          developers who believe their games would be a good fit for our arcade.
          If you have a game you&apos;d like to feature on our platform, please
          send us a message!
        </CollapseContent>
      ),
    },
    {
      key: '16',
      header: (
        <CollapseHeader>
          What is the Public Alpha for BIGA Arcade?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          The Public Beta is a special phase where we invite players to
          experience a select portion of BIGA Arcade. This period allows you to
          explore the platform, enjoy a variety of games, and provide us with
          valuable feedback—all without the need for in-game credits.
          Additionally, any in-game revenue you accumulate during the Public
          Beta is simulated, offering a glimpse of the earnings you could
          achieve by reaching <u>Level Master</u> status or by climbing the
          leaderboards in the full version of BIGA Arcade.
        </CollapseContent>
      ),
    },
  ];
  return (
    <HomepageTemplate>
      <SupportWrapper>
        <SupportContentWrapper>
          <MobileTitlePageWrapper>
            <SupportIconWrapper>
              <SupportIcon />
            </SupportIconWrapper>
            Support
          </MobileTitlePageWrapper>
          <FirstBlock>
            <FirstBlockItem>
              <FirstBlockItemTitle>Customer Support</FirstBlockItemTitle>
              <FirstBlockItemDecription>
                Can&apos;t find what you&apos;re looking for? If your query
                hasn&apos;t been addressed in the FAQs above, we&apos;re here to
                help.
                <br />
                <br />
                Please feel free to email us at{' '}
                <TextBIGAMail to="mailto:hello@bigarcade.org">
                  hello@bigarcade.org
                </TextBIGAMail>
                . Our dedicated team is always ready to provide you with the
                assistance you need.
              </FirstBlockItemDecription>
            </FirstBlockItem>
            <FirstBlockItem>
              <FirstBlockItemTitle>Partnership Inquiry</FirstBlockItemTitle>
              <FirstBlockItemDecription>
                We are always looking for partners with whom we can create
                synergy and provide value to BIGA and our users. Please email us
                at{' '}
                <TextBIGAMail to="mailto:partners@bigarcade.org">
                  partners@bigarcade.org.
                </TextBIGAMail>
              </FirstBlockItemDecription>
            </FirstBlockItem>
            <FirstBlockItem>
              <FirstBlockItemTitle>Game Developers</FirstBlockItemTitle>
              <FirstBlockItemDecription>
                Want to build a game on BIGA? Game developers and studios have
                the opportunity to build or integrate their existing games onto
                our platform if they follow our requirements and are approved.
                If interested, please fill out this form{' '}
                <TextBIGAButton id="XbXfic2M">CLICK HERE</TextBIGAButton>.
              </FirstBlockItemDecription>
            </FirstBlockItem>
          </FirstBlock>
          <SecondBlock></SecondBlock>
          <ThirdBlock>
            <ThirdBlockHeader>
              <TitlePageWrapper>
                <SupportIconWrapper className="icon-of-question">
                  <SupportIcon />
                </SupportIconWrapper>
                Frequently Asked Questions
              </TitlePageWrapper>
              <ThirdBlockHeaderDecription>
                Most common questions users have about BIGA are answered below.
                If you do not find what you are looking for, please contact us
                at{' '}
                <TextBIGAMail
                  to="mailto:hello@bigarcade.org"
                  className="third-block-email"
                >
                  hello@bigarcade.org
                </TextBIGAMail>
                .
              </ThirdBlockHeaderDecription>
            </ThirdBlockHeader>
            <ThirdBlockBody>
              <CollapseWrapper
                defaultActiveKey={['1']}
                expandIconPosition="end"
                bordered={false}
                expandIcon={({ isActive }) => (
                  <ArrowIcon
                    style={{
                      transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                )}
              >
                {panels.map(panel => (
                  <Panel key={panel.key} header={panel.header}>
                    {panel.content}
                  </Panel>
                ))}
              </CollapseWrapper>
            </ThirdBlockBody>
          </ThirdBlock>
        </SupportContentWrapper>
        <AboveGradient src={aboveGradient} />
        <BelowGradient src={belowGradient} />
        <MobileGradient src={mobileGradient} />
      </SupportWrapper>
    </HomepageTemplate>
  );
};
