import {
  HowToWorkBackground,
  HowToWorkRichesCoins,
  HowToWorkRichesCoins1,
  HowToWorkVideo,
  HowToWorkVideoContent,
  HowToWorkVideoCover,
  HowToWorkVideoIframe,
  Wrapper,
} from './styled';
import HowToWorkBg from 'assets/main/how-to-work/how-to-work-bg.png';
import RichesCoins1 from 'assets/main/how-to-work/emoji-riches_coins-1.png';
import RichesCoins from 'assets/main/how-to-work/emoji-riches_coins.png';
import VideoPlayBtn from 'assets/main/how-to-work/video-play-btn.png';
import { GrayButton } from 'components/Button/GrayButton';
import VideoCover from 'assets/main/how-to-work/video-cover.png';

export const HowToWork = () => {
  const onPlayVideo = () => {
    const element = window.document.getElementById('video-player');
    element.style.zIndex = '2';
    if (window['YT'] && window['YT'].Player) {
      new window['YT'].Player('video-player', {
        videoId: 'PiHLJ2SzSG4',
        playerVars: {
          playersinline: 1,
          autoplay: 0,
          controls: 1,
        },
        events: {
          onReady: event => {
            console.log('Ready');
            event.target.playVideo();
          },
          onError: e => {
            console.log('video error', e);
          },
        },
      });
    }
  };

  return (
    <Wrapper id="how-to-work">
      <HowToWorkBackground src={HowToWorkBg} />
      <HowToWorkRichesCoins src={RichesCoins} />
      <HowToWorkRichesCoins1 src={RichesCoins1} />
      <HowToWorkVideo>
        <HowToWorkVideoIframe id="video-player"></HowToWorkVideoIframe>
        <HowToWorkVideoCover src={VideoCover} alt="" />
        <HowToWorkVideoContent className="guide">
          <div className="content-wrapper">
            <div className="title">HOW BIGA WORKS</div>
            <div className="description">
              Watch 2 min video to know how you can make money playing games on
              BIGA
            </div>
          </div>
          <GrayButton onClick={onPlayVideo}>
            <div className="play-btn" style={{ marginLeft: '-50px' }}>
              <img src={VideoPlayBtn} alt="" /> Play video
            </div>
          </GrayButton>
        </HowToWorkVideoContent>
      </HowToWorkVideo>
    </Wrapper>
  );
};
