import { useEffect, useState } from 'react';
import { getSupportedTokenList } from 'services/api';

export interface ISupportedToken {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
}

export const useGetSupportedTokenList = (params, refresh: boolean) => {
  const [listToken, setListToken] = useState<ISupportedToken[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getSupportedTokenList(params);
      if (res?.success) {
        setListToken(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { listToken, total, loading };
};
