import { SplashVideoFullScreen } from 'components/AccessControl/styled';
import { LandingFooterSection } from 'components/Landing/FooterSection';
import { PitRouter } from 'constants/routers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayScoreEarn } from '../../components/Landing/PlayScoreEarn';
import { LandingPageWrapper } from './styled';
import { ArcadeVision } from 'components/Landing/ArcadeVision';
import { HowToWork } from 'components/Landing/HowToWork';
import { OurGames } from 'components/Landing/OurGames';
import { HangOut } from 'components/Landing/HangOut';
import { LandingPartnersSection } from 'components/Landing/PartnersSection';

export const LandingPage = () => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState<boolean>(false);

  return (
    <LandingPageWrapper>
      <PlayScoreEarn />
      {showVideo && (
        <SplashVideoFullScreen
          onEnded={() => {
            navigate(PitRouter.REGISTER);
            setShowVideo(false);
          }}
          className="splash-video-full-screen"
        />
      )}
      <ArcadeVision />
      <HowToWork />
      <OurGames />
      <HangOut />
      <LandingPartnersSection />
      <LandingFooterSection />
    </LandingPageWrapper>
  );
};
