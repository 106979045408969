import AppColumn from 'components/Column';
import styled from 'styled-components';

export const Mobile = styled.div`
  display: none;
  @media (max-width: 1400px) {
    display: block;
  }
`;

export const Desktop = styled(AppColumn)`
  background: #0f0f13;
  height: auto;
  min-height: 100vh;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 30px;
`;

export const Logo = styled.div`
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
`;

export const WrapperNavbar = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;
