import { useEffect, useState } from 'react';
import { getTotalMetricsSummary, ITimeRange } from 'services/api';

export interface ITotalMetricsSummary {
  totalSignUps: number;
  totalUniquePlayers: number;
  totalPlays: number;
}

export const useGetTotalMetricsSummary = (params: ITimeRange) => {
  const [totalMetricsSummary, setTotalMetricsSummary] =
    useState<ITotalMetricsSummary>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTotalMetricsSummary(params);
      if (res?.success) {
        setTotalMetricsSummary(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return { totalMetricsSummary, loading };
};
