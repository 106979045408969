import { abi as Erc20TokenAbi } from 'constants/abis/erc20.abi.json';
import { MAX_UINT_256 } from 'constants/value';
import { ethers } from 'ethers';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useContract from 'hooks/useContract';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

const useERC20Callback = (token: string) => {
  const { account } = useActiveWeb3React();
  const ERC20 = useContract(token, Erc20TokenAbi);

  // Write function
  const approve = useCallback(
    async (to: string) => {
      try {
        if (ERC20 && account) {
          const gasLimit = await ERC20.estimateGas.approve(to, MAX_UINT_256);
          const tx = await ERC20.approve(to, MAX_UINT_256, {
            gasLimit: ethers.BigNumber.from(gasLimit).mul(2),
          });
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [ERC20, account],
  );

  // Read function
  const balanceOf = useCallback(() => {
    try {
      if (ERC20 && account) {
        return ERC20.balanceOf(account);
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [ERC20, account]);

  const allowance = useCallback(
    (spender: string) => {
      try {
        if (ERC20 && spender && account) {
          return ERC20.allowance(account, spender);
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [ERC20, account],
  );

  const decimals = useCallback(() => {
    try {
      if (ERC20) {
        return ERC20.decimals();
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [ERC20]);

  const symbol = useCallback(() => {
    try {
      if (ERC20) {
        return ERC20.symbol();
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [ERC20]);

  return { approve, balanceOf, allowance, decimals, symbol };
};

export default useERC20Callback;
