import { Select, Spin } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { AdminStatisticDayFilterType } from 'constants/enum/FilterType';
import { ONE_DAY_IN_MILLISECONDS } from 'constants/value';
import { formatUnits } from 'ethers/lib/utils';
import { useGetAffiliateTopRevenue } from 'hooks/admin-statistic/useGetAffiliateTopSignRevenue';
import { useGetAffiliateTopSignUp } from 'hooks/admin-statistic/useGetAffiliateTopSignUp';
import { useGetAverageMetricsAverageUserSpent } from 'hooks/admin-statistic/useGetAverageMetricsAverageUserSpent';
import { useGetTopMetricsTopGames } from 'hooks/admin-statistic/useGetTopMetricsTopGames';
import { useGetTopMetricsTopUsers } from 'hooks/admin-statistic/useGetTopMetricsTopUsers';
import { useGetTotalAffiliate } from 'hooks/admin-statistic/useGetTotalAffiliate';
import { useGetMetricsPlatformRevenue } from 'hooks/admin-statistic/useGetTotalMetricsPlatformRevenue';
import { useGetMetricsScoringModelRevenue } from 'hooks/admin-statistic/useGetTotalMetricsScoringModelRevenue';
import { useGetTotalMetricsSummary } from 'hooks/admin-statistic/useGetTotalMetricsSummary';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import HomepageTemplate from 'template/Homepage';

import {
  AdminStatsContentBlock,
  AdminStatsContentBlockItem,
  AdminStatsContentItemTitle,
  AdminStatsContentItemValue,
  AdminStatsContentTitle,
  AdminStatsContentWrapper,
  AdminStatsWrapper,
  ChartWrapper,
  EmptyWrapper,
  SelectDaysFilter,
  TimePickerWrapper,
  ToolTipChartWrapper,
  ToolTipProfile,
  ToolTipProfileAvatar,
  ToolTipProfileName,
  ToolTipText,
} from './styled';

const { Option } = Select;

const COLORS = [
  '#a2b7ca',
  '#68b7a9',
  '#dab86e',
  '#f6b898',
  '#b5b3e7',
  '#f9a0cf',
  '#e6d688',
];

export const AdminStats = () => {
  const [selectedRange, setSelectedRange] = useState<
    [Moment | null, Moment | null]
  >([moment(), moment().subtract(1, 'day')]);

  const [params, setParams] = useState<{
    startDate: number | null;
    endDate: number | null;
  }>({
    startDate: moment().subtract(1, 'day').valueOf(),
    endDate: moment().valueOf(),
  });

  const handleRangeChange = (dates: [Moment | null, Moment | null]) => {
    setSelectedRange(dates);

    if (dates !== null) {
      const [start, end] = dates;
      setParams({
        startDate: start ? start.valueOf() : null,
        endDate: end ? end.valueOf() : null,
      });
    } else {
      setParams({ startDate: null, endDate: null });
    }
  };

  const [selectedDayFilter, setSelectedDayFilter] = useState(
    AdminStatisticDayFilterType.ONE_DAY,
  );

  const handleDayFilterSelect = (selected: AdminStatisticDayFilterType) => {
    setSelectedDayFilter(selected);
    if (selected === AdminStatisticDayFilterType.ALL_TIME) {
      setParams({
        startDate: 0,
        endDate: Date.now(),
      });
    } else {
      const numberOfDay = Number(selected.split(' ')[0]);
      setSelectedRange([moment().subtract(numberOfDay, 'days'), moment()]);
      setParams({
        startDate: Date.now() - numberOfDay * ONE_DAY_IN_MILLISECONDS,
        endDate: Date.now(),
      });
    }
  };

  const { totalMetricsSummary } = useGetTotalMetricsSummary(params);
  const { totalAffiliate } = useGetTotalAffiliate(params);

  const averageAmountOfPlayPerUser =
    totalMetricsSummary?.totalPlays / totalMetricsSummary?.totalUniquePlayers ||
    0;

  const { totalMetricsPlatformRevenue, loading: loadingPlatformRevenue } =
    useGetMetricsPlatformRevenue(params);

  const _tokenPriceMetricsPlatformRevenue = +(
    totalMetricsPlatformRevenue?.tokenPrice || 0
  );

  const totalRevenueGenerated =
    (parseFloat(
      formatUnits(
        totalMetricsPlatformRevenue?.totalPlatformFee || 0,
        totalMetricsPlatformRevenue?.decimals || 18,
      ),
    ) +
      parseFloat(
        formatUnits(
          totalMetricsPlatformRevenue?.totalAffiliateFee || 0,
          totalMetricsPlatformRevenue?.decimals || 18,
        ),
      ) +
      parseFloat(
        formatUnits(
          totalMetricsPlatformRevenue?.totalLevelOwnerFee || 0,
          totalMetricsPlatformRevenue?.decimals || 18,
        ),
      ) +
      parseFloat(
        formatUnits(
          totalMetricsPlatformRevenue?.totalProviderFee || 0,
          totalMetricsPlatformRevenue?.decimals || 18,
        ),
      ) +
      parseFloat(
        formatUnits(
          totalMetricsPlatformRevenue?.totalTreasuryFee || 0,
          totalMetricsPlatformRevenue?.decimals || 18,
        ),
      )) *
    _tokenPriceMetricsPlatformRevenue;

  const totalRevenueGeneratedChart = totalMetricsPlatformRevenue
    ? Object.entries(totalMetricsPlatformRevenue)
        .filter(
          ([key]) =>
            key !== 'address' &&
            key !== 'name' &&
            key !== 'symbol' &&
            key !== 'decimals' &&
            key !== 'tokenPrice',
        )
        .map(([key, value]) => ({
          name: key,
          value: +Number(
            +formatUnits(value, totalMetricsPlatformRevenue?.decimals || 18) *
              _tokenPriceMetricsPlatformRevenue,
          ).toFixed(2),
        }))
    : [];

  const {
    totalMetricsScoringModelRevenue,
    loading: loadingScoringModelRevenue,
  } = useGetMetricsScoringModelRevenue(params);

  const _tokenMetricsScoringModelRevenue = +(
    totalMetricsScoringModelRevenue?.tokenPrice || 0
  );

  const scoringModelChart = [];

  for (
    let i = 0;
    i < totalMetricsScoringModelRevenue?.scoringModelsRevenue.length;
    i++
  ) {
    const scoringModel =
      totalMetricsScoringModelRevenue?.scoringModelsRevenue[i].scoringModel;
    const amountString =
      totalMetricsScoringModelRevenue?.scoringModelsRevenue[i].amount;

    const newObj = {
      scoringModel: scoringModel,
      amount: +(
        +formatUnits(
          amountString,
          totalMetricsScoringModelRevenue?.decimals || 18,
        ) * _tokenMetricsScoringModelRevenue
      ).toFixed(2),
    };

    scoringModelChart.push(newObj);
  }

  const { averageMetricsAverageUserSpent } =
    useGetAverageMetricsAverageUserSpent(params);
  const _tokenAverageMetricsAverageUserSpent = +(
    averageMetricsAverageUserSpent?.tokenPrice || 0
  );

  const { topMetricsTopUsers } = useGetTopMetricsTopUsers(params);
  const { topAffiliateSignUp } = useGetAffiliateTopSignUp(params);
  const { topAffiliateRevenue } = useGetAffiliateTopRevenue(params);

  const _tokenTopUsersSpendChart = +(
    totalMetricsScoringModelRevenue?.tokenPrice || 0
  );
  const topUsersSpendChart = [];

  for (let i = 0; i < topMetricsTopUsers?.sortedByTotalEntryFee.length; i++) {
    const totalEntryFeeData =
      topMetricsTopUsers?.sortedByTotalEntryFee[i].totalEntryFee;
    const totalPlaysData =
      topMetricsTopUsers?.sortedByTotalEntryFee[i].totalPlays;
    const usernameData = topMetricsTopUsers?.sortedByTotalEntryFee[i].username;
    const avatarURLData =
      topMetricsTopUsers?.sortedByTotalEntryFee[i]?.avatarURL;

    const newObj = {
      totalPlays: totalPlaysData,
      totalEntryFee: +(
        +formatUnits(totalEntryFeeData, 18) * _tokenTopUsersSpendChart
      ).toFixed(2),
      username: usernameData,
      avatarURL: avatarURLData,
    };

    topUsersSpendChart.push(newObj);
  }

  const { topMetricsTopGames } = useGetTopMetricsTopGames(params);
  const _tokenTopMetricsTopGames = +(
    topMetricsTopGames?.supportedToken.tokenPrice || 0
  );
  const topGamesSpendChart = [];
  for (let i = 0; i < topMetricsTopGames?.sortedByTotalEntryFee.length; i++) {
    const totalEntryFeeData =
      topMetricsTopGames?.sortedByTotalEntryFee[i].totalEntryFee;

    const totalPlaysData =
      topMetricsTopGames?.sortedByTotalEntryFee[i].totalPlays;
    const gameIdData = topMetricsTopGames?.sortedByTotalEntryFee[i].gameId;
    const nameData = topMetricsTopGames?.sortedByTotalEntryFee[i]?.name;
    const scoringModelData =
      topMetricsTopGames?.sortedByTotalEntryFee[i]?.scoringModel;
    const newObj = {
      totalPlays: totalPlaysData,
      totalEntryFee: +(
        +formatUnits(totalEntryFeeData, 18) * _tokenTopMetricsTopGames
      ).toFixed(2),
      gameId: gameIdData,
      name: nameData,
      scoringModel: scoringModelData,
    };

    topGamesSpendChart.push(newObj);
  }

  return (
    <HomepageTemplate>
      <AdminStatsWrapper>
        <AdminStatsContentWrapper
          style={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <TimePickerWrapper
            value={selectedRange}
            onChange={handleRangeChange}
            format="DD/MM/YYYY"
          />
          <SelectDaysFilter
            value={selectedDayFilter}
            onChange={handleDayFilterSelect}
          >
            {Object.values(AdminStatisticDayFilterType).map((value, index) => (
              <Option key={index} value={value}>
                {value}
              </Option>
            ))}
          </SelectDaysFilter>
        </AdminStatsContentWrapper>
        <AdminStatsContentWrapper>
          <AdminStatsContentTitle>Total Metrics</AdminStatsContentTitle>
          <AdminStatsContentBlock>
            <AdminStatsContentBlockItem
              style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            >
              <AdminStatsContentItemTitle>
                Total Sign Ups
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {totalMetricsSummary?.totalSignUps.toLocaleString() ?? 0}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>
            <AdminStatsContentBlockItem
              style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            >
              <AdminStatsContentItemTitle>
                Total Unique Players
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {totalMetricsSummary?.totalUniquePlayers.toLocaleString() ?? 0}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>
            <AdminStatsContentBlockItem
              style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            >
              <AdminStatsContentItemTitle>
                Total Plays
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {totalMetricsSummary?.totalPlays.toLocaleString() ?? 0}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>

            <AdminStatsContentBlockItem
              style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            >
              <AdminStatsContentItemTitle>
                Average Amount Of Play Per Player
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {averageAmountOfPlayPerUser.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                }) ?? 0}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>

            <AdminStatsContentBlockItem>
              <AdminStatsContentItemTitle>
                Total Revenue Generated
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {totalRevenueGenerated.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                }) + ' USD'}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <Spin spinning={loadingPlatformRevenue}>
                  <ResponsiveContainer height={350}>
                    <PieChart>
                      <Pie
                        data={totalRevenueGeneratedChart}
                        outerRadius={80}
                        dataKey="value"
                        label
                      >
                        {totalRevenueGeneratedChart.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Legend
                        payload={totalRevenueGeneratedChart.map(
                          (entry, index) => ({
                            value:
                              entry.name
                                .split(/(?=[A-Z])/)
                                .join(' ')
                                .toLowerCase()
                                .replace(/^\w/, c => c.toUpperCase()) +
                              ': ' +
                              entry.value +
                              ' USD',
                            color: COLORS[index % COLORS.length],
                          }),
                        )}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Spin>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}

            <AdminStatsContentBlockItem>
              <AdminStatsContentItemTitle>
                Breakdown Via Scoring Model
              </AdminStatsContentItemTitle>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <Spin spinning={loadingScoringModelRevenue}>
                  <ResponsiveContainer height={350}>
                    <PieChart>
                      <Pie
                        data={scoringModelChart}
                        outerRadius={80}
                        dataKey="amount"
                        label
                      >
                        {scoringModelChart.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Legend
                        payload={scoringModelChart.map((entry, index) => ({
                          value:
                            entry.scoringModel
                              .split(/(?=[A-Z])/)
                              .join(' ')
                              .toLowerCase()
                              .replace(/^\w/, c => c.toUpperCase()) +
                            ': ' +
                            entry.amount +
                            ' USD',
                          color: COLORS[index % COLORS.length],
                        }))}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Spin>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}
          </AdminStatsContentBlock>
        </AdminStatsContentWrapper>

        <AdminStatsContentWrapper>
          <AdminStatsContentTitle>Total Affiliate</AdminStatsContentTitle>
          <AdminStatsContentBlock>
            <AdminStatsContentBlockItem
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                marginBottom: '1rem',
              }}
            >
              <AdminStatsContentItemTitle>
                Total Affiliate Sign Up
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {totalAffiliate?.totalSignUps?.toLocaleString() ?? 0}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <ResponsiveContainer height={350}>
                  <BarChart data={topAffiliateSignUp} layout="vertical">
                    <XAxis
                      type="number"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="username"
                      type="category"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <Tooltip
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const { username, avatarURL, totalSignUp } =
                            payload[0].payload;
                          return (
                            <ToolTipChartWrapper>
                              <ToolTipProfile>
                                <ToolTipProfileAvatar
                                  src={avatarURL || defaultAvatarImg}
                                />
                                <ToolTipProfileName>
                                  {username}
                                </ToolTipProfileName>
                              </ToolTipProfile>
                              <ToolTipText>
                                <b>Total Sign up:</b> {totalSignUp}
                              </ToolTipText>
                            </ToolTipChartWrapper>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalSignUp" fill="#68b7a9" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}

            <AdminStatsContentBlockItem
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                marginBottom: '1rem',
              }}
            >
              <AdminStatsContentItemTitle>
                Total Affiliate Revenue
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {totalAffiliate?.totalRevenue?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                }) + ' USD'}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>

            {selectedRange ? (
              <ChartWrapper>
                <ResponsiveContainer height={350}>
                  <BarChart data={topAffiliateRevenue} layout="vertical">
                    <XAxis
                      type="number"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="username"
                      type="category"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <Tooltip
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const { username, avatarURL, totalRevenue } =
                            payload[0].payload;
                          return (
                            <ToolTipChartWrapper>
                              <ToolTipProfile>
                                <ToolTipProfileAvatar
                                  src={avatarURL || defaultAvatarImg}
                                />
                                <ToolTipProfileName>
                                  {username}
                                </ToolTipProfileName>
                              </ToolTipProfile>
                              <ToolTipText>
                                <b>Total Revenue: </b>
                                {totalRevenue?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 0,
                                }) + ' USD'}
                              </ToolTipText>
                            </ToolTipChartWrapper>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalRevenue" fill="#68b7a9" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}
          </AdminStatsContentBlock>
        </AdminStatsContentWrapper>

        <AdminStatsContentWrapper>
          <AdminStatsContentTitle>
            Average and Top Metrics
          </AdminStatsContentTitle>
          <AdminStatsContentBlock>
            <AdminStatsContentBlockItem
              style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
            >
              <AdminStatsContentItemTitle>
                Average User Spend
              </AdminStatsContentItemTitle>
              <AdminStatsContentItemValue>
                {(
                  +formatUnits(
                    averageMetricsAverageUserSpent?.amount || 0,
                    averageMetricsAverageUserSpent?.decimals || 18,
                  ) * _tokenAverageMetricsAverageUserSpent
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                }) + ' USD'}
              </AdminStatsContentItemValue>
            </AdminStatsContentBlockItem>

            <AdminStatsContentBlockItem>
              <AdminStatsContentItemTitle>
                Top Users Spend
              </AdminStatsContentItemTitle>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <ResponsiveContainer height={350}>
                  <BarChart data={topUsersSpendChart} layout="vertical">
                    <XAxis
                      type="number"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="username"
                      type="category"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <Tooltip
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const { username, avatarURL, totalEntryFee } =
                            payload[0].payload;
                          return (
                            <ToolTipChartWrapper>
                              <ToolTipProfile>
                                <ToolTipProfileAvatar
                                  src={avatarURL || defaultAvatarImg}
                                />
                                <ToolTipProfileName>
                                  {username}
                                </ToolTipProfileName>
                              </ToolTipProfile>
                              <ToolTipText>
                                <b>Total Spends:</b> {totalEntryFee} USD
                              </ToolTipText>
                            </ToolTipChartWrapper>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalEntryFee" fill="#68b7a9" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}

            <AdminStatsContentBlockItem>
              <AdminStatsContentItemTitle>
                Top Users Play
              </AdminStatsContentItemTitle>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <ResponsiveContainer height={350}>
                  <BarChart
                    data={topMetricsTopUsers?.sortedByTotalPlays}
                    layout="vertical"
                  >
                    <XAxis
                      type="number"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="username"
                      type="category"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <Tooltip
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const { username, avatarURL, totalPlays } =
                            payload[0].payload;
                          return (
                            <ToolTipChartWrapper>
                              <ToolTipProfile>
                                <ToolTipProfileAvatar
                                  src={avatarURL || defaultAvatarImg}
                                />
                                <ToolTipProfileName>
                                  {username}
                                </ToolTipProfileName>
                              </ToolTipProfile>
                              <ToolTipText>
                                <b>Total Plays:</b> {totalPlays}
                              </ToolTipText>
                            </ToolTipChartWrapper>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalPlays" fill="#68b7a9" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}

            <AdminStatsContentBlockItem>
              <AdminStatsContentItemTitle>
                Top Games Spend
              </AdminStatsContentItemTitle>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <ResponsiveContainer height={350}>
                  <BarChart data={topGamesSpendChart} layout="vertical">
                    <XAxis
                      type="number"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="name"
                      type="category"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <Tooltip
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const { name, totalEntryFee, scoringModel } =
                            payload[0].payload;
                          return (
                            <ToolTipChartWrapper>
                              <ToolTipText>
                                <b>Game: </b>
                                {name}
                              </ToolTipText>
                              <ToolTipText>
                                <b>Total Spends: </b>
                                {totalEntryFee} USD
                              </ToolTipText>
                              <ToolTipText>
                                <b>Scoring Model: </b>
                                {scoringModel}
                              </ToolTipText>
                            </ToolTipChartWrapper>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalEntryFee" fill="#68b7a9" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}

            <AdminStatsContentBlockItem>
              <AdminStatsContentItemTitle>
                Top Games Played
              </AdminStatsContentItemTitle>
            </AdminStatsContentBlockItem>
            {selectedRange ? (
              <ChartWrapper>
                <ResponsiveContainer height={350}>
                  <BarChart
                    data={topMetricsTopGames?.sortedByTotalPlays}
                    layout="vertical"
                  >
                    <XAxis
                      type="number"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="name"
                      type="category"
                      tick={{ fill: '#ffffff' }}
                      axisLine={{ stroke: '#fff' }}
                      tickLine={false}
                    />
                    <Tooltip
                      content={({ payload }) => {
                        if (payload && payload.length) {
                          const { name, totalPlays, scoringModel } =
                            payload[0].payload;
                          return (
                            <ToolTipChartWrapper>
                              <ToolTipText>
                                <b>Game: </b>
                                {name}
                              </ToolTipText>
                              <ToolTipText>
                                <b>Total Plays: </b>
                                {totalPlays}
                              </ToolTipText>
                              <ToolTipText>
                                <b>Scoring Model: </b>
                                {scoringModel}
                              </ToolTipText>
                            </ToolTipChartWrapper>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalPlays" fill="#68b7a9" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            ) : (
              <EmptyWrapper />
            )}
          </AdminStatsContentBlock>
        </AdminStatsContentWrapper>
      </AdminStatsWrapper>
    </HomepageTemplate>
  );
};
