import { Button, Form, Input, Modal } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  border-radius: 10px;
  height: 285px;
  top: calc(calc(100% - 400px) / 2);
  .ant-modal-content {
    background: #fff;
    height: 285px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #23c879 1px solid;
    .ant-modal-body {
      border-radius: 50%;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperImage = styled.img`
  width: 108px;
  height: 108px;
`;

export const WrapperText = styled.p`
  margin-top: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000;
  padding: 0 10px;
`;

export const ThemedModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-content {
    border-radius: 0.8rem;
    background: #fff;
    border: 1px solid white;
    overflow: hidden;
    padding: 20px 10px;
    max-height: 80vh;
    width: 85vw;
    overflow-y: auto;
  }
  .ant-modal-header {
    background: transparent;
    border: none;
    padding: 0 24px 16px 0;
  }

  .ant-modal-title {
    color: black;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 25px;
    }
  }

  .ant-row {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 800px) {
    .ant-modal-content {
      overflow-y: auto;
    }
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 24px 0 0;

    .ant-btn-default {
      :hover,
      :focus {
        color: #0b3885;
        border-color: #0b3885;
      }
    }
    .ant-btn-primary {
      background: #0b3885;
      border: none;

      :disabled,
      :disabled:hover {
        color: #fff;
        opacity: 0.6;
      }
    }
  }
`;

export const AddThemedModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-content {
    border-radius: 0.8rem;
    background: #fff;
    border: 1px solid white;
    overflow: hidden;
    padding: 20px 10px;
    max-height: 80vh;
    width: 85vw;
    overflow-y: auto;
  }
  .ant-modal-header {
    background: transparent;
    border: none;
    padding: 0 24px 16px 0;
  }

  .ant-modal-title {
    color: black;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 25px;
    }
  }

  .ant-row {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 800px) {
    .ant-modal-content {
      overflow-y: auto;
    }
  }

  .ant-modal-footer {
    .ant-btn-default {
      :hover,
      :focus {
        color: #0b3885;
        border-color: #0b3885;
      }
    }
    .ant-btn-primary {
      background: #0b3885;
      border: none;
    }
  }
`;

export const DeleteThemedModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-content {
    border-radius: 0.8rem;
    background: #fff;
    border: 1px solid white;
    overflow: hidden;
    padding: 20px 10px;
    max-height: 80vh;
    width: 85vw;
    overflow-y: auto;
  }
  .ant-modal-header {
    background: transparent;
    border: none;
    padding: 0 24px 16px 0;
  }

  .ant-modal-title {
    color: black;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 25px;
    }
  }

  .ant-row {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 800px) {
    .ant-modal-content {
      overflow-y: auto;
    }
  }

  .ant-modal-footer {
    .ant-btn-default {
      :hover,
      :focus {
        color: #0b3885;
        border-color: #0b3885;
      }
    }
    .ant-btn-primary {
      background: #ff525b;
      border: none;
    }
  }

  p {
    text-align: justify;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const SubmitButton = styled(Button)<{ height?: string; width?: string }>`
  color: white;
  border: none;
  border-radius: 6.5px;
  font-size: 17px;
  font-weight: 600;
  height: ${props => (props.height ? props.height : '50px')};
  width: ${props => (props.width ? props.width : '250px')};
  background-color: #0b3885;
  margin-top: 20px;

  :hover,
  :focus,
  :active {
    color: white;
    background-color: #0b3885;
    transform: scale(1.05);
  }

  :active {
    transform: scale(0.9);
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    background-color: #0b3885;
    opacity: 0.6;
    background-image: none !important;
    color: white;
  }
`;

export const StyledForm = styled(Form)<{
  labelcolor?: string;
  $labelweight?: string;
}>`
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 10px;
  }

  .ant-form-item-label > label {
    color: ${({ labelcolor }) => labelcolor || '#000'};
    font-size: 14px;
    font-weight: ${({ $labelweight: labelweight }) => labelweight || '400'};
    line-height: 20px;
  }

  .ant-form-item-explain-error {
    color: red;
    display: flex;
    margin-top: 3px;
  }

  @media (min-width: 1400px), (min-width: 1600px) {
    .ant-form-item-label > label {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    .ant-form .ant-form-item .ant-form-item-control {
      flex: unset;
    }
  }
`;

export const TextInputField = styled(Input)`
  padding: 8px;
  border: none;
  color: #0b3885;
  width: 100%;
  background-color: rgba(11, 56, 133, 0.17);
  font-weight: 600;
  font-size: 16px;

  input {
    border: none;
    color: #0b3885;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
  }

  ::placeholder {
    color: #6b8ab8;
    font-weight: 500;
  }

  :focus {
    outline: none;
  }
`;
