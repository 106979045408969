import { Modal } from 'antd';
import styled from 'styled-components';
import { media } from 'constants/enum/breakpoints';

export const ModalContainer = styled.div``;

export const StyledModal = styled(Modal)`
  max-width: 520px;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  .ant-modal-content {
    background: linear-gradient(#0f0f13, #161923);
    border-radius: 24px;
    background-repeat: no-repeat;
    padding: 0 20px;
  }

  .ant-modal-close {
    top: 5px;
    right: 5px;
  }

  .ant-modal-close-x {
    svg {
      fill: #fff;
    }
  }

  ${media.sm} {
    max-width: 337px;

    .ant-modal-content {
      padding: 0;
    }
  }
`;

export const BackgroundGradient = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  z-index: 100;
`;
