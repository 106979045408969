import { Col, Dropdown, Row, Spin } from 'antd';
import arrowDownIcon from 'assets/vectors/logo-game-arrow-down.svg';
import { GameDetailDrawer } from 'components/Games/GameDetailDrawer';
import { GameItem } from 'components/Games/GameItem';
import { IGameActive, useGetGameActive } from 'hooks/games/useGetGameActive';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import HomepageTemplate from 'template/Homepage';
import { everflowConversion } from 'utils/everflow';
import useWindowSize from 'hooks/useWindowSize';

import {
  DropdownIcon,
  DropdownTitle,
  DropdownTitleWrapper,
  FilterText,
  FilterWrapper,
  GameStyledCol,
  GamesWrapper,
  GameWrapper,
  MenuItemWrapper,
  MenuWrapper,
  TitleStyled,
  TitleWrapper,
  TitleLogo,
  TagStyled,
} from './styled';
import { GameDetailDrawerMobile } from 'components/Games/GameDetailDrawerMobile';

export const GamesPage = () => {
  const { state } = useLocation();
  const menu = (
    <MenuWrapper>
      <MenuItemWrapper>All</MenuItemWrapper>
      <MenuItemWrapper>New Release</MenuItemWrapper>
      <MenuItemWrapper>Coming soon</MenuItemWrapper>
      <MenuItemWrapper>Alphabetical</MenuItemWrapper>
      <MenuItemWrapper>Price: High to Low</MenuItemWrapper>
      <MenuItemWrapper>Price: Low to High</MenuItemWrapper>
    </MenuWrapper>
  );
  const [params] = useState({
    pageIndex: 1,
    pageSize: 30,
    showStatistic: true,
  });

  const { gameActive, loading } = useGetGameActive(params);

  const toggleDrawer = useToggleModal(ApplicationModal.GAME_DETAIL_DRAWER);
  const [gameDetail, setGameDetail] = useState<IGameActive>();

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { width } = useWindowSize();

  const showDetail = (index: number) => {
    setGameDetail(filteredGames?.at(index));
    toggleDrawer();
  };

  const handleTagClick = (tag: string) => {
    if (tag === 'All') {
      setSelectedTags([]);
    } else {
      setSelectedTags(prevTags =>
        prevTags.includes(tag)
          ? prevTags.filter(t => t !== tag)
          : [...prevTags, tag],
      );
    }
  };

  const filteredGames =
    selectedTags.length === 0
      ? gameActive
      : gameActive?.filter(game =>
          selectedTags.every(tag => game.tags.includes(tag)),
        );

  // Extract unique tags from the games
  const uniqueTags = useMemo(() => {
    const tags = new Set<string>();
    gameActive?.forEach(game => {
      game.tags.forEach(tag => tags.add(tag));
    });
    return ['All', ...Array.from(tags)];
  }, [gameActive]);

  const filterWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (width <= 767) setShowMobileMenu(true);
    else setShowMobileMenu(false);
  }, [width, setShowMobileMenu]);

  useEffect(() => {
    if (state?.isConversion)
      (async () => {
        await everflowConversion();
      })();
  }, [state]);

  useEffect(() => {
    const filterWrapper = filterWrapperRef.current;
    if (filterWrapper) {
      const handleWheel = (event: WheelEvent) => {
        if (event.deltaY !== 0) {
          event.preventDefault();
          filterWrapper.scrollLeft += event.deltaY;
        }
      };
      filterWrapper.addEventListener('wheel', handleWheel);
      return () => {
        filterWrapper.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  return (
    <HomepageTemplate>
      <GamesWrapper>
        <TitleWrapper>
          <TitleStyled>
            <TitleLogo />
            Games
          </TitleStyled>

          <FilterWrapper ref={filterWrapperRef}>
            {uniqueTags.map(tag => (
              <TagStyled
                key={tag}
                color={
                  selectedTags.includes(tag) ||
                  (tag === 'All' && selectedTags.length === 0)
                    ? 'blue'
                    : 'default'
                }
                onClick={() => handleTagClick(tag)}
              >
                {tag}
              </TagStyled>
            ))}
          </FilterWrapper>
        </TitleWrapper>
        <Spin spinning={loading}>
          <GameWrapper>
            <Row gutter={showMobileMenu ? [14, 14] : [20, 20]} align="bottom">
              {filteredGames?.map((item, index) => (
                <GameStyledCol
                  xl={6}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}
                  key={index}
                >
                  <GameItem {...item} showDetail={() => showDetail(index)} />
                </GameStyledCol>
              ))}
            </Row>
          </GameWrapper>
        </Spin>

        {showMobileMenu ? (
          <GameDetailDrawerMobile
            gameDetail={gameDetail}
            selectedTags={selectedTags}
          />
        ) : (
          <GameDetailDrawer
            gameDetail={gameDetail}
            selectedTags={selectedTags}
          />
        )}
      </GamesWrapper>
    </HomepageTemplate>
  );
};
