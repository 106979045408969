export enum StakingMonths {
  M_24 = '24M',
  M_12 = '12M',
  M_6 = '6M',
}

export enum StakingTabs {
  STAKE = 'Stake',
  HOW_TO_EARN_USDT = 'How to earn USDT',
}
