import { Web3Provider } from '@ethersproject/providers';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { loginWallet, logout } from 'redux/slices/user';
import { LocalhostStorage } from 'utils/sessionStorage';
import { injected, walletconnect } from 'utils/wallets/connector';
import { setupNetwork } from 'utils/wallets/setupNetwork';

export const defaultConnectedWalletStatus = {
  Injected: false,
  WalletConnect: false,
  WalletLink: false,
  Bitkeep: false,
};

function useConnectors() {
  const { activate, deactivate } = useWeb3React<Web3Provider>();
  const dispatch = useDispatch();

  const disconnect = async () => {
    try {
      deactivate();
      const chainId = LocalhostStorage.get('chainId');
      // LocalhostStorage.clear();
      LocalhostStorage.remove('isConnected');
      LocalhostStorage.remove('account');
      LocalhostStorage.remove('chainId');
      LocalhostStorage.remove('typeOfConnector');
      LocalhostStorage.remove('path');
      LocalhostStorage.set('chainId', +chainId);
      dispatch(logout());
    } catch (error) {
      console.log({ error });
    }
  };

  const noMetamaskInstalled = useMemo(() => {
    if (!window?.ethereum) return true;

    return !window.ethereum && !window.ethereum.isMetaMask;
  }, []);

  const CHAIN_ID = LocalhostStorage.get('chainId');
  useEffect(() => {
    if (!CHAIN_ID)
      LocalhostStorage.set('chainId', +process.env.REACT_APP_CHAIN_ID);
  }, [CHAIN_ID]);

  const onConnectWallet = async (
    otherConnector: InjectedConnector | WalletConnectConnector,
  ) => {
    try {
      if (noMetamaskInstalled) {
        window.open('https://metamask.io/');
        return;
      }
      await activate(
        otherConnector,
        async (err: Error) => {
          console.log('3', err);
          if (err instanceof UnsupportedChainIdError) {
            const provider = await otherConnector.getProvider();

            const hasSetup = await setupNetwork(provider);
            if (hasSetup) {
              activate(otherConnector);
            }
          } else {
            if (err instanceof NoEthereumProviderError) {
              toast.warning('Provider Error: No provider was found');
            } else if (
              err instanceof UserRejectedRequestErrorInjected ||
              err instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (otherConnector instanceof WalletConnectConnector) {
                const walletConnector =
                  otherConnector as WalletConnectConnector;
                walletConnector.walletConnectProvider = null;
              }
              toast.warning(
                'Authorization Error: Please authorize to access your account',
              );
            } else {
              if (
                new RegExp('already processing eth_requestAccounts', 'i').test(
                  err?.message,
                )
              ) {
                toast.warning(
                  'Metamask is opening, please check your Metamask wallet',
                );
              } else {
                toast.warning(err.name + ':' + err.message);
              }
            }
          }
        },
        false,
      );
      const addressWallet = await otherConnector.getAccount();
      if (!addressWallet) return;
      LocalhostStorage.set('account', addressWallet);
      LocalhostStorage.set('isConnected', 'true');
      dispatch(loginWallet(addressWallet));

      if (otherConnector instanceof InjectedConnector) {
        LocalhostStorage.set('typeOfConnector', 'Metamask');
      }
      if (otherConnector instanceof WalletConnectConnector) {
        LocalhostStorage.set('typeOfConnector', 'WalletConnect');
      }
    } catch (e) {
      console.log({ e });
      deactivate();
    }
  };

  const onConnectMetamaskWallet = async () => {
    const chainId = LocalhostStorage.get('chainId');
    try {
      await onConnectWallet(
        injected(chainId || process.env.REACT_APP_CHAIN_ID),
      );
    } catch (error) {
      console.log({ error });
    }
  };
  const onWalletConnect = async () => {
    try {
      // const walletconnect = new WalletConnectConnector({
      //   rpc: {
      //     11155111:
      //       'https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      //   },
      //   chainId: 11155111,
      //   bridge: 'https://bridge.walletconnect.org',
      //   qrcode: true,
      // });
      await onConnectWallet(walletconnect);
    } catch (e) {
      console.log(e);
    }
  };
  return {
    onConnectMetamaskWallet,
    onWalletConnect,
    disconnect,
    onConnectWallet,
  };
}

export default useConnectors;
