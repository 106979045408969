import { createSlice } from '@reduxjs/toolkit';

interface IConnect {
  isVisible: boolean;
}

const initialState: IConnect = {
  isVisible: false,
};

const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    show: state => {
      state.isVisible = true;
    },
    hide: state => {
      state.isVisible = false;
    },
    handleClick: state => {
      state.isVisible = !state.isVisible;
    },
  },
});

export const { show, hide, handleClick } = connectSlice.actions;

export default connectSlice.reducer;
