import { useEffect, useState } from 'react';
import { getListLevelFee } from 'services/api';

export interface ILevelFee {
  gameId: string;
  level: string;
  entryFee: string;
  levelName: string;
}

export const useGetListLevelFee = (params, refresh: boolean) => {
  const [listLevel, setListLevel] = useState<ILevelFee[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getListLevelFee(params);
      if (res?.success) {
        setListLevel(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { listLevel, total, loading };
};
