import { MailOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import backImg from 'assets/vectors/login-keyboard-backspace.svg';
import { PitRouter } from 'constants/routers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendMailResetPassword } from 'services/api';

import {
  CustomFormItem,
  ForgotBackButton,
  ForgotFirstStepButtonWrapper,
  ForgotStepButton,
  ForgotStepButtonWrapper,
  ForgotStepDescription,
  ForgotStepTitle,
  ForgotSuccessContainer,
  FormWrapper,
  InputStyle,
  SuccessDetailText,
  SuccessText,
  Wrapper,
} from './styled';

export const EnterEmailForm = () => {
  const navigate = useNavigate();

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [isSendMail, setIsSendMail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const verifySendEmail: any = await sendMailResetPassword({
        emailAddress,
      });
      if (verifySendEmail?.success) {
        setIsSendMail(true);
      } else {
        toast.error(verifySendEmail?.message || 'Email not exist!');
      }
    } catch (e) {
      toast.error(e.reason || 'Email not exist!');
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onBackLogin = () => {
    navigate(PitRouter.LOGIN);
  };

  return (
    <Wrapper>
      <ForgotBackButton onClick={onBackLogin}>
        <img src={backImg} alt="back" />
        <span>Back</span>
      </ForgotBackButton>
      {!isSendMail ? (
        <>
          <ForgotStepTitle>Reset password</ForgotStepTitle>
          <ForgotStepDescription>
            Enter the email associated with your account and well send an email
            with instructions to reset your password.
          </ForgotStepDescription>
          <ForgotFirstStepButtonWrapper>
            <FormWrapper>
              <Form
                className="enter-email-form"
                onFinish={handleSubmit}
                autoComplete="off"
                disabled={loading}
              >
                <CustomFormItem
                  name="email"
                  rules={[
                    {
                      pattern: new RegExp(
                        '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
                      ),
                      message: 'Invalid email',
                    },
                  ]}
                >
                  <InputStyle
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    required
                    placeholder="Email address"
                    onKeyDown={e => e.keyCode === 32 && e.preventDefault()}
                  />
                </CustomFormItem>
                <ForgotStepButtonWrapper>
                  <ForgotStepButton htmlType="submit" loading={loading}>
                    Send Instructions
                  </ForgotStepButton>
                </ForgotStepButtonWrapper>
              </Form>
            </FormWrapper>
          </ForgotFirstStepButtonWrapper>
        </>
      ) : (
        <ForgotSuccessContainer>
          <MailOutlined twoToneColor="#0aa74e" className="email-icon" />
          <SuccessText>Check your mail</SuccessText>
          <SuccessDetailText>
            We have send a password recover instructions to your email.
          </SuccessDetailText>
        </ForgotSuccessContainer>
      )}
    </Wrapper>
  );
};
