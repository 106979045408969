import { useEffect, useState } from 'react';
import { getRecommendedGameList } from 'services/api';

export interface IRecommendedGame {
  gameId?: string;
  scoringModel?: string;
  name?: string;
  description?: string;
  backgroundImgUrl?: string;
  backgroundColor?: string;
  logoColor?: string;
  tags?: string[];
  tokenAddress?: string;
  tokenInfo?: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
  };

  // only showStatistic = true
  totalAttempts?: number;
  totalPlayers?: number;
  totalWalletValue?: string;
  totalRevenue?: number;
}

export const useGetRecommendedGame = (gameId: string) => {
  const [recommendedGameList, setRecommendedGameList] =
    useState<IRecommendedGame[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getRecommendedGameList(gameId);
      if (res?.success) {
        setRecommendedGameList(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [gameId]);
  return { recommendedGameList, loading };
};
