import { List, Select, Spin, Tooltip } from 'antd';
import LeaderboardTable from 'components/Leaderboard';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import {
  getScoringModels,
  ScoringModelLeaderboardTypes,
} from 'constants/enum/ScoringModel';
import { IGameActive } from 'hooks/games/useGetGameActive';
import { useGetGameActiveForScroll } from 'hooks/games/useGetGameActiveForScroll';
import { useGetGameLevelDetail } from 'hooks/leaderboard/useGetGameLevelDetail';
import { useGetGameStatistic } from 'hooks/leaderboard/useGetGameStatic';
import { useGetListLevel } from 'hooks/leaderboard/useGetListLevel';
import { useCallback, useEffect, useState } from 'react';
import { removeBackground, setBackground } from 'redux/slices/template';
import { useAppDispatch } from 'redux/store';
import HomepageTemplate from 'template/Homepage';
import { formatNumberThousand } from 'utils/formatNumber';
import { ReactComponent as LeaderboardIcon } from 'assets/vectors/logo-navbar-leaderboard.svg';
import gradient from 'assets/images/leaderboard/gradient.svg';
import {
  ButtonText,
  ButtonWrapper,
  DetailLabelWrapper,
  DetailValueWrapper,
  DetailWrapper,
  GameWrapper,
  GeneralButtonWrapper,
  InformationWrapper,
  LeaderBoardWrapper,
  LeftWrapper,
  ListGameWrapper,
  RightWrapper,
  SelectMultiLevel,
  TitlePageWrapper,
  LeaderboardIconWrapper,
  ListGameContainer,
  GradientBottom,
  OptionItem,
  FullGameContainer,
  StyledDivider,
  DividerWrapper,
} from './styled';

const { Option } = Select;

export const LeaderBoard = () => {
  const [tab, setTab] = useState(GameScoringModelType.level);
  const [gameParams, setGameParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    scoringModelType: GameScoringModelType.level,
    notShowTestGames: true,
  });

  const [levelGameParams, setLevelGameParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    scoringModelType: GameScoringModelType.score,
    notShowTestGames: true,
  });

  const {
    gameActive,
    loading: listGameLoading,
    total: listGameTotal,
  } = useGetGameActiveForScroll(gameParams);

  const {
    gameActive: levelGames,
    loading: levelGamesLoading,
    total: levelGameTotal,
  } = useGetGameActiveForScroll(levelGameParams);

  const [selectedLevel, setSelectedLevel] = useState<number>();

  const [selectedGameId, setSelectedGameId] = useState('');
  const [gameInfo, setGameInfo] = useState<IGameActive>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeBackground());
    };
  }, []);

  useEffect(() => {
    if (gameActive && gameActive.length > 0 && !selectedGameId) {
      const firstGame = gameActive[0];
      setSelectedGameId(firstGame.gameId);
      setGameInfo(firstGame);
      // dispatch(setBackground(firstGame.backgroundImgUrl));
    }
  }, [gameActive, selectedGameId, dispatch]);

  useEffect(() => {
    if (gameActive && gameActive.length > 0) {
      const scoreModule = getScoringModels(gameActive[0].scoringModel);
      if (
        (tab == GameScoringModelType.score &&
          scoreModule == ScoringModelLeaderboardTypes.SCORE_BASED) ||
        (tab == GameScoringModelType.level &&
          scoreModule == ScoringModelLeaderboardTypes.LEVEL_BASED)
      ) {
        const firstGame = gameActive[0];
        setSelectedGameId(firstGame.gameId);
        setGameInfo(firstGame);
        // dispatch(setBackground(firstGame.backgroundImgUrl));
      }
    }
  }, [tab, gameActive, dispatch]);

  const { gameLevelDetail } = useGetGameLevelDetail(selectedGameId);

  const { listLevel, loading } = useGetListLevel(selectedGameId, tab);

  const [listLv, setListLv] = useState<number[]>();

  useEffect(() => {
    if (listLevel) {
      const listLevelNumber = listLevel.map(lv => +lv);
      setListLv(listLevelNumber);
      setSelectedLevel(1);
    }
  }, [listLevel]);

  const handleLevelSelect = selected => {
    setSelectedLevel(selected);
  };

  const { gameStatistic } = useGetGameStatistic(
    selectedGameId,
    selectedLevel?.toString(),
    tab == GameScoringModelType.score,
  );

  const hhandleGameListScroll = useCallback(
    e => {
      if (
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
          e.currentTarget.scrollHeight &&
        !levelGamesLoading &&
        levelGames?.length < levelGameTotal
      ) {
        setLevelGameParams({
          ...levelGameParams,
          pageIndex: levelGameParams.pageIndex + 1,
        });
      }
    },
    [levelGameParams, levelGamesLoading, levelGameTotal, levelGames],
  );

  const handleGameListScroll = useCallback(
    e => {
      if (
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
          e.currentTarget.scrollHeight &&
        !listGameLoading &&
        gameActive?.length < listGameTotal
      ) {
        setGameParams({
          ...gameParams,
          pageIndex: gameParams.pageIndex + 1,
        });
      }
    },
    [gameParams, listGameLoading, listGameTotal, gameActive],
  );

  return (
    <HomepageTemplate>
      <LeaderBoardWrapper>
        <TitlePageWrapper>
          <LeaderboardIconWrapper>
            <LeaderboardIcon />
          </LeaderboardIconWrapper>
          Leaderboard
        </TitlePageWrapper>
        <LeftWrapper>
          <FullGameContainer>
            <ListGameContainer>
              <ButtonWrapper
                active={tab === GameScoringModelType.level}
                // onClick={() => {
                //   setGameParams({
                //     ...gameParams,
                //     pageIndex: 1,
                //     scoringModelType: GameScoringModelType.level,
                //   });
                //   setTab(GameScoringModelType.level);
                // }}
              >
                <ButtonText active={tab === GameScoringModelType.level}>
                  Level
                </ButtonText>
              </ButtonWrapper>
              <ListGameWrapper onScroll={handleGameListScroll}>
                <Spin spinning={listGameLoading}>
                  <List
                    dataSource={gameActive}
                    renderItem={item => (
                      <List.Item
                        onClick={() => {
                          setTab(GameScoringModelType.level);
                          setSelectedGameId(item.gameId);
                          setGameInfo(item);
                          // dispatch(setBackground(item.backgroundImgUrl));
                        }}
                      >
                        <Tooltip title={item.name} placement="top">
                          <GameWrapper
                            src={item.backgroundImgUrl}
                            selected={item.gameId === selectedGameId}
                            alt="Image"
                          />
                        </Tooltip>
                      </List.Item>
                    )}
                  />
                </Spin>
              </ListGameWrapper>
            </ListGameContainer>
            <DividerWrapper>
              <StyledDivider type="vertical" />
            </DividerWrapper>
            <ListGameContainer>
              <ButtonWrapper
                active={tab === GameScoringModelType.score}
                // onClick={() => {
                //   setTab(GameScoringModelType.score);
                //   setGameParams({
                //     ...gameParams,
                //     pageIndex: 1,
                //     scoringModelType: GameScoringModelType.score,
                //   });
                // }}
              >
                <ButtonText>Score</ButtonText>
              </ButtonWrapper>
              <ListGameWrapper onScroll={hhandleGameListScroll}>
                <Spin spinning={levelGamesLoading}>
                  <List
                    dataSource={levelGames}
                    renderItem={item => (
                      <List.Item
                        onClick={() => {
                          setTab(GameScoringModelType.score);
                          setSelectedGameId(item.gameId);
                          setGameInfo(item);
                          // dispatch(setBackground(item.backgroundImgUrl));
                        }}
                      >
                        <Tooltip title={item.name} placement="top">
                          <GameWrapper
                            src={item.backgroundImgUrl}
                            selected={item.gameId === selectedGameId}
                            alt="Image"
                          />
                        </Tooltip>
                      </List.Item>
                    )}
                  />
                </Spin>
              </ListGameWrapper>
            </ListGameContainer>
          </FullGameContainer>
        </LeftWrapper>

        <RightWrapper>
          {tab === GameScoringModelType.score && (
            <SelectMultiLevel
              dropdownStyle={{
                backgroundColor: '#15151B',
                borderRadius: '0 0 8px 8px',
              }}
              value={selectedLevel}
              onChange={handleLevelSelect}
            >
              {listLv?.map(level => {
                const levelDetail = gameLevelDetail.find(
                  detail => detail?.level === level.toString(),
                );
                return (
                  <Option key={level} value={level}>
                    <OptionItem>
                      {levelDetail ? levelDetail.levelName : `Level ${level}`}
                    </OptionItem>
                  </Option>
                );
              })}
            </SelectMultiLevel>
          )}

          <LeaderboardTable
            tab={tab}
            selectedGameId={selectedGameId}
            gameInfo={gameInfo}
            selectedLevel={selectedLevel}
            minLevel={gameStatistic?.minLevel}
            isScroll={true}
          />

          <InformationWrapper>
            <DetailWrapper>
              <DetailLabelWrapper>Total unique players</DetailLabelWrapper>
              <DetailValueWrapper>
                {gameStatistic?.playerAmount?.toLocaleString() || 0}
              </DetailValueWrapper>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabelWrapper>Amount of Plays</DetailLabelWrapper>
              <DetailValueWrapper>
                {formatNumberThousand(
                  gameStatistic?.playsAmount || 0,
                ).toLocaleString()}
              </DetailValueWrapper>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabelWrapper>Amount of Revenue</DetailLabelWrapper>
              <DetailValueWrapper>
                $
                {parseFloat(gameStatistic?.totalOfUSDRevenue).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </DetailValueWrapper>
            </DetailWrapper>
          </InformationWrapper>
        </RightWrapper>
        <GradientBottom src={gradient} />
      </LeaderBoardWrapper>
    </HomepageTemplate>
  );
};
