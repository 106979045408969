import styled from 'styled-components';

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
  color: #0b3885;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0;
  margin-bottom: 20px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #6f7071;
  border: none;
  font-size: 18px;
  padding: 0;
  padding-top: 10px;
  margin-top: 0;
  position: absolute;

  :hover {
    color: black;
  }
`;

export const HeaderTitle = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 50px;
  transform: translateX(-50%);
  @media (max-width: 768px) {
    left: 20%;
    margin-top: 30px;
    transform: translateX(-5%);
  }
`;
