import { useEffect, useState } from 'react';
import { getInfluencerWhitelist } from 'services/api';

export interface IInfluencerWhitelist {
  username: string;
  avatarURL?: string;
  affiliateCode: string;
}

export const useGetInfluencerWhitelist = (params, refresh: boolean) => {
  const [affiliate, setAffiliate] = useState<IInfluencerWhitelist[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getInfluencerWhitelist(params);
      if (res?.success) {
        setAffiliate(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { affiliate, total, loading };
};
