import {
  DisconnectMetamaskModalContainer,
  Title,
  ButtonWrapper,
  NoButton,
  YesButton,
  Wrapper,
} from './styled';
import { UpdateInformationModal } from '../UpdateInformationModal';

interface IProps {
  isOpen: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}
export const DisconnectMetamask = ({ isOpen, onOk, onCancel }: IProps) => {
  return (
    <Wrapper>
      <DisconnectMetamaskModalContainer>
        <UpdateInformationModal isOpen={isOpen} onCancel={onCancel}>
          <Title>Are you sure you want to disconnect Metamask account?</Title>
          <ButtonWrapper>
            <NoButton onClick={onCancel}>No</NoButton>
            <YesButton onClick={onOk}>Yes</YesButton>
          </ButtonWrapper>
        </UpdateInformationModal>
      </DisconnectMetamaskModalContainer>
    </Wrapper>
  );
};
