import styled from 'styled-components';
import VideoCover from 'assets/main/how-to-work/video-cover.png';
import { media } from 'constants/enum/breakpoints';

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;

  * {
    color: white;
  }

  .game-list {
    display: none;
  }

  ${media.xl3} {
    .desktop {
      zoom: 0.9;
    }
  }

  ${media.xl2} {
    .desktop {
      zoom: 0.8;
    }
  }

  ${media.xl1} {
    .desktop {
      zoom: 0.7;
    }
  }

  ${media.xl} {
    .desktop {
      zoom: 0.6;
    }
  }

  ${media.lg} {
    gap: unset;
    .desktop {
      display: none;
    }

    .game-list {
      display: flex;
    }
  }
`;

export const OurGamesTitleWrapper = styled.div`
  max-width: 654px;
  margin: auto;

  ${media.lg} {
    margin-bottom: 0;
  }
`;

export const OurGamesTitle = styled.div`
  font-size: 64px;
  font-weight: 600;
  text-align: center;

  ${media.lg} {
    font-size: 50px;
  }

  ${media.md} {
    font-size: 28px;
  }
`;

export const OurGamesDescription = styled.div`
  font-size: 22px;
  text-align: center;
  line-height: 32px;

  ${media.md} {
    font-size: 16px;
  }
`;

export const GameWrapper = styled.div`
  max-height: 460px;
  /* width: 90vw; */
  max-width: 1690px;
  display: flex;
  column-gap: 30px;
  margin: auto;
  &:hover {
    .logo {
      display: none;
    }
    .play-now {
      display: block;
    }
    .small-box {
      justify-content: space-evenly;
      padding: 50px 0;
    }
    .small-box,
    .big-box {
      background-color: rgba(249, 249, 249, 0.06);
      border-bottom: 13px solid rgba(0, 0, 0, 0.2);
    }
    .thumbnail {
      scale: 1.05;
    }
  }

  &.mobile {
    all: unset;

    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const GameSmallBox = styled.div`
  width: 490px;
  height: 460px;
  border-radius: 80px;
  background-color: rgba(249, 249, 249, 0.04);
  border-bottom: 13px solid rgba(249, 249, 249, 0.001);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: all ease-in-out 0.3s;

  * {
    transition: all ease-in-out 0.3s;
  }

  .play-now {
    display: none;
  }

  .logo {
    display: block;
  }
`;

export const GameLogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 150px;
`;

export const GameLogo = styled.img`
  max-width: 293px;

  &.mobile {
    max-width: 152px;
    max-height: 100%;
  }
`;

export const GameBigBox = styled.div`
  /* flex-grow: 1; */
  /* max-width: calc(100% - 500px); */
  width: 1150px;
  height: 460px;
  border-radius: 80px;
  background-color: rgba(249, 249, 249, 0.04);
  border-bottom: 13px solid rgba(249, 249, 249, 0.001);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: all ease-in-out 0.3s;

  * {
    transition: all ease-in-out 0.3s;
  }
`;

export const GameContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-width: 443px;
  padding: 60px;

  &.mobile {
    height: max-content;
    padding: 0;
    width: 100%;
  }
`;

export const GameContentHeadingWrapper = styled.div``;

export const GameName = styled.div`
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 2px;

  &.mobile {
    font-size: 22px;
  }
`;

export const GameContentHeading = styled.div`
  display: flex;
  justify-content: space-between;
  .status {
    color: #a5fb19;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 14px;

    span {
      display: inline-block;
      font-size: 1px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #a5fb19;
    }
  }

  .players {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

export const GameContentRevenu = styled.div`
  margin-bottom: 20px;
  * {
    color: #e8e8e8;
  }

  .total-revenu {
    font-size: 36px;
    font-weight: 600;
    color: #e961ff;

    &.mobile {
      font-size: 32px;
    }
  }
`;

export const GameContentDescription = styled.div`
  color: #e8e8e8;
  font-size: 18px;

  &.mobile {
    font-size: 16px;
    height: 120px;
  }
`;

export const GameThumbnailWrapper = styled.div`
  width: 665px;
  height: 378px;
  flex-grow: 1;
  position: relative;
  margin: 60px 60px 60px 0;
  overflow: hidden;
  border-radius: 35px;
`;

export const GameThumbnailImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const GameTags = styled.div`
  position: absolute;
  display: flex;
  bottom: 15px;
  right: 15px;
  gap: 10px;

  div:first-child {
    background-color: #6b24b2;
  }

  div {
    background-color: #272727;
    padding: 10px;
    border-radius: 40px;
  }
`;
