export const PitRouter = {
  CONNECT: '/connect',
  HOME: '/',
  REGISTER: '/register',
  USER: '/user',
  BUY_ATTEMPTS: '/buy-attempts',
  FINISH_LEVEL: '/finish-level',
  TEST: '/action-test',
  POSTER: '/poster',
  LEADER_BOARD: '/leaderboard',
  DASHBOARD: '/dashboard',
  GAME_LIST: '/game-list',
  ADMIN_PANEL: '/admin-panel',
  ADMIN_STATS: '/admin-stats',
  PLAY_GAME: '/:gameName',
  MANAGEMENT: '/management',
  LOGIN: '/login',
  CASHIER: '/cashier',
  PROFILE: '/profile',
  SUPPORT: '/support',
  AFFILIATE_CODE: '/register/:affiliateCode',
  AFFILIATE: '/affiliate',
  FORGOT_PASSWORD: '/reset-password',
  MAINTENANCE: '/maintenance',
  STAKING: '/staking',
};
