import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  ButtonWrapper,
  LinkButton,
  ModalWithdrawContainer,
  ModalWithdrawContent,
  ModalWithdrawWrapper,
  ModalWrapper,
  SubmitButton,
  Text,
} from './styled';

interface IProps {
  value: any;
  handleWithdraw: () => void;
  loading: boolean;
}

export const WithdrawModal = ({ value, handleWithdraw, loading }: IProps) => {
  const open = useModalIsOpen(ApplicationModal.WITHDRAW);
  const toggleModal = useToggleModal(ApplicationModal.WITHDRAW);
  const handleCancel = () => {
    toggleModal();
  };

  return (
    <ModalWrapper isOpen={open} onCancel={handleCancel} width="520px">
      <ModalWithdrawWrapper>
        <ModalWithdrawContainer>
          <ModalWithdrawContent>
            <Text>
              <b>{value || 'xx'} BIGA</b> will be locked for proceeding
              transaction. If in case transaction is failed, your{' '}
              <b>{value || 'xx'} BIGA</b> will be unlocked after <b>05 mins.</b>
            </Text>
            <ButtonWrapper>
              <LinkButton onClick={handleCancel}>Close and go back</LinkButton>

              <SubmitButton loading={loading} onClick={handleWithdraw}>
                I understand and continue
              </SubmitButton>
            </ButtonWrapper>
          </ModalWithdrawContent>
        </ModalWithdrawContainer>
      </ModalWithdrawWrapper>
    </ModalWrapper>
  );
};
