import { formatUnits } from '@ethersproject/units';
import { isAddress } from 'ethers/lib/utils';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { TokenDetail } from 'interfaces/contracts/TokenDetail';
import { useEffect, useState } from 'react';

import useERC20Callback from './useERC20Callback';
import useStakingContractInfo from 'hooks/contract/useStakingContractInfo';

export const useGetStakingTokenInfo = (address: string, refresh?: boolean) => {
  const [tokenInfo, setTokenInfo] = useState<TokenDetail>();
  const [loading, setLoading] = useState(false);

  const { account } = useActiveWeb3React();
  const { symbol, allowance, decimals, balanceOf } = useERC20Callback(address);
  const contractInfo = useStakingContractInfo();
  const stakingContract = contractInfo?.StakingContractAddress;

  useEffect(() => {
    void (async () => {
      setLoading(true);

      if (isAddress(address?.toLowerCase())) {
        try {
          if (!stakingContract) return;
          const _symbol = await symbol();
          const _allowance = await allowance(stakingContract);
          const _decimals = await decimals();
          const _balanceOf = await balanceOf();
          setTokenInfo({
            tokenAddress: address,
            decimals: _decimals,
            symbol: _symbol,
            balanceOf: formatUnits(_balanceOf, _decimals),
            allowance: formatUnits(_allowance, _decimals),
          });
        } catch (e) {
          console.log(e.message);
        }
      } else {
        setTokenInfo(undefined);
      }
      setLoading(false);
    })();
  }, [
    refresh,
    account,
    symbol,
    allowance,
    stakingContract,
    decimals,
    balanceOf,
    address,
  ]);
  return { tokenInfo, loading };
};
