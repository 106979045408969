import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  account: null,
  isLoggedInWallet: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginWallet: (state, { payload }) => {
      state.isLoggedInWallet = true;
      state.account = payload;
    },
    logout: state => {
      state.isLoggedInWallet = false;
      state.account = null;
    },
  },
});

export const { loginWallet, logout } = userSlice.actions;

export default userSlice.reducer;
