import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: #15151b;
    border-radius: 24px;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
  .ant-modal-close-icon {
    svg {
      fill: #fff;
    }
  }
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalDeleteAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalDeleteWrapper = styled.div`
  display: flex;
  padding: 30px 56px 30px 57px;
  flex-direction: column;
  gap: 74px;
  width: 100%;
`;

export const DeleteText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const ModalDeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalConfirmDeleteTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  text-align: center;
`;

export const ModalDeleteButton = styled(Text)`
  padding: 12px 24px;
  gap: 10px;
  background: #ff1900;
  cursor: pointer;
  user-select: none;

  font-size: 18px;
  font-weight: 600;

  text-align: center;
`;

export const ModalDeleteContainer = styled.div``;

export const ModalConfirmDeleteWrapper = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 24px;
  font-weight: 600;
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  text-align: center;
`;

export const NoteWrapper = styled.ul`
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  margin: 20px 0;

  & > li {
    margin-bottom: 10px;
  }
`;

export const ConfirmDeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ConfirmDeleteYesButton = styled(Button)`
  font-size: 20px;
  border-radius: 50px;
  font-weight: 700;
  background: linear-gradient(90deg, #ff1900 -54.32%, #e50000 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(255, 0, 0, 0.24),
    0px 2px 4px -1px rgba(139, 0, 0, 0.12),
    0px 16px 24px 0px rgba(255, 0, 0, 0.24),
    0px 8px 8px -4px rgba(139, 0, 0, 0.12);
  border: none;
  color: #fff;
  display: flex;
  height: 58px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
`;

export const ConfirmDeleteNoButton = styled(Button)`
  font-size: 20px;
  border-radius: 50px;
  font-weight: 700;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border: none;
  color: #fff;
  display: flex;
  height: 58px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
`;
