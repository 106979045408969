import { useEffect, useState } from 'react';
import { getTesterList } from 'services/api';

export interface ITester {
  username: string;
  avatarURL?: string;
}

export const useGetTesters = (params, refresh: boolean) => {
  const [testers, setTesters] = useState<ITester[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTesterList(params);
      if (res?.success) {
        setTesters(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { testers, total, loading };
};
