import { Dropdown, Select } from 'antd';
import {
  CloseButton,
  DropdownHeaderWrapper,
  DropdownWrapper,
  ExpandButton,
  ExpandText,
  ExpandWrapper,
  LeaderBoardButtonWrapper,
  LeaderboardButton,
  LeaderboardButtonWrapper,
} from './styled';
import { LeaderboardTableGame } from 'components/Modals/PlayGame/LeaderboardTable';
import { SelectMultiLevelMinisize } from 'pages/Leaderboard/styled';
import LeaderboardTable from 'components/Leaderboard';
import { useEffect, useState } from 'react';
import { useToggleModal } from 'redux/slices/application/hook';
import { ApplicationModal } from 'redux/slices/application';
import { useGetGameLevelDetail } from 'hooks/leaderboard/useGetGameLevelDetail';
import { useGetListLevel } from 'hooks/leaderboard/useGetListLevel';
import { useGetGameActive } from 'hooks/games/useGetGameActive';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import { useGetGameStatistic } from 'hooks/leaderboard/useGetGameStatic';
import { ScoringModels } from 'constants/enum/ScoringModel';

const { Option } = Select;

export const LeaderBoardButton = ({ selectedGame, gameDetail, className }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [paramsLevelType] = useState({
    pageIndex: 1,
    pageSize: 30,
    scoringModelType: GameScoringModelType.level,
    showStatistic: true,
  });

  const { gameLevelDetail } = useGetGameLevelDetail(selectedGame?.gameId);
  const { gameActive: gameLeveltype } = useGetGameActive(paramsLevelType);

  const pathname = window.location.pathname;
  const gameId = pathname.substring(1);
  const isGameLevelType = gameLeveltype?.some(game => game?.gameId === gameId);
  const [selectedLevel, setSelectedLevel] = useState<number>();

  const { gameStatistic } = useGetGameStatistic(
    selectedGame?.gameId,
    selectedLevel?.toString(),
    gameDetail?.scoringModel !== ScoringModels.LEVEL_PASS,
  );

  useEffect(() => {
    if (gameStatistic?.minLevel) setSelectedLevel(gameStatistic?.minLevel);
  }, [gameStatistic?.minLevel]);

  const { listLevel } = useGetListLevel(
    selectedGame?.gameId,
    isGameLevelType ? GameScoringModelType.level : GameScoringModelType.score,
  );

  const handleLevelSelect = (selected: number) => {
    setSelectedLevel(selected);
  };

  const [levels, setLevels] = useState<number[]>();

  useEffect(() => {
    if (listLevel) {
      const listLevelNumber = listLevel.map(lv => +lv);
      setLevels(listLevelNumber);
      setSelectedLevel(1);
    }
  }, [listLevel]);

  const handleDropdownVisibleChange = visible => {
    setDropdownVisible(visible);
  };

  const handleOpemDropdown = () => {
    toggleLeaderboardTableModal();
    setDropdownVisible(true);
  };

  const handleCloseDropdown = () => {
    setDropdownVisible(false);
  };

  const toggleLeaderboardTableModal = useToggleModal(
    ApplicationModal.PLAYGAME_LEADERBOARD_TABLE,
  );

  const handleLeaderboardTableModal = () => {
    setDropdownVisible(false);
    toggleLeaderboardTableModal();
  };

  const renderLevelOptions = () => {
    return levels?.map(level => {
      const levelDetail = gameLevelDetail.find(
        detail => detail?.level === level.toString(),
      );

      return (
        <Option key={level} value={level}>
          {levelDetail?.levelName || `Level ${level}`}
        </Option>
      );
    });
  };

  const menu = (
    <DropdownWrapper>
      <DropdownHeaderWrapper>
        <ExpandWrapper onClick={handleLeaderboardTableModal}>
          <ExpandButton />
          <ExpandText>Expand window</ExpandText>
        </ExpandWrapper>
        {!isGameLevelType && (
          <SelectMultiLevelMinisize
            value={selectedLevel}
            onChange={handleLevelSelect}
          >
            {renderLevelOptions()}
          </SelectMultiLevelMinisize>
        )}
        <CloseButton onClick={handleCloseDropdown} />
      </DropdownHeaderWrapper>

      <LeaderboardTable
        tab={
          isGameLevelType
            ? GameScoringModelType.level
            : GameScoringModelType.score
        }
        isMinimized={true}
        selectedLevel={selectedLevel}
        selectedGameId={selectedGame?.gameId}
        gameInfo={selectedGame}
        minLevel={isGameLevelType ? undefined : gameStatistic?.minLevel}
        isScroll={false}
      />
    </DropdownWrapper>
  );

  return (
    <LeaderBoardButtonWrapper className={className}>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={dropdownVisible}
        onVisibleChange={handleDropdownVisibleChange}
        autoAdjustOverflow
        arrow
      >
        <LeaderboardButtonWrapper>
          <LeaderboardButton />{' '}
          <span style={{ color: '#a9a9a9', fontSize: '16px' }}>
            Leaderboard
          </span>
        </LeaderboardButtonWrapper>
      </Dropdown>
      <LeaderboardTableGame
        levelsOptions={renderLevelOptions()}
        isCheckGameLevelType={isGameLevelType}
        onMinimized={handleOpemDropdown}
        selectedLevelProp={selectedLevel}
        setSelectedLevelProp={setSelectedLevel}
        selectedGameId={selectedGame}
        minLevel={gameStatistic?.minLevel}
        maxLevel={gameStatistic?.maxLevel}
      />
    </LeaderBoardButtonWrapper>
  );
};
