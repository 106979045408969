import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const AffiliateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 56px 142px;
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  ${media.xl2} {
    padding: 56px 142px 56px 70px;
  }

  ${media.xl1} {
    padding: 56px 100px 56px 70px;
  }

  ${media.xl} {
    padding: 56px 50px 56px 40px;
  }

  ${media.xl} {
    padding: 40px;
  }

  ${media.xs} {
    padding: 40px 18px;
  }
`;

export const GradientBottom = styled.img`
  position: absolute;
  bottom: -370px;
  right: -570px;
  pointer-events: none;
  width: 1200px;

  ${media.sm} {
    display: none;
  }
`;

export const AffiliateContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  gap: 20px;

  ${media.lg} {
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
`;

export const AffiliateIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 187, 255, 0.24);
  border-radius: 50px;
  margin-top: 3px;
  flex-shrink: 0;
  svg path {
    fill: #00aafd;
  }
`;

export const AffiliateContentTitle = styled(Text)`
  gap: 12px;
  display: flex;
  width: 23%;

  ${media.lg} {
    width: 100%;
    justify-content: center;
  }
`;

export const PageTitle = styled(Text)`
  width: 200px;
  line-height: 44px;
  font-size: 32px;
  font-weight: 700;

  ${media.xl1} {
    width: unset;
  }
`;

export const AffiliateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  flex: 1;

  ${media.md2} {
    background-color: rgba(21, 21, 27, 1);
    border-radius: 23px;
    padding: 20px 18px;
  }
`;

export const AffiliateContentContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: rgba(21, 21, 27, 1);
  border-radius: 24px;
  padding: 36px 48px;

  ${media.md2} {
    flex-direction: column;
    background-color: unset;
    gap: 12px;
    padding: 0;
  }
`;

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 30px;

  ${media.xl3} {
    width: 70%;
  }

  ${media.xl2} {
    width: 77%;
  }

  ${media.xl2} {
    width: 100%;
  }

  ${media.md2} {
    gap: 24px;
  }
`;

export const LeftTitle = styled.div`
  width: 35%;
  font-size: 24px;
  font-weight: 500;
  color: #fff;

  ${media.md2} {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: rgba(232, 232, 232, 1);
  }
`;

export const AffiliateContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 70px 10px 0;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  ${media.xl} {
    padding: 10px 0;
  }
`;

export const CopyUrlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

export const AffiliateContentItemTitle = styled(Text)`
  font-size: 18px;
  font-weight: 500;
`;

export const UrlWrapper = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(31, 31, 38, 1);
  width: 100%;
  border-radius: 8px;
  padding: 0 40px;
  height: 55px;
  display: flex;
  align-items: center;
  word-break: break-word;

  ${media.xl2} {
    padding: 0 20px;
  }

  ${media.md2} {
    font-weight: 400;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 20px;
  }
`;

export const AffiliateContentItemValue = styled(Text)`
  font-size: 18px;
  font-weight: 500;

  &.biga-value {
    font-weight: 700;
  }
`;

export const UnderlineText = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  user-select: none;
  padding: 0 22px;
  min-height: 55px;
  border-radius: 12px;
  display: flex;
  align-items: center;
`;
