import { Menu } from 'antd';
import { ReactComponent as LogoRedDot } from 'assets/vectors/logo-header-reddot.svg';
import { ReactComponent as LogoCredit } from 'assets/vectors/logo-header-biga-credit.svg';
import { ReactComponent as LogoWallet } from 'assets/vectors/logo-header-wallet.svg';
import styled from 'styled-components';
import { media } from 'constants/enum/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const HeaderWrap = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #0d0d0d;
`;

export const HeaderWrapper = styled(Wrapper)<{ hasBackground?: boolean }>`
  gap: 38px;
  height: 80px;
  padding: 15px 85px 15px 24px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.16); */

  background: ${props => (props.hasBackground ? 'transparent' : '#0F0F13')};

  ${media.md} {
    padding: 9px 15px;
    height: 60px;
    justify-content: space-between;
  }
`;

export const UserInfoWrapper = styled.div`
  gap: 10px;
  display: flex;
`;

export const LogoWrapper = styled(Wrapper)`
  /* padding: 15px 0 36px 39px; */
`;

export const Logo = styled.img`
  width: 72.22px;
  height: auto;
  cursor: pointer;
`;

export const DropdownWrapper = styled(Menu)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 0;
  background: #15151b;
  border-radius: 10px;
  width: 180px;
  color: #fff;
  font-weight: 500;
  box-shadow: 0 8px 20px 4px rgba(255, 255, 255, 0.1);
`;

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  user-select: none;

  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
  }
`;

export const ProfileWrapper = styled(Wrapper)``;

export const ProfileMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 10.909px;
  border-radius: 10px;
  border: 1px solid #404040;
  width: 42px;
  height: 42px;
`;

export const ProfileAvatar = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
`;

export const BigaCreditsWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${media.md} {
    background-color: #ffffff0d;
    padding: 5px;
    width: 100%;
    justify-content: center;
    gap: 7px;
  }
`;

export const BigaCreditsTextWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BigaCreditsLogo = styled(LogoCredit)`
  height: 46px;
  width: 46px;

  ${media.sm} {
    height: 25px;
    width: auto;
  }
`;

export const BigaCreditsText = styled(Text)`
  color: #a9a9a9;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const BigaCreditsValueText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
`;

export const Line = styled.div`
  width: 1px;
  height: 22px;
  background: rgba(255, 255, 255, 0.4);
`;

export const ConnectWalletWrapper = styled(Wrapper)`
  gap: 8px;
  cursor: pointer;
`;

export const LogoHeaderRedDot = styled(LogoRedDot)<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  .header-connect-dot {
    fill: ${props => props?.active && '#68CE67'};
  }
`;

export const ConnectWalletText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const LogoHeaderWallet = styled(LogoWallet)`
  width: 20px;
  height: 20px;
`;

export const ConnectWalletButton = styled(Text)`
  width: 206px;
  height: 54px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 22px;
  border-radius: 12px;

  background: linear-gradient(93deg, #0038ff -49.51%, #00aafd 99.71%);
  box-shadow: 0px 6px 12px 0px rgba(0, 187, 255, 0.24);

  &:hover {
    background: linear-gradient(93deg, #0028dd -49.51%, #0088dd 99.71%);
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }

  ${media.sm} {
    width: 156px;
    height: 39px;
    font-size: 12px;
  }
`;

export const MenuWrapper = styled(Menu)`
  display: inline-flex;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  background: #15151b;
  box-shadow: 0px 8px 60px 4px rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  max-width: 450px;
`;

export const MenuMainWrapper = styled(Wrapper)`
  gap: 40px;
  flex-direction: column;
`;

export const MenuMainTextWrapper = styled(Wrapper)`
  flex-direction: column;
  gap: 16px;
`;

export const ConnectTitleText = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ConnectDecriptionText = styled(Text)`
  color: rgba(181, 181, 181, 1);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

export const MenuMainButtonWrapper = styled(Wrapper)`
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 0 40px;
  width: 100%;
`;

export const MenuMainButton = styled(Wrapper)<{ disabled?: boolean }>`
  width: 100%;
  height: 56px;
  padding: 10px 16px;
  justify-content: center;
  gap: 8px;
  border-radius: 12px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const MenuMainButtonLogo = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const MenuMainButtonText = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

export const MenuMainDecriptionWrapper = styled(Wrapper)`
  width: 100%;
  padding: 24px 10px;
  justify-content: center;
  gap: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

export const MenuMainDecriptionText = styled(Text)`
  color: rgba(181, 181, 181, 1);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

export const MenuMainDecriptionStepsText = styled.span`
  color: rgba(0, 170, 253, 1);
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const ConnectButtonWrapper = styled(Wrapper)`
  gap: 16px;
`;

export const ConnectButtonText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
