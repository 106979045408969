import { Select } from 'antd';
import LeaderboardTable from 'components/Leaderboard';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import { formatUnits } from 'ethers/lib/utils';
import { useGetGameLevelDetail } from 'hooks/leaderboard/useGetGameLevelDetail';
import { useGetListLevel } from 'hooks/leaderboard/useGetListLevel';
import { SelectMultiLevel } from 'pages/Leaderboard/styled';
import { useEffect, useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  CloseButton,
  LevelMasterTableBodyItem,
  LevelMasterTableBodyItemTitle,
  LevelMasterTableBodyItemValue,
  LevelMasterTableBodyWrapper,
  LevelMasterTableHeaderWrapper,
  MinimizeButton,
  MinimizeText,
  MinimizeWrapper,
  ModalLevelMasterTableWrapper,
  ModalWrapper,
  TableWrapper,
} from './styled';

const { Option } = Select;

interface IProps {
  onMinimized: () => void;
  selectedGameId: any;
  isCheckGameLevelType: any;
  selectedLevelProp: number;
  setSelectedLevelProp: any;
  minLevel?: number;
  maxLevel?: number;
  levelsOptions: any;
}

export const LeaderboardTableGame = ({
  onMinimized,
  selectedGameId,
  isCheckGameLevelType,
  selectedLevelProp,
  setSelectedLevelProp,
  minLevel,
  maxLevel,
  levelsOptions,
}: IProps) => {
  const open = useModalIsOpen(ApplicationModal.PLAYGAME_LEADERBOARD_TABLE);
  const toggleModal = useToggleModal(
    ApplicationModal.PLAYGAME_LEADERBOARD_TABLE,
  );
  const handleCancel = () => {
    toggleModal();
    setSelectedLevelProp(selectedLevel);
  };

  const handleMinimizeClick = () => {
    onMinimized();
    setSelectedLevelProp(selectedLevel);
  };

  const { gameLevelDetail } = useGetGameLevelDetail(selectedGameId?.gameId);

  const [selectedLevel, setSelectedLevel] = useState<number>(selectedLevelProp);

  useEffect(() => setSelectedLevel(selectedLevelProp), [selectedLevelProp]);

  // const { listLevel } = useGetListLevel(selectedGameId?.gameId, isGameLevelType ? GameScoringModelType.level : GameScoringModelType.score);

  // const [levels, setLevels] = useState<number[]>();

  // useEffect(() => {
  //   console.log({ id: selectedGameId?.gameId, listLevel, levels })

  //   if (listLevel) {
  //     const listLevelNumber = listLevel.map(lv => +lv);
  //     setLevels(listLevelNumber);
  //     setSelectedLevel(1);
  //   }
  // }, [listLevel]);

  const handleLevelSelect = (selected: number) => {
    setSelectedLevel(selected);
  };

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="1021px"
      centered
    >
      <ModalLevelMasterTableWrapper>
        <LevelMasterTableHeaderWrapper>
          <MinimizeWrapper onClick={handleMinimizeClick}>
            <MinimizeButton />
            <MinimizeText>Minimize window</MinimizeText>
          </MinimizeWrapper>
          <div>
            {!isCheckGameLevelType && (
              <SelectMultiLevel
                value={selectedLevel}
                onChange={handleLevelSelect}
              >
                {levelsOptions}
              </SelectMultiLevel>
            )}
            <CloseButton onClick={handleCancel} />
          </div>
        </LevelMasterTableHeaderWrapper>

        {/* <LevelMasterTableBodyWrapper>
          <LevelMasterTableBodyItem>
            <LevelMasterTableBodyItemTitle>
              Amount of Plays
            </LevelMasterTableBodyItemTitle>
            <LevelMasterTableBodyItemValue>
              {selectedGameId?.totalAttempts.toLocaleString()}
            </LevelMasterTableBodyItemValue>
          </LevelMasterTableBodyItem>
          <LevelMasterTableBodyItem>
            <LevelMasterTableBodyItemTitle>
              Amount of Biga Credits
            </LevelMasterTableBodyItemTitle>
            <LevelMasterTableBodyItemValue>
              {parseFloat(
                formatUnits(
                  selectedGameId?.totalOfSpentAmount || 0,
                  selectedGameId?.tokenInfo?.decimals || 18,
                ),
              ).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </LevelMasterTableBodyItemValue>
          </LevelMasterTableBodyItem>
          <LevelMasterTableBodyItem>
            <LevelMasterTableBodyItemTitle>
              Amount of Revenue
            </LevelMasterTableBodyItemTitle>
            <LevelMasterTableBodyItemValue>
              $
              {parseFloat(selectedGameId?.totalRevenue).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              ) || 0}
            </LevelMasterTableBodyItemValue>
          </LevelMasterTableBodyItem>
        </LevelMasterTableBodyWrapper> */}

        <TableWrapper>
          <LeaderboardTable
            tab={
              isCheckGameLevelType
                ? GameScoringModelType.level
                : GameScoringModelType.score
            }
            isMinimized={false}
            selectedGameId={selectedGameId?.gameId}
            selectedLevel={selectedLevel}
            gameInfo={selectedGameId}
            minLevel={isCheckGameLevelType ? undefined : minLevel}
            isScroll={true}
          />
        </TableWrapper>
      </ModalLevelMasterTableWrapper>
    </ModalWrapper>
  );
};
