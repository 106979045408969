import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasBackground: false,
  backgroundURL: '',
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setBackground: (state, { payload }) => {
      state.hasBackground = true;
      state.backgroundURL = payload;
    },
    removeBackground: state => {
      state.hasBackground = false;
      state.backgroundURL = '';
    },
  },
});

export const { setBackground, removeBackground } = templateSlice.actions;

export default templateSlice.reducer;
