import { Form } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 56px;
`;

export const FormItemWrapper = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const FormItemInput = styled(Form.Item)`
  /* width: 98%; */
  margin-bottom: 0px;
  padding: 0px;
  .ant-form-item-label {
    label {
      font-size: 16px;
    }
  }
`;

export const RatioGameType = styled.div`
  margin-top: 18px;
  .ant-radio-wrapper {
    font-size: 16px;
    color: black;
  }
`;

export const WrapReview = styled.div`
  max-width: 100vw;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0;
  margin-bottom: 20px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #6f7071;
  border: none;
  font-size: 18px;
  padding: 0;
  padding-top: 10px;
  margin-top: 0;
  position: absolute;

  :hover {
    color: black;
  }
`;

export const HeaderTitle = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 50px;
  transform: translateX(-50%);
  @media (max-width: 768px) {
    left: 20%;
    margin-top: 30px;
    transform: translateX(-5%);
  }
`;

export const AvatarImg = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 50%;
`;

export const BackgroundGameImage = styled.img`
  width: 80px;
  height: 100px;
  border: 1px solid #a7a7a7;
  object-fit: cover;
`;

export const UploadFileZipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: black;
  font-size: 20px;
  line-height: 32px;
`;
