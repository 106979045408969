import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collapsed: false,
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
  },
});

export const { setCollapsed } = navbarSlice.actions;

export default navbarSlice.reducer;
