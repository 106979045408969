import { Purchase } from 'components/Cashier/Purchase';
import { WithDraw } from 'components/Cashier/Withdraw';
import { ReactComponent as WalletIcon } from 'assets/vectors/logo-navbar-cashier.svg';
import gradient from 'assets/images/withdraw/gradient-withdraw.png';
import { useState } from 'react';
import HomepageTemplate from 'template/Homepage';

import {
  CashierLeftContent,
  CashierMainWrapper,
  CashierRightContent,
  CashierRightContentFirstBlock,
  CashierWrapper,
  FirstBlockButton,
  FirstBlockButtonWrapper,
  GradientBottom,
  LeftContentBody,
  LeftContentBodyItem,
  LeftContentBodyItemContent,
  LeftContentBodyItemContentWrapper,
  LeftContentBodyItemNumberWrapper,
  LeftContentBodyItemTitle,
  LeftContentHeader,
  StyledDivider,
  TitlePageWrapper,
  WalletIconWrapper,
} from './styled';

export const Cashier = () => {
  const [activeButton, setActiveButton] = useState('Purchase');
  const [disabled, setDisabled] = useState(false);

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName);
  };

  return (
    <HomepageTemplate>
      <CashierWrapper>
        <CashierMainWrapper>
          <CashierLeftContent>
            <TitlePageWrapper className="desktop">
              <WalletIconWrapper>
                <WalletIcon />
              </WalletIconWrapper>
              Wallet
            </TitlePageWrapper>
            {activeButton === 'Purchase' ? (
              <LeftContentHeader>
                Please follow these steps to purchase
              </LeftContentHeader>
            ) : (
              <LeftContentHeader>
                Please follow these steps for withdrawal
              </LeftContentHeader>
            )}
            <LeftContentBody>
              <LeftContentBodyItem>
                <LeftContentBodyItemContentWrapper>
                  <LeftContentBodyItemNumberWrapper>
                    1
                  </LeftContentBodyItemNumberWrapper>
                  <LeftContentBodyItemTitle>
                    Select amount
                  </LeftContentBodyItemTitle>
                </LeftContentBodyItemContentWrapper>
                <LeftContentBodyItemContent>
                  Please input the desired amount of BIGA credits and select
                  desired cryptocurrency
                </LeftContentBodyItemContent>
              </LeftContentBodyItem>
              <LeftContentBodyItem>
                <LeftContentBodyItemContentWrapper>
                  <LeftContentBodyItemNumberWrapper>
                    2
                  </LeftContentBodyItemNumberWrapper>
                  <LeftContentBodyItemTitle>
                    Review details
                  </LeftContentBodyItemTitle>
                </LeftContentBodyItemContentWrapper>
                <LeftContentBodyItemContent>
                  Review the desired amount details with transactions fees.
                </LeftContentBodyItemContent>
              </LeftContentBodyItem>
              <LeftContentBodyItem>
                {activeButton === 'Purchase' ? (
                  <>
                    <LeftContentBodyItemContentWrapper>
                      <LeftContentBodyItemNumberWrapper>
                        3
                      </LeftContentBodyItemNumberWrapper>
                      <LeftContentBodyItemTitle>
                        Confirm purchase
                      </LeftContentBodyItemTitle>
                    </LeftContentBodyItemContentWrapper>
                    <LeftContentBodyItemContent>
                      Review the desired amount details with transactions fees
                      and confirm the purchase.
                    </LeftContentBodyItemContent>
                  </>
                ) : (
                  <>
                    <LeftContentBodyItemContentWrapper>
                      <LeftContentBodyItemNumberWrapper>
                        3
                      </LeftContentBodyItemNumberWrapper>
                      <LeftContentBodyItemTitle>
                        Confirm Withdrawal
                      </LeftContentBodyItemTitle>
                    </LeftContentBodyItemContentWrapper>
                    <LeftContentBodyItemContent>
                      Review the desired amount details with transactions fees
                      and confirm the withdrawal.
                    </LeftContentBodyItemContent>
                  </>
                )}
              </LeftContentBodyItem>
            </LeftContentBody>
          </CashierLeftContent>

          <CashierRightContent>
            <CashierRightContentFirstBlock>
              <FirstBlockButtonWrapper
                active={activeButton === 'Purchase'}
                onClick={() => !disabled && handleButtonClick('Purchase')}
              >
                <FirstBlockButton active={activeButton === 'Purchase'}>
                  Purchase
                </FirstBlockButton>
              </FirstBlockButtonWrapper>
              <StyledDivider type="vertical" />
              <FirstBlockButtonWrapper
                active={activeButton === 'Withdraw'}
                onClick={() => !disabled && handleButtonClick('Withdraw')}
              >
                <FirstBlockButton active={activeButton === 'Withdraw'}>
                  Withdraw
                </FirstBlockButton>
              </FirstBlockButtonWrapper>
            </CashierRightContentFirstBlock>

            {activeButton === 'Purchase' ? (
              <Purchase setDisabled={setDisabled} />
            ) : (
              <WithDraw setDisabled={setDisabled} />
            )}
          </CashierRightContent>

          <TitlePageWrapper className="mobile">
            <WalletIconWrapper>
              <WalletIcon />
            </WalletIconWrapper>
            Wallet
          </TitlePageWrapper>
        </CashierMainWrapper>
        <GradientBottom src={gradient} />
      </CashierWrapper>
    </HomepageTemplate>
  );
};
