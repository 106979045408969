import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
const CHAIN_ID = +process.env.REACT_APP_CHAIN_ID;
const MAINNET_CHAIN_ID = 1;
const POLLING_INTERVAL = 12000;
const TESTNET_CHAIN_ID = 97;
const KOVAN_CHAIN_ID = 42;
const AVALANCHE_CHAIN_ID = 43113;
const POLYGON_CHAIN_ID = 80001;
const GSC_CHAIN_ID = 770;
const GOERLI_CHAIN_ID = 5;
const SEPOLIA_CHAIN_ID = 11155111;
const AVALANCHE_MAINNET = 43114;
const BNB_MAINNET = 56;
const BASE_MAINNET = 8453;
const BASE_SEPOLIA_TESTNET = 84532;
const NETWORKS = {
  [TESTNET_CHAIN_ID]: {
    chainIds: [TESTNET_CHAIN_ID],
    rpc: {
      97: process.env.REACT_APP_BSC_NODE,
    },
  },
  [AVALANCHE_CHAIN_ID]: {
    chainIds: [AVALANCHE_CHAIN_ID],
    rpc: {
      43113: process.env.REACT_APP_AVALANCHE_TEST_NET,
    },
  },
  [POLYGON_CHAIN_ID]: {
    chainIds: [POLYGON_CHAIN_ID],
    rpc: {
      80001: process.env.REACT_APP_POLYGON_NODE,
    },
  },
  [KOVAN_CHAIN_ID]: {
    chainIds: [KOVAN_CHAIN_ID],
    rpc: {
      42: process.env.REACT_APP_KOVAN_NODE,
    },
  },
  [GSC_CHAIN_ID]: {
    chainIds: [GSC_CHAIN_ID],
    rpc: {
      770: process.env.REACT_APP_GSC_NODE,
    },
  },
  [GOERLI_CHAIN_ID]: {
    chainIds: [GOERLI_CHAIN_ID],
    rpc: {
      5: process.env.REACT_APP_GOERLI_NODE,
    },
  },
  [SEPOLIA_CHAIN_ID]: {
    chainIds: [SEPOLIA_CHAIN_ID],
    rpc: {
      11155111: process.env.REACT_APP_SEPOLIA_NODE,
    },
  },
  [MAINNET_CHAIN_ID]: {
    chainIds: [MAINNET_CHAIN_ID],
    rpc: {
      1: process.env.REACT_APP_ETHEREUM_NODE,
    },
  },
  [AVALANCHE_MAINNET]: {
    chainIds: [AVALANCHE_MAINNET],
    rpc: {
      43114: process.env.REACT_APP_AVALANCHE_MAIN_NET,
    },
  },
  [BNB_MAINNET]: {
    chainIds: [BNB_MAINNET],
    rpc: {
      56: '',
    },
  },
  [BASE_MAINNET]: {
    chainIds: [BASE_MAINNET],
    rpc: {
      8453: '',
    },
  },
  [BASE_SEPOLIA_TESTNET]: {
    chainIds: [BASE_SEPOLIA_TESTNET],
    rpc: {
      84532: '',
    },
  },
};

export function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
}
export const injected = (chainId, chainIds?: number[]) =>
  new InjectedConnector({
    supportedChainIds: !chainIds ? NETWORKS[chainId]?.chainIds : chainIds,
  });

export const supportedChainIds = [CHAIN_ID].filter(o => o);

export const getNewWalletConnect = () => {
  return new WalletConnectConnector({
    rpc: 'https://sepolia.infura.io/v3/',
    qrcode: true,
  });
};

export const walletconnect = new WalletConnectConnector({
  rpc: {
    [CHAIN_ID]: NETWORKS[CHAIN_ID].rpc[CHAIN_ID],
    // [SEPOLIA_CHAIN_ID]:
    //   'https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  },
  supportedChainIds,
  chainId: CHAIN_ID,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const WalletConnectInstance = new WalletConnectConnector({
  rpc: NETWORKS[CHAIN_ID].rpc[CHAIN_ID],
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
});

export enum NET_TYPE {
  MAIN_NET = 'mainNet',
  TEST_NET = 'testNet',
}

export const networkTypeSelected =
  process.env.REACT_APP_MAINNET?.toLocaleLowerCase() === 'true'
    ? NET_TYPE.MAIN_NET
    : NET_TYPE.TEST_NET;

export const SUPPORTED_NETWORKS = {
  [SEPOLIA_CHAIN_ID]: {
    chainId: SEPOLIA_CHAIN_ID,
    name: 'Sepolia',
    iconUrl: 'https://chainlist.org/unknown-logo.png',
    netType: NET_TYPE.TEST_NET,
  },
  [AVALANCHE_CHAIN_ID]: {
    chainId: AVALANCHE_CHAIN_ID,
    name: 'Avalanche Fuji Testnet',
    iconUrl: 'https://cryptologos.cc/logos/avalanche-avax-logo.png',
    netType: NET_TYPE.TEST_NET,
  },
  [AVALANCHE_MAINNET]: {
    chainId: AVALANCHE_MAINNET,
    name: 'Avalanche',
    iconUrl: 'https://cryptologos.cc/logos/avalanche-avax-logo.png',
    netType: NET_TYPE.MAIN_NET,
  },
  [TESTNET_CHAIN_ID]: {
    chainId: TESTNET_CHAIN_ID,
    name: 'BNB Smart Chain Testnet',
    iconUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDkhwqATeXvrHFGeCHjsbyrtYu3wAccB1CCL1mT8gHdA&s',
    netType: NET_TYPE.TEST_NET,
  },
  [BNB_MAINNET]: {
    chainId: BNB_MAINNET,
    name: 'BNB Chain',
    iconUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDkhwqATeXvrHFGeCHjsbyrtYu3wAccB1CCL1mT8gHdA&s',
    netType: NET_TYPE.MAIN_NET,
  },
  [BASE_MAINNET]: {
    chainId: BASE_MAINNET,
    name: 'Base',
    iconUrl: 'https://icons.llamao.fi/icons/chains/rsz_base.jpg',
    netType: NET_TYPE.MAIN_NET,
  },
  [BASE_SEPOLIA_TESTNET]: {
    chainId: BASE_SEPOLIA_TESTNET,
    name: 'Base Sepolia Testnet',
    iconUrl: 'https://icons.llamao.fi/icons/chains/rsz_base.jpg',
    netType: NET_TYPE.TEST_NET,
  },
  [MAINNET_CHAIN_ID]: {
    chainId: MAINNET_CHAIN_ID,
    name: 'Ethereum',
    iconUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwMont5WgejLppJtLnOp7jwVnyTnYTqDyR01p-EKMtcw&s',
    netType: NET_TYPE.MAIN_NET,
  },
};

export const ContractAddressByChainId = {
  [SEPOLIA_CHAIN_ID]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [TESTNET_CHAIN_ID]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [AVALANCHE_CHAIN_ID]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [BASE_SEPOLIA_TESTNET]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [MAINNET_CHAIN_ID]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [BNB_MAINNET]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [AVALANCHE_MAINNET]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
  [BASE_MAINNET]: {
    StakingContractAddress: process.env.REACT_APP_STAKING_CONTRACT,
  },
};

export const SUPPORT_CHAIN_IDS =
  '11155111, 43113, 97, 56, 43114, 8453, 84532, 1';
