import {
  LeftCircleTwoTone,
  PoweroffOutlined,
  RightCircleTwoTone,
  SolutionOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import bigalogo from 'assets/vectors/logo-navbar-biga.svg';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { useLogOut } from 'hooks/useLogOut';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCollapsed } from 'redux/slices/navbar';
import { useAppSelector } from 'redux/store';

import {
  // SettingWrapper,
  MenuLogoSetting,
  DropdownItemWrapper,
  DropdownWrapper,
  Line,
  Logo,
  LogoWrapper,
  MainWrapper,
  MenuItemWrapper,
  MenuLogoAdminPanel,
  MenuLogoAdminStats,
  MenuLogoAffiliate,
  MenuLogoCashier,
  MenuLogoGameManagement,
  MenuLogoGames,
  MenuLogoLeaderboard,
  MenuLogoStaking,
  MenuText,
  MenuWrapper,
  NavbarWrapper,
  ProfileAvatar,
  ProfileAvatarWrapper,
  ProfileMain,
  ProfileWrapper,
  SupportLogo,
  SupportWrapper,
} from './styled';
import { LogoutModal } from 'components/Modals/UserProfile/LogoutModal';

interface INavbarProps {
  theme?: string;
  onClick?: () => void;
}

export const Navbar = ({ theme, onClick }: INavbarProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logOut = useLogOut();
  const [visible, setVisible] = useState(false);
  const [visibleAvatarDropdown, setVisibleAvatarDropdown] = useState(false);

  const dispatch = useDispatch();
  const collapsed = useAppSelector(state => state.navbar.collapsed);

  const isEnableCashier = useEnableCashier();

  const handleOk = () => {
    logOut();
    navigate(PitRouter.GAME_LIST);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLogOutClick = () => {
    setVisibleAvatarDropdown(false);
    setVisible(true);
  };

  const handleProfileClick = () => {
    setVisibleAvatarDropdown(false);
    navigate(PitRouter.PROFILE);
  };

  const widgetMenu = (
    <DropdownWrapper>
      <DropdownItemWrapper key="profile" onClick={handleProfileClick}>
        <SolutionOutlined />
        Profile
      </DropdownItemWrapper>
      <DropdownItemWrapper key="logout" onClick={handleLogOutClick}>
        <PoweroffOutlined />
        Logout
      </DropdownItemWrapper>
    </DropdownWrapper>
  );

  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);
  const avatarURL = useMemo(() => {
    return playerInfo?.avatarURL
      ? `${playerInfo?.avatarURL}?timestamp=${Date.now()}`
      : '';
  }, [playerInfo?.avatarURL, refreshAvatar]);

  return (
    <NavbarWrapper onClick={onClick}>
      <LogoWrapper>
        <Logo
          src={bigalogo}
          alt="logo"
          onClick={() => navigate(PitRouter.HOME)}
        />
      </LogoWrapper>

      <MainWrapper>
        {playerInfo && playerInfo.status === PlayerStatus.UploadedProfile && (
          <ProfileWrapper>
            <Dropdown
              open={visibleAvatarDropdown}
              overlay={widgetMenu}
              trigger={['click']}
              placement="bottomRight"
              overlayStyle={{ position: 'fixed' }}
              onOpenChange={open => setVisibleAvatarDropdown(open)}
            >
              <ProfileMain>
                <ProfileAvatarWrapper>
                  <ProfileAvatar
                    src={avatarURL ? avatarURL : defaultProfileImg}
                    alt="avatar profile"
                  />
                </ProfileAvatarWrapper>
                <LogoutModal
                  isOpen={visible}
                  onCancel={handleCancel}
                  onOk={handleOk}
                />
              </ProfileMain>
            </Dropdown>
          </ProfileWrapper>
        )}

        <MenuWrapper>
          <MenuItemWrapper
            to={PitRouter.GAME_LIST}
            className={pathname === PitRouter.GAME_LIST ? 'navbar-active' : ''}
          >
            <MenuLogoGames />
          </MenuItemWrapper>
          <Line />

          {playerInfo &&
            playerInfo.roles &&
            !playerInfo.roles.includes(PlayerRoles.Tester) && (
              <>
                <MenuItemWrapper
                  to={PitRouter.LEADER_BOARD}
                  className={
                    pathname === PitRouter.LEADER_BOARD ? 'navbar-active' : ''
                  }
                >
                  <MenuLogoLeaderboard />
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {playerInfo?.roles &&
            playerInfo.roles.includes(PlayerRoles.Influencer) && (
              <>
                <MenuItemWrapper
                  to={PitRouter.AFFILIATE}
                  className={
                    pathname === PitRouter.AFFILIATE ? 'navbar-active' : ''
                  }
                >
                  <MenuLogoAffiliate />
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {isEnableCashier &&
            playerInfo?.status === PlayerStatus.UploadedProfile &&
            !playerInfo?.roles.includes(PlayerRoles.Tester) && (
              <>
                <MenuItemWrapper
                  to={PitRouter.CASHIER}
                  className={
                    pathname === PitRouter.CASHIER ? 'navbar-active' : ''
                  }
                >
                  <MenuLogoCashier />
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin) ||
              playerInfo.roles.includes(PlayerRoles.Provider)) && (
              <>
                <MenuItemWrapper
                  to={PitRouter.MANAGEMENT}
                  className={
                    pathname === PitRouter.MANAGEMENT ? 'navbar-active' : ''
                  }
                >
                  <MenuLogoGameManagement />
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin)) && (
              <>
                <MenuItemWrapper
                  to={PitRouter.ADMIN_STATS}
                  className={
                    pathname === PitRouter.ADMIN_STATS ? 'navbar-active' : ''
                  }
                >
                  <MenuLogoAdminStats />
                </MenuItemWrapper>
                <Line />
              </>
            )}
          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin)) && (
              <>
                <MenuItemWrapper
                  to={PitRouter.ADMIN_PANEL}
                  className={
                    pathname === PitRouter.ADMIN_PANEL ? 'navbar-active' : ''
                  }
                >
                  <MenuLogoAdminPanel />
                </MenuItemWrapper>
                <Line />
              </>
            )}
          {process.env.REACT_APP_IS_HIDE_STAKING !== 'true' && (
            <>
              <MenuItemWrapper
                to={PitRouter.STAKING}
                className={
                  pathname === PitRouter.STAKING ? 'navbar-active' : ''
                }
              >
                <MenuLogoStaking />
              </MenuItemWrapper>
              <Line />
            </>
          )}
        </MenuWrapper>

        {!playerInfo?.roles.includes(PlayerRoles.Tester) && (
          <>
            <MenuItemWrapper
              to={PitRouter.SUPPORT}
              className={pathname === PitRouter.SUPPORT ? 'navbar-active' : ''}
            >
              <SupportLogo />
            </MenuItemWrapper>
            <Line />
          </>
        )}

        {playerInfo && playerInfo.status === PlayerStatus.UploadedProfile && (
          <MenuItemWrapper
            to={PitRouter.PROFILE}
            className={pathname === PitRouter.PROFILE ? 'navbar-active' : ''}
          >
            <MenuLogoSetting />
          </MenuItemWrapper>
        )}
      </MainWrapper>
    </NavbarWrapper>
  );
};
