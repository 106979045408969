import { Col, Dropdown } from 'antd';
import { ReactComponent as LogoLeaderboard } from 'assets/vectors/logo-navbar-leaderboard.svg';
import { ReactComponent as LogoClosed } from 'assets/vectors/logo-play-game-close.svg';
import { ReactComponent as LogoBack } from 'assets/vectors/logo-game-arrow-left.svg';
import { ReactComponent as LogoExpanded } from 'assets/vectors/logo-play-game-expand.svg';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';
import gradintBg from 'assets/images/gradient-bg.png';

export const PlayGameWrapper = styled.div`
  /* all: inherit; */
  padding-top: 20px;
  background: url(${gradintBg}) no-repeat;
  background-attachment: fixed;
  background-position: 100px 400px;
`;

export const PlayGameContainer = styled.div`
  gap: 64px;
  padding: 0px 10vw 200px 10vw;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;

  ${media.md2} {
    padding: 0px 5vw 100px;
  }
`;

export const GameDetailWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GameDetailContentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  /* gap: 62px; */
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const GameDetailContentTitleWrapper = styled.div`
  width: 100%;
  height: 60px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const GameDetailContentMainWrapper = styled.iframe`
  aspect-ratio: 16/9;
  width: 100%;
  height: calc(100vw * 9 / 16 - 9vw);

  ${media.md2} {
    height: calc(100vw * 9 / 16 - 5vw);
  }
`;

export const GameDetailStatisticWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #15151b;
  border-radius: 24px;
  flex-direction: column;
  padding: 30px 50px;
  gap: 30px;

  * {
    color: white;
  }

  ${media.md} {
    background-color: unset;
    padding: 10px;
  }
`;

export const GameDetailStatisticInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${media.md} {
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
  }
`;

export const GameDetailStatisticGameName = styled.div`
  font-size: 25.46px;
  font-weight: 500;

  ${media.md} {
    display: none;
  }
`;

export const GameDetailStatisticInfoTags = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const GameDetailStatisticInfoTag = styled.div`
  font-size: 17px;
  border-radius: 42px;
  background-color: #1f1f2a;
  padding: 8px 15px;

  &:first-child {
    background-color: #6b24b2;
  }
`;

export const GameDetailStatisticContent = styled.div`
  display: flex;
  width: 100%;
  column-gap: 30px;
  align-items: flex-start;
  justify-content: space-between;

  ${media.md} {
    flex-direction: column;
    row-gap: 30px;
  }
`;

export const GameDetailStatisticDescription = styled.div`
  max-width: 401px;
  min-width: 200px;
  width: 30%;
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;

  ${media.md} {
    max-width: unset;
    width: 100%;
  }
`;

export const GameDetailStatisticItems = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: max-content; */
`;

export const GameDetailStatisticItem = styled.div`
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &:has(.revenue) {
    margin-right: 50px;
  }
`;

export const GameDetailStatisticItemTitle = styled.div`
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
`;

export const GameDetailStatisticItemValue = styled.div`
  color: #fff;
  font-size: 22.63px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  /* font-family: Changeling Neo; */
  flex: 1;
  display: flex;
  align-items: center;

  &.revenue {
    color: #e961ff;
    font-size: 32px;
  }
`;

export const GameRecommentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GameRecommentText = styled.div`
  color: #fff;
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  line-height: normal;
  text-transform: uppercase;
  /* font-family: Changeling Neo; */

  ${media.md} {
    font-size: 24px;
  }
`;

export const GameRecommentList = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const GameTitleStyled = styled.p`
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  text-align: center;
  position: absolute;
  width: 100%;
  margin: 0;
  z-index: 0;
`;

export const LeaderboardButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 3;
`;

export const LeaderBoardButtonWrapper = styled.div`
  z-index: 3;
  &.mobile {
    display: none;
  }

  ${media.md} {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }
  }
`;

export const LeaderboardButton = styled(LogoLeaderboard)`
  width: 40px;
  height: 40px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 100%;
  cursor: pointer;
  user-select: none;
  outline: 8px solid rgba(255, 255, 255, 0.08);
`;

export const WarningText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
  color: #fff;
`;

export const DropdownWrapper = styled.div`
  width: 380px;
  max-width: 100%;
  border-radius: 8px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .ant-table-body {
    overflow-y: auto;
  }

  .ant-table-tbody tr:nth-child(even) td {
    /* background-color: #f3f4f6; */
  }

  .load-more-button {
    /* border-radius: 0 0 8px 8px; */
  }

  .ant-table-cell-scrollbar {
    background-color: red;
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: unset;
  }
`;

export const DropdownHeaderWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: black;
  border-radius: 8px 8px 0 0;
`;

export const CloseButton = styled(LogoClosed)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background: black;
  cursor: pointer;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  ${media.md} {
    display: none;
  }
`;

export const BackButton = styled(LogoBack)`
  width: 40px;
  height: 40px;
  padding: 5px;
  cursor: pointer;
  user-select: none;

  display: none;

  ${media.md} {
    display: block;
  }
`;

export const ExpandWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const ExpandButton = styled(LogoExpanded)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background: black;
  color: white;
`;

export const ExpandText = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const GameStyledCol = styled(Col)`
  @media (min-width: 1440px) {
    width: 25%;
    flex-basis: 25%;
  }

  @media (min-width: 1750px) {
    flex-basis: 20%;
    width: 20%;
  }
`;
