export enum ScoringModels {
  LEVEL_PASS = 'LevelPass',
  HIGHEST_REPLACE_SCORE = 'HighestReplaceScore',
  HIGHEST_INCREASE_SCORE = 'HighestIncreaseScore',
  LOWEST_SCORE = 'LowestScore',
  LOWEST_TIME = 'LowestTime',
  LEVEL_PASS_HIGHEST_SCORE = 'LevelPassHighestScore',
  LEVEL_PASS_LOWEST_TIME = 'LevelPassLowestTime',
}

export enum ScoringModelLeaderboardTypes {
  LEVEL_BASED = 'LevelBased',
  SCORE_BASED = 'ScoreBased',
}

export const getScoringModels = (scoringModels: ScoringModels | string) => {
  switch (scoringModels) {
    case ScoringModels.LEVEL_PASS:
      return ScoringModelLeaderboardTypes.LEVEL_BASED;
    case ScoringModels.LEVEL_PASS_HIGHEST_SCORE:
      return ScoringModelLeaderboardTypes.LEVEL_BASED;
    case ScoringModels.LEVEL_PASS_LOWEST_TIME:
      return ScoringModelLeaderboardTypes.LEVEL_BASED;
    case ScoringModels.HIGHEST_INCREASE_SCORE:
      return ScoringModelLeaderboardTypes.SCORE_BASED;
    case ScoringModels.HIGHEST_REPLACE_SCORE:
      return ScoringModelLeaderboardTypes.SCORE_BASED;
    case ScoringModels.LOWEST_SCORE:
      return ScoringModelLeaderboardTypes.SCORE_BASED;
    case ScoringModels.LOWEST_TIME:
      return ScoringModelLeaderboardTypes.SCORE_BASED;
  }
};
