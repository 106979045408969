import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refreshToken: false,
};

const refreshSlice = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    refreshNewToken: state => {
      state.refreshToken = !state.refreshToken;
    },
  },
});

export const { refreshNewToken } = refreshSlice.actions;

export default refreshSlice.reducer;
