import { VisionAnimation } from './VisionAnimation';
import {
  VisionBackground,
  VisionContent,
  VisionContentWrapper,
  VisionDescription,
  VisionTitle,
  VisionTitleWrapper,
  Wrapper,
} from './styled';
import VisionBg from 'assets/main/vison/vision-bg.png';
import { TopEarners } from '../TopEarners/index';

export const ArcadeVision = () => {
  return (
    <Wrapper>
      <VisionBackground src={VisionBg} alt="" />
      <VisionContentWrapper>
        <VisionContent>
          <VisionTitleWrapper>
            <VisionTitle>BIGA ARCADES</VisionTitle>
            <VisionTitle>
              <span style={{ color: '#E961FF' }}>VISION</span>
            </VisionTitle>
          </VisionTitleWrapper>
          <VisionDescription>
            To be the worlds most played gamer casino making as many gamers as
            possible as much money as possible.
          </VisionDescription>
        </VisionContent>
        <div className="animation">
          <VisionAnimation />
        </div>
        <TopEarners />
      </VisionContentWrapper>
    </Wrapper>
  );
};
